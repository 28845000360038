import React from "react";

const roles = {
  1: "Back Office Staff",
  2: "Community Staff",
  3: "Provider",
};

const backOfficeRoles = {
  1: "Admin",
  2: "Scheduler",
  3: "Executive",
  4: "Creator",
};

const UsersTable = ({ users }) => {
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  return (
    <div className="h-full overflow-scroll no-scrollbar">
      <table className="w-full mb-[460px]">
        <thead>
          <tr className="text-left bg-primarylightgrey">
            <th className="px-4 py-3">Name</th>
            <th className="px-4 py-3">Role</th>
            <th className="px-4 py-3">Phone No.</th>
            <th className="px-4 py-3">Email</th>
            <th className="px-4 py-3">Position</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr className="text-left text-gray-900 border-bottom" key={index}>
              <td className="px-4 py-3">{user.details.name}</td>
              <td className="px-4 py-3">{roles[user.type]}</td>
              <td className="px-4 py-3">{formatPhoneNumber(user.phone)}</td>
              <td className="px-4 py-3">{user.email}</td>
              <td className="px-4 py-3">
                {user.type === 1
                  ? backOfficeRoles[user.details.role]
                  : `${user.details.speciality} - ${user.details.ehr.name}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
