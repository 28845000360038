import React from "react";

const Footer = () => {
  return (
    <div className={"flex justify-center items-center py-4 mt-2 w-full"}>
      <span className="text-sm">2024 CareSMS Inc. All rights reserved.</span>
    </div>
  );
};

export default Footer;
