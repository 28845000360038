import { apiSlice } from "../../app/api/apiSlice";

export const billingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    attachPaymentMethod: builder.mutation({
      query: (credentials) => ({
        url: "/billing/attach-payment-method",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    setDefaultPaymentMethod: builder.mutation({
      query: (credentials) => ({
        url: "/billing/set-default-payment-method",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    getPaymentMethods: builder.mutation({
      query: () => ({
        url: "/billing/get-payment-methods",
        method: "GET",
      }),
    }),
    removePaymentMethod: builder.mutation({
      query: (credentials) => ({
        url: "/billing/remove-payment-method",
        method: "DELETE",
        body: { ...credentials },
      }),
    }),
    getSubscription: builder.mutation({
      query: () => ({
        url: "/billing/get-subscription",
        method: "GET",
      }),
    }),
    createCheckoutSession: builder.mutation({
      query: (credentials) => ({
        url: "/billing/create-checkout-session",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updateSubscription: builder.mutation({
      query: (credentials) => ({
        url: "/billing/update-subscription",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    createSubscription: builder.mutation({
      query: (credentials) => ({
        url: "/billing/create-subscription",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    cancelSubscription: builder.mutation({
      query: (credentials) => ({
        url: "/billing/cancel-subscription",
        method: "DELETE",
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useAttachPaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
  useGetPaymentMethodsMutation,
  useRemovePaymentMethodMutation,
  useGetSubscriptionMutation,
  useCreateCheckoutSessionMutation,
  useUpdateSubscriptionMutation,
  useCreateSubscriptionMutation,
  useCancelSubscriptionMutation,
} = billingApiSlice;
