import React, { useState } from "react";
import {
  AddCircle,
  AddCircleOutlineOutlined,
  CheckSharp,
} from "@mui/icons-material";
import { Icons } from "../../../assets";
import {
  useCancelSubscriptionMutation,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
} from "../../../features/billing/billingApiSlice";
import { useNavigate } from "react-router-dom";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../../Billing/components/PaymentForm";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateUser } from "../../../features/auth/authSlice";
import { useGetUserMutation } from "../../../features/user/userApiSlice";
import SubscriptionActionModal from "./components/SubscriptionActionModal";

const plansData = [
  {
    title: "Basic",
    description: "Effortless Scheduling for Your Practice.",
    for: "New Practices",
    lookup_key: "basic_caresms",
    price: 29,
    featureTitle: "Features you'll love:",
    features: [
      "Patient data upload",
      "Intelligent Scheduling Assistant",
      "Up to 16-week daily schedules per Provider",
      "Configure Location Scheduling Rules",
      "Configure Provider Availability",
      "White glove user support",
    ],
  },
  {
    title: "Pro",
    description: "Empower Your Practice with Command Center Efficiency.",
    for: "Established Practices",
    lookup_key: "pro_caresms",
    price: 59,
    featureTitle: "Everything in Basic, plus:",
    features: [
      "Command Center for Back-Office",
      "Provider Mobile App [iOS and Android]",
      "Community Mobile App [iOS and Android]",
      "Messaging",
      "In-app alerts and push notifications",
      "Appointment confirmations",
    ],
  },
  {
    title: "Expert",
    description: "Elevate Your Practice with Advanced Insights and Support.",
    for: "High Volume Practices",
    lookup_key: "expert_caresms",
    price: 89,
    featureTitle: "Everything in Pro, plus:",
    features: ["Reporting & Analytics", "Live Scheduling Assistance"],
  },
];

const addOnsData = [
  {
    title: "EHR Integration",
    price: null,
    featureTitle: "What's included?",
    features: [
      "Sync to EHR automatically.",
      "Publish and share schedules seamlessly.",
      // "Automated schedule entry.",
      "Real-time access to the latest visit details.",
    ],
  },
  {
    title: "Digital Marketing",
    price: null,
    featureTitle: "What's included?",
    features: [
      "Inbound content marketing.",
      "Google keyword analysis and implementation",
      "Targeted marketing initiatives to drive new patient lead generation",
    ],
  },
];

const PricingPlans = () => {
  const [noOfProviders, setNoOfProviders] = useState(null);
  const [planDuration, setPlanDuration] = useState("monthly");

  const [selectedPlan, setSelectedPlan] = useState(null);

  const navigate = useNavigate();

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [createSubscription, { isLoading: createLoading }] =
    useCreateSubscriptionMutation();

  const [cancelSubscription, { isLoading: cancelLoading }] =
    useCancelSubscriptionMutation();

  const [updateSubscription, { isLoading: updateLoading }] =
    useUpdateSubscriptionMutation();

  const [paymentFormOpen, setPaymentFormOpen] = useState(false);

  const user = useSelector(selectUser);

  const [getUser] = useGetUserMutation();

  const dispatch = useDispatch();

  const fetchUser = async () => {
    try {
      const { data } = await getUser().unwrap();

      dispatch(
        updateUser({
          user: data,
        })
      );
    } catch (error) {
      console.error("Failed to fetch user: ", error);
    }
  };

  const handleSuccess = async () => {
    if (selectedPlan.lookup_key === user.subscription.lookupKey) {
      const response = await cancelSubscription().unwrap();

      if (response.data) {
        await fetchUser();
      }
    } else {
      if (user.subscription.lookupKey) {
        const response = await updateSubscription({
          lookupKey: selectedPlan.lookup_key,
        }).unwrap();

        if (response.data) {
          await fetchUser();
        }
      } else {
        const response = await createSubscription({
          lookupKey: selectedPlan.lookup_key,
        }).unwrap();

        if (response.data) {
          await fetchUser();
        }
      }
    }
  };

  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col items-center justify-center w-screen h-full overflow-clip">
        <div className="flex flex-col items-center justify-center px-12 max-w-min">
          <div className="flex flex-row items-center justify-between w-full py-8">
            <p className="m-0 text-2xl font-semibold">Plans and Pricing</p>
            <div className="flex flex-row items-center gap-6">
              {/* <div className="flex flex-col items-center justify-center gap-2">
              <p className="m-0 text-base font-semibold">How many providers?</p>
              <div className="flex w-[280px] h-[60px] flex-row items-center px-6 py-2 border rounded-xl">
                <input
                  type="number"
                  className="w-40 px-3 py-2 text-base"
                  placeholder="Custom"
                  value={noOfProviders}
                  min={1}
                  onChange={(e) => {
                    setNoOfProviders(
                      e.target.value < 1 ? null : e.target.value
                    );
                  }}
                />
                <p className="m-0 text-base">Providers</p>
              </div>
            </div> */}
              {/* <div className="flex flex-col items-center justify-center gap-2">
              <p className="m-0 text-base">
                <span className="text-primaryblue">Save from 16%</span> with
                yearly
              </p>
              <div className="relative w-[280px] h-[60px]  flex flex-row items-center border rounded-xl">
                <p
                  className={classNames(
                    "z-50 w-1/2 m-0 text-base text-center transition-all duration-300 ease-in-out cursor-pointer",
                    planDuration === "monthly" ? "text-white" : ""
                  )}
                  onClick={() => setPlanDuration("monthly")}
                >
                  Monthly
                </p>
                <p
                  className={classNames(
                    "z-50 w-1/2 m-0 text-base text-center transition-all duration-300 ease-in-out cursor-pointer",
                    planDuration === "yearly" ? "text-white" : ""
                  )}
                  onClick={() => setPlanDuration("yearly")}
                >
                  Yearly
                </p>
                <div
                  className={classNames(
                    "absolute z-10 w-[124px] h-10 mx-2 rounded-lg bg-primaryblue duration-300 ease-in-out",
                    planDuration === "monthly"
                      ? "translate-x-0"
                      : "translate-x-[140px]"
                  )}
                />
              </div>
            </div> */}
            </div>
          </div>
          <div className="flex flex-col gap-12 mb-6">
            <div className="flex flex-row gap-12">
              {plansData.map((plan, index) => (
                <div
                  key={index}
                  className="flex-col w-[340px] rounded-2xl border border-primaryblue overflow-clip"
                >
                  <div className="flex flex-col gap-8 p-6 bg-subtleblue">
                    <div className="flex flex-row items-end w-full">
                      <div className="flex items-center justify-center w-12 h-12 ml-auto bg-white rounded-full">
                        <img
                          src={Icons.Verified_Icon}
                          alt="Verified_Icon"
                          className="w-6 h-6 object-fit-contain"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        <p className="m-0 text-2xl font-semibold">
                          {plan.title}
                        </p>
                        <div className="flex flex-row items-center gap-2 px-2 bg-white rounded-md w-fit">
                          <div className="w-2 h-2 rounded-full bg-primaryblue" />
                          <p className="m-0 text-sm whitespace-nowrap text-primaryblue">
                            {plan.for}
                          </p>
                        </div>
                      </div>
                      <p className="m-0 text-base font-light">
                        {plan.description}
                      </p>
                    </div>
                    <div className="flex flex-col gap-0">
                      <p className="m-0 text-4xl font-semibold">
                        $
                        {noOfProviders === null
                          ? planDuration === "monthly"
                            ? formatPrice(plan.price)
                            : formatPrice((plan.price * 12 * 0.84).toFixed(0))
                          : planDuration === "monthly"
                          ? formatPrice(noOfProviders * plan.price)
                          : formatPrice(
                              (noOfProviders * plan.price * 12 * 0.84).toFixed(
                                0
                              )
                            )}
                      </p>
                      <p className="m-0 text-base font-light">
                        /{planDuration.replace("ly", "")}{" "}
                        {noOfProviders !== null && noOfProviders > 1
                          ? "total"
                          : "per provider"}
                      </p>
                    </div>
                    <button
                      onClick={async () => {
                        setSelectedPlan(plan);
                        if (
                          user.subscription.hasDefaultPaymentMethod === false &&
                          user.subscription.lookupKey !== plan.lookup_key
                        ) {
                          setPaymentFormOpen(true);
                        } else {
                          setOpen(true);
                        }
                      }}
                      className={
                        ("flex flex-row items-center justify-center w-full my-12",
                        user.subscription.lookupKey === plan.lookup_key
                          ? "bg-primarygreen text-white p-2 rounded-xl"
                          : "bg-primaryblue text-white p-2 rounded-xl")
                      }
                    >
                      <p className="m-0 text-base font-semibold">
                        {user.subscription.lookupKey === plan.lookup_key
                          ? "Cancel Plan"
                          : "Select Plan"}
                      </p>
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 p-6">
                    <p className="m-0 mb-2 text-base font-semibold">
                      {plan.featureTitle}
                    </p>
                    {plan.features.map((feature, index) => (
                      <div
                        key={index}
                        className="flex flex-row items-start gap-2"
                      >
                        <CheckSharp
                          style={{
                            color: "#3062D4",
                            width: "16px",
                            height: "16px",
                            objectFit: "contain",
                            marginTop: "4px",
                          }}
                        />
                        <p className="m-0 text-base">{feature}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-row items-center w-full gap-6">
              <div className="w-full h-[0.5px] bg-primaryblue" />
              <div className="flex flex-row items-center gap-3">
                <p className="m-0 font-semibold whitespace-nowrap text-primaryblue">
                  Add-ons
                </p>
                <AddCircleOutlineOutlined
                  style={{
                    color: "#3062D4",
                    width: "24px",
                    height: "24px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="w-full h-[0.5px] bg-primaryblue" />
            </div>
            <div className="flex flex-row w-full gap-12">
              {addOnsData.map((addOn, index) => (
                <div
                  key={index}
                  className="flex-col w-[340px] rounded-2xl border border-primaryblue overflow-clip"
                >
                  <div className="flex flex-col gap-8 p-6 bg-subtleblue">
                    <div className="flex flex-row items-center justify-between">
                      <p className="m-0 text-2xl font-semibold">
                        {addOn.title}
                      </p>
                      <AddCircle
                        style={{
                          color: "#3062D4",
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 p-6">
                    <p className="m-0 mb-2 text-base font-semibold">
                      {addOn.featureTitle}
                    </p>
                    {addOn.features.map((feature, index) => (
                      <div
                        key={index}
                        className="flex flex-row items-start gap-2"
                      >
                        <CheckSharp
                          style={{
                            color: "#3062D4",
                            width: "16px",
                            height: "16px",
                            objectFit: "contain",
                            marginTop: "4px",
                          }}
                        />
                        <p className="m-0 text-base">{feature}</p>
                      </div>
                    ))}
                    <button className="flex flex-row items-center justify-center w-full h-12 mt-4 text-white rounded-xl bg-primaryblue">
                      <p className="m-0 text-base font-semibold">
                        {addOn.price === null
                          ? "Contact Sales"
                          : `Add for $${addOn.price}/month`}
                      </p>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ReusableModal
        open={paymentFormOpen}
        onClose={() => setPaymentFormOpen(false)}
      >
        <Elements
          stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)}
        >
          <PaymentForm handleSuccess={handleSuccess} />
        </Elements>
      </ReusableModal>
      {selectedPlan && (
        <SubscriptionActionModal
          open={open}
          handleOpen={() => {
            setOpen(false);
            setSelectedPlan(null);
          }}
          handleAction={handleSuccess}
          action={
            selectedPlan.lookup_key === user.subscription.lookupKey
              ? "cancel"
              : "select"
          }
          isLoading={createLoading || cancelLoading || updateLoading}
          selectedSubscription={selectedPlan}
        />
      )}
    </>
  );
};
export default PricingPlans;
