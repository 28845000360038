import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const DoublebarChartIconsvg = (props) => (
  <Tooltip title={props.tooltipText}>
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2" y="18" width="20" height="2" fill={props.color || "#1976d2"} />

      <rect x="4" y="10" width="3" height="8" fill={props.color || "#1976d2"} />
      <rect x="8" y="7" width="3" height="11" fill={props.color || "#1976d2"} />

      <rect x="14" y="6" width="3" height="12" fill={props.color || "#1976d2"} />
      <rect x="18" y="4" width="3" height="14" fill={props.color || "#1976d2"} />
    </svg>
  </Tooltip>

);

export default DoublebarChartIconsvg;

