import React from 'react'
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import * as pivottableUtilities from 'react-pivottable/Utilities';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';

function PivotTable({ incomingData, processApiData, isPatientReport, id, csvData, loading, setLoading }) {
    const filterDuplicates = (newState) => {
        const validNames = csvData.length > 0 ? csvData[0] : pivotState?.data[0];
        const newRows = newState.rows.filter((item) => validNames.includes(item) && (!pivoteRows.includes(item) || !newState.cols.includes(item)));
        const newCols = newState.cols.filter((item) => validNames.includes(item) && (!pivoteCols.includes(item) || !newState.rows.includes(item)));
        setPivoteRows(newRows)
        setPivoteCols(newCols)
        return { ...newState, rows: newRows, cols: newCols };
    };

    const [pivoteRows, setPivoteRows] = React.useState([])
    const [pivoteCols, setPivoteCols] = React.useState([])
    const [pivotState, setPivotState] = React.useState(
        {
            data: [],
            aggregatorName: "Integer Sum",
            vals: ["Patient Count"],
            renderers: Object.assign({}, TableRenderers, createPlotlyRenderers(Plot)),
            rowOrder: "key_a_to_z",
            colOrder: "key_a_to_z",
            rendererName: "Table",
            unusedOrientationCutoff: 85,
            menuLimit: 500,
            valueFilter: {
                Date: {},
                Provider: {},
                'Patient Count': {},
                'Visit Type': {}
            },
        }
    );
    const handleOnPivoteChange = (newState) => {
        setPivotState((pre) => {
            const updated = filterDuplicates(newState)
            // updated.cols.map((item) => {
            //     updated.valueFilter[item] = { undefined: true }
            // })
            // console.log(updated)
            return updated
        });
    };
    const createValueFilter = (headers) => {
        return headers?.reduce((acc, header) => {
            acc[header] = {};
            return acc;
        }, {});
    };
    const processLargeData = (data, chunkSize = 500) => {
        let index = 0;
        const totalChunks = Math.ceil(data.length / chunkSize);

        const processNextChunk = () => {
            if (index >= totalChunks) {
                return;
            }
            const start = index * chunkSize;
            const end = start + chunkSize;
            const chunk = data.slice(start, end);

            setPivotState(prevState => ({
                ...prevState,
                data: [...prevState.data, ...chunk]
            }));
            index++;
            setTimeout(processNextChunk, 0);

        };
        processNextChunk();
    };
    const updateStateOfPivot = async (data, obj) => {
        try {
            if (obj.isCsvData === true) {
                if (data?.length > 0) {
                    setPivoteRows([`${data[0][0]}`]);
                    setPivoteCols([`${data[0][1]}`])
                }
                await setPivotState((prevState) => ({
                    ...prevState,
                    data: data,
                    valueFilter: createValueFilter(data[0]),
                    vals: []
                }));
                setLoading(false)
                // processApiData(data)
            } else {
                const newData = processApiData(data);
                if (newData?.length > 0) {
                    setPivoteRows([`${newData[0][1]}`])
                    setPivoteCols([`${newData[0][2]}`])
                }
                console.log(newData)
                await setPivotState(prevState => ({
                    ...prevState,
                    data: newData,
                    vals: ["Patient Count"],
                    valueFilter: newData?.length > 0 ? createValueFilter(newData[0]) : ""
                }));
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    React.useEffect(() => {
        const tableCon = document.querySelector(".pvtOutput")
        const tableHead = document.querySelector(".pvtTable > thead")
        const hrListContainer = document.querySelector(".pvtHorizList")
        const pvtTableContainer = document.querySelector(".pvtTable-container");
        if (false) {
            tableCon ? tableCon.classList.add("pivote-scrollable") : ""
            pvtTableContainer ? pvtTableContainer.classList.remove("h-80") : ""
        } else {
            tableCon ? tableCon.classList.remove("pivote-scrollable") : ""
            pvtTableContainer ? pvtTableContainer.classList.add("h-80") : ""
        }
    }, [pivotState])
    React.useEffect(() => {
        setPivotState({
            data: [],
            aggregatorName: "Integer Sum",
            vals: ["Patient Count"],
            renderers: Object.assign({}, TableRenderers, createPlotlyRenderers(Plot)),
            rowOrder: "key_a_to_z",
            colOrder: "key_a_to_z",
            rendererName: "Table",
            unusedOrientationCutoff: 85,
            menuLimit: 500,
            valueFilter: {
                Date: {},
                Provider: {},
                'Patient Count': {},
                'Visit Type': {}
            },
        })
        updateStateOfPivot(incomingData, { isCsvData: false })
    }, [incomingData])

    React.useEffect(() => {
        updateStateOfPivot(csvData, { isCsvData: true })
    }, [csvData])

    React.useEffect(() => {
        const dropdowns = document.querySelectorAll('.pvtVals .pvtDropdown');
        const dropdowns2 = document.querySelectorAll('.pvtRenderers .pvtDropdown');
        const pvtAxisContainer = document.querySelector('.pvtAxisContainer.pvtCols');
        const pvtRowsConatiner = document.querySelector('.pvtAxisContainer.pvtRows')
        const pvtTable = document.querySelector(".pvtTable");
        pvtTable ? pvtTable.id = id : ""
        dropdowns.forEach((dropdown) => {
            if (!dropdown.parentNode.querySelector('.dropdown-label')) {
                const label = document.createElement('label');
                label.textContent = 'Select Operation';
                label.className = 'dropdown-label';
                label.classList.add('pvtDropdown-label')
                dropdown.parentNode.insertBefore(label, dropdown);
            }
        });
        if (dropdowns[1] && !dropdowns[1].previousElementSibling?.classList.contains('dropdown-label2')) {
            const label = document.createElement('label');
            label.textContent = 'Value Types';
            label.className = 'dropdown-label2';
            label.classList.add('pvtDropdown-label');
            dropdowns[1].parentNode.insertBefore(label, dropdowns[1]);
        }
        dropdowns2.forEach((dropdown) => {
            if (!dropdown.previousElementSibling || dropdown.previousElementSibling.tagName.toLowerCase() !== 'label') {
                const label = document.createElement('label');
                label.textContent = 'Types of View';
                label.className = 'dropdown-label';
                label.classList.add('pvtDropdown-label')
                dropdown.parentNode.insertBefore(label, dropdown);
            }
        });
        if (pvtAxisContainer && !pvtAxisContainer.querySelector('.pvtCols-label')) {
            const label = document.createElement('label');
            label.textContent = 'Pivot Table Columns:';
            label.style.fontWeight = '700';
            label.style.marginRight = '10px';
            label.style.padding = '0px 6px'
            label.style.color = '#212529';
            label.className = 'pvtCols-label';
            const firstLi = pvtAxisContainer.querySelector('li');
            if (firstLi) {
                pvtAxisContainer.insertBefore(label, firstLi);
            }
        }
        if (pvtRowsConatiner && !pvtRowsConatiner.querySelector('.pvtRows-label')) {
            const label = document.createElement('label');
            label.textContent = 'Pivot Table Rows:';
            label.style.fontWeight = '700';
            label.style.marginRight = '10px';
            label.style.padding = '0px 6px'
            label.style.color = '#212529';
            label.className = 'pvtRows-label';
            const firstLi = pvtRowsConatiner.querySelector('li');
            if (firstLi) {
                pvtRowsConatiner.insertBefore(label, firstLi);
            }
        }
    });
    React.useEffect(() => {
        if (pivotState.aggregatorName === 'Count') {
            const dropdowns = document.querySelectorAll('.pvtVals .pvtDropdown');
            if (dropdowns[1] && !dropdowns[1].previousElementSibling?.classList.contains('dropdown-label2')) {
                const label = document.createElement('label');
                label.textContent = 'Value Types';
                label.className = 'dropdown-label2';
                label.classList.add('pvtDropdown-label')
                dropdowns[1].parentNode.insertBefore(label, dropdowns[1]);
            } else {
                const label = document.querySelector(".dropdown-label2");
                label ? label.remove() : ""
            }
        } else {
            const dropdowns = document.querySelectorAll('.pvtVals .pvtDropdown');
            if (dropdowns[1] && !dropdowns[1].previousElementSibling?.classList.contains('dropdown-label2')) {
                const label = document.createElement('label');
                label.textContent = 'Value Types';
                label.className = 'dropdown-label2';
                label.classList.add('pvtDropdown-label')
                dropdowns[1].parentNode.insertBefore(label, dropdowns[1]);
            }
        }
    }, [pivotState])
    const customAggregators = {
        'Count': pivottableUtilities.aggregators['Count'],
        'Sum': pivottableUtilities.aggregators['Sum'],
        'Average': pivottableUtilities.aggregators["Average"],
        'Count Unique Values': pivottableUtilities.aggregators["Count Unique Values"],
        'First': pivottableUtilities.aggregators["First"],
        'Integer Sum': pivottableUtilities.aggregators['Integer Sum'],
        'Last': pivottableUtilities.aggregators['Last'],
        'List Unique Values': pivottableUtilities.aggregators['List Unique Values'],
        'Maximum': pivottableUtilities.aggregators['Maximum'],
        'Median': pivottableUtilities.aggregators['Median'],
        'Minimum': pivottableUtilities.aggregators['Minimum'],
    };
    // List of allowed renderer names
    const allowedRenderers = ["Table"];
    // Filter renderers to only include allowed ones
    const filteredRenderers = Object.keys(pivotState.renderers)
        .filter(key => {
            return allowedRenderers.includes(key)
        })
        .reduce((obj, key) => {
            obj[key] = pivotState.renderers[key];
            return obj;
        }, {});
    return (
        <>
            {loading ? (
                <Stack sx={{ color: 'grey.500', height: '100%', alignItems: 'center', justifyContent: 'center' }} spacing={2} direction="row">
                    <CircularProgress color="inherit" />
                </Stack>
            ) : (<>
                {incomingData.length > 0 || csvData.length > 0 ?
                    <PivotTableUI
                        data={pivotState.data}
                        onChange={handleOnPivoteChange}
                        rows={pivoteRows}
                        cols={pivoteCols}
                        aggregatorName={pivotState.aggregatorName}
                        vals={csvData.length > 0 ? (pivotState.vals.length <= 0 ? [`${csvData[0][0]}`] : pivotState.vals) : pivotState.vals}
                        renderers={Object.assign({}, filteredRenderers, createPlotlyRenderers(Plot))}
                        rowOrder={pivotState.rowOrder}
                        colOrder={pivotState.colOrder}
                        rendererName={pivotState.rendererName}
                        unusedOrientationCutoff={Infinity}
                        menuLimit={Infinity}
                        valueFilter={pivotState.valueFilter}
                        aggregators={customAggregators}
                        id={id}
                    /> :
                    <div
                        className="d-flex align-Items-center justify-content-center absolute w-full my-5 py-3"
                        style={{ zIndex: 0 }}
                    >
                        <p className="text-xl font-bold text-primarygrayborder">
                            {" "}
                            No Records Found
                        </p>
                    </div>
                }</>)}
        </>
    )
}
export default PivotTable