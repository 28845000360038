import React from "react";
import moment from "moment";
import IconComponent from "../../../../../components/DataTable/Icon";
import CheckboxSelector from "../../../components/CheckboxSelector";
import classNames from "classnames";

const TABLE_HEADERS = [
  "Name",
  "Speciality",
  "Email",
  "Contact SMS / Phone",
  "Visits per Day",
  "Availability",
  "Leaves",
  "",
];

const WELCOME_TABLE_HEADERS = [
  "Name",
  "Speciality",
  "Visit per Day",
  "Availability",
  "Leave",
  "",
];

const DAY_MAP = {
  MONDAY: "M",
  TUESDAY: "T",
  WEDNESDAY: "W",
  THURSDAY: "Th",
  FRIDAY: "F",
  SATURDAY: "Sa",
  SUNDAY: "Su",
};
const ProviderTable = ({
  providers,
  handleEditClick,
  selectProvider,
  selectAllProviders,
  visitPerDaySort,
  handleVisitPerDaySort,
  isGlobalShow = true,
}) => {
  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value.map((day) => DAY_MAP[day]).join(", ")
        : type === "date"
          ? moment(value).format("MM/DD/YYYY")
          : value;
  };
  return (
    <div
      className={isGlobalShow ? "h-screen overflow-scroll no-scrollbar" : "overflow-auto max-h-96 min-h-40"}
    >
      <table className={(!isGlobalShow ? "" : "mb-[100px]") + " w-full "}>
        <thead>
          <tr className="bg-primarylightgrey">
            {isGlobalShow &&
              TABLE_HEADERS.map((header, index) => (
                <th key={index} className="px-4 py-3 text-base">
                  <div className="flex flex-row items-center">
                    <p className="m-0">{header}</p>
                  </div>
                </th>
              ))}
            {!isGlobalShow &&
              WELCOME_TABLE_HEADERS.map((header, index) => (
                <>
                  <th key={index} className="px-4 py-3 text-base">
                    <div className="flex flex-row items-center">
                      <p className="m-0">{header}</p>
                    </div>
                  </th>
                </>
              ))}
          </tr>
        </thead>
        {providers.length > 0 ?
          <tbody>
            {!isGlobalShow &&
              providers.map((provider) => (
                <tr className="text-base border-bottom" key={provider.id}>
                  <td className="px-4 py-3 text-left">{provider.name}</td>
                  <td className="px-4 py-3 text-left">{provider.speciality}</td>
                  <td className="px-4 py-3 text-left">
                    {parseNull(provider.visitsPerDay)}
                  </td>
                  <td className="px-4 py-3 text-left">{parseNull(provider.workingDays)}</td>
                  <td className="px-4 py-3 text-left">
                    <div className="flex flex-row gap-2 justify-center">{provider.ptos}</div>
                  </td>
                  <td className="px-4 py-3">
                    <button
                      className="p-2 text-white bg-gray-200 rounded-md"
                      onClick={() => {
                        handleEditClick(provider.id);
                      }}
                    >
                      <IconComponent
                        icon="Edit_Icon"
                        i={provider.id}
                        key={provider.id}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            {isGlobalShow &&
              providers.map((provider) => (
                <tr className="text-base border-bottom" key={provider.id}>
                  {/* <CheckboxSelector
                  key={provider.id}
                  header={provider.name}
                  isChecked={provider.isSelected}
                  handleChange={() => selectProvider(provider.id)}
                  textSize=""
                /> */}
                  <td className="px-4 py-3">{provider.name}</td>
                  <td className="px-4 py-3">{provider.speciality}</td>
                  <td className="px-4 py-3">NA</td>
                  <td className="px-4 py-3 text-center">NA</td>
                  <td className="px-4 py-3 text-center">
                    {parseNull(provider.visitsPerDay)}
                  </td>
                  <td className="px-4 py-3">{parseNull(provider.workingDays)}</td>
                  <td className="px-4 py-3">
                    <div className="flex flex-row gap-2">{provider.ptos}</div>
                  </td>
                  <td className="px-4 py-3">
                    <button
                      className="p-2 text-white bg-gray-200 rounded-md"
                      onClick={() => {
                        handleEditClick(provider.id);
                      }}
                    >
                      <IconComponent
                        icon="Edit_Icon"
                        i={provider.id}
                        key={provider.id}
                      />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
          : <tbody className="relative">
            <div
              className="d-flex align-Items-center justify-content-center absolute w-full my-3 py-2"
              style={{ zIndex: 0 }}
            >
              <p className="text-xl font-bold text-primarygrayborder">
                {" "}
                No Records Found
              </p>
            </div>
          </tbody>}

      </table>
    </div>
  );
};

export default ProviderTable;
