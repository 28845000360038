import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import CommunityVisitRules from "./tabs/CommunityVisitRules";
import MenuHeader from "../../../../components/Header/MenuHeader";
import SchedulingSettingsHeader from "../../components/SchedulingSettingsHeader";
import PatientVisitRules from "./tabs/PatientVisitRules";
import CommunityVisitRulesModal from "./components/CommunityVisitRulesModal";
import PatientVisitRulesModal from "./components/PatientVisitRulesModal";
import { useFetchVisitRulesMutation } from "../../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../features/auth/authSlice";

const VisitRules = ({ isWelcomePage, isFileUpload }) => {
  const [tab, setTab] = useState("community");
  const [refresh, setRefresh] = useState(false);

  const [visitRules, setVisitRules] = useState(null);
  const [opaque, setOpaque] = useState(false);

  const [communityVisitRulesModalOpen, setCommunityVisitRulesModalOpen] =
    useState(false);

  const [patientVisitRulesModalOpen, setPatientVisitRulesModalOpen] =
    useState(false);

  const handleCommunityVisitRulesModal = () => {
    setCommunityVisitRulesModalOpen(!communityVisitRulesModalOpen);
    setOpaque(!opaque);
  };

  const handlePatientVisitRulesModal = () => {
    setPatientVisitRulesModalOpen(!patientVisitRulesModalOpen);
    setOpaque(!opaque);
  };

  const [communityConstraintToEdit, setCommunityConstraintToEdit] =
    useState(null);

  const [patientConstraintToEdit, setPatientConstraintToEdit] = useState(null);

  const [fetchVisitRulesAPI] = useFetchVisitRulesMutation();

  const user = useSelector(selectUser);

  const fetchVisitRules = async () => {
    try {
      const response = await fetchVisitRulesAPI(user.organisation.id).unwrap();
      setVisitRules(response.data);
    } catch (error) {
      console.error(error.data.message);
    }
  };
  useEffect(() => {
    fetchVisitRules();
  }, [isFileUpload]);

  useEffect(() => {
    if (refresh) {
      fetchVisitRules();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (communityConstraintToEdit !== null) {
      handleCommunityVisitRulesModal();
    }
  }, [communityConstraintToEdit]);

  useEffect(() => {
    if (patientConstraintToEdit !== null) {
      handlePatientVisitRulesModal();
    }
  }, [patientConstraintToEdit]);

  useEffect(() => {
    if (!communityVisitRulesModalOpen) {
      setCommunityConstraintToEdit(null);
    }
  }, [communityVisitRulesModalOpen]);

  useEffect(() => {
    if (!patientVisitRulesModalOpen) {
      setPatientConstraintToEdit(null);
    }
  }, [patientVisitRulesModalOpen]);

  return (
    <div
      className={`relative flex flex-col w-full ${
        isWelcomePage ? "" : "h-screen"
      }`}
    >
      {!isWelcomePage && <SchedulingSettingsHeader title="Visit Rules" />}
      <div
        className={`w-full px-4 ${
          isWelcomePage ? "" : "h-screen overflow-hidden"
        }`}
      >
        <div
          className={`flex flex-col items-center gap-4 sm:flex-row ${
            isWelcomePage ? "mb-4" : "py-4"
          } `}
        >
          <button
            className={classNames(
              "px-4 py-2 font-bold rounded-lg sm:w-60 w-full",
              tab === "community"
                ? "bg-primaryblue text-white"
                : "text-gray-900 bg-white border border-gray-300"
            )}
            onClick={() => {
              setTab("community");
            }}
          >
            Community Visit Rules
          </button>
          <button
            className={classNames(
              "px-4 py-2 font-bold rounded-lg sm:w-60 w-full",
              tab === "patient"
                ? "bg-primaryblue text-white"
                : "text-gray-900 bg-white border border-gray-300"
            )}
            onClick={() => {
              setTab("patient");
            }}
          >
            Home Visit Rules
          </button>
        </div>
        <div className="w-full border-b-[1px] border-b-secondarygrayborder" />
        {tab === "community" && visitRules !== null && (
          <CommunityVisitRules
            rules={visitRules.community}
            handleCommunityVisitRulesModal={handleCommunityVisitRulesModal}
            constraintToEdit={communityConstraintToEdit}
            setConstraintToEdit={setCommunityConstraintToEdit}
            visitRules={visitRules}
            setVisitRules={setVisitRules}
            isWelcomePage={isWelcomePage}
          />
        )}
        {tab === "patient" && visitRules !== null && (
          <PatientVisitRules
            rules={visitRules.patient}
            handlePatientVisitRulesModal={handlePatientVisitRulesModal}
            constraintToEdit={patientConstraintToEdit}
            setConstraintToEdit={setPatientConstraintToEdit}
            visitRules={visitRules}
            setVisitRules={setVisitRules}
            isWelcomePage={isWelcomePage}
          />
        )}
        {communityVisitRulesModalOpen && (
          <CommunityVisitRulesModal
            handleCommunityVisitRulesModal={handleCommunityVisitRulesModal}
            setRefresh={setRefresh}
            details={communityConstraintToEdit}
            visitRules={visitRules}
            setVisitRules={setVisitRules}
          />
        )}
        {patientVisitRulesModalOpen && (
          <PatientVisitRulesModal
            handlePatientVisitRulesModal={handlePatientVisitRulesModal}
            setRefresh={setRefresh}
            details={patientConstraintToEdit}
            visitRules={visitRules}
            setVisitRules={setVisitRules}
          />
        )}
      </div>
    </div>
  );
};

export default VisitRules;
