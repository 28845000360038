import * as React from "react";

const ArrowUpRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 20}
    height={props.height || 20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M9.75 5.5a.75.75 0 0 1 0-1.5h5.5a.75.75 0 0 1 .75.75v5.5a.75.75 0 0 1-1.5 0V6.56l-9.22 9.22a.75.75 0 0 1-1.06-1.06l9.22-9.22H9.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ArrowUpRight;
