import React, { useEffect } from "react";
import BackOfficeUser from "./components/BackOfficeUser";
import ProviderUser from "./components/ProviderUser";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectUser, updateUser } from "../../features/auth/authSlice";
import { useGetUserMutation } from "../../features/user/userApiSlice";
import { useLogoutMutation } from "../../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";

const Account = () => {
  const user = useSelector(selectUser);

  const [getUser] = useGetUserMutation();
  const [useLogout] = useLogoutMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchUser = async () => {
    try {
      const { data } = await getUser().unwrap();

      dispatch(
        updateUser({
          user: data,
        })
      );
    } catch (error) {
      console.error("Failed to fetch user: ", error);
    }
  };

  const handleLogout = async () => {
    try {
      await useLogout().unwrap();
      dispatch(logOut());
      return navigate("/signin");
    } catch (error) {
      console.error("Failed to logout: ", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="flex flex-col gap-12 p-12">
      <div className="flex flex-row items-center justify-between">
        <h1 className="m-0 text-2xl font-bold">Account Details</h1>
        <div className="flex flex-row gap-6">
          <button className="py-2 font-semibold bg-white border-2 rounded-md shadow-sm border-primarylightgrey w-60">
            <span className="text-primarytextgrey">Edit Account Details</span>
          </button>
          <button
            onClick={handleLogout}
            className="py-2 font-semibold text-white rounded-md shadow-sm w-60 bg-primaryblue"
          >
            <span>Sign Out</span>
          </button>
        </div>
      </div>
      {user.type === 1 ? (
        <BackOfficeUser user={user} />
      ) : (
        <ProviderUser user={user} />
      )}
      <div className="w-full h-[1px] bg-primarygrayborder" />
      <div className="flex flex-row gap-12">
        <a href="#" className="no-underline text-lightblue">
          Privacy
        </a>
        <a href="#" className="no-underline text-lightblue">
          HIPAA Compliance
        </a>
      </div>
    </div>
  );
};

export default Account;
