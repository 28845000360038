import React, { useState } from "react";
import CloseSvg from "../../../../../assets/svgs/CloseSvg";
import { Icons } from "../../../../../assets";
import classNames from "classnames";
import CheckboxSelector from "../../../components/CheckboxSelector";

const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

const DAY_MAP = {
  MONDAY: "M",
  TUESDAY: "T",
  WEDNESDAY: "W",
  THURSDAY: "Th",
  FRIDAY: "F",
  SATURDAY: "Sa",
  SUNDAY: "Su",
};

const PatientVisitRulesFilter = ({
  handleNameFilter,
  handleAvailabilityFilter,
  nameFilter,
  AvailabilityFilter,
}) => {
  const [AvailabilityListOpen, setAvailabilityListOpen] = useState(false);

  const handleAvailabilityListOpen = () => {
    setAvailabilityListOpen(!AvailabilityListOpen);
  };

  return (
    <div className="flex flex-col md:flex-row w-full gap-8">
      <div className="relative flex flex-row items-center gap-2 divide-x-2 rounded-md outline outline-gray-200 w-full md:w-80">
        <div className="pl-2">
          <img
            src={Icons.Search_Icon}
            alt="search"
            className="w-5 h-5 cursor-pointer search-icon"
          />
        </div>
        <input
          type="text"
          placeholder="Search by Patient Name ..."
          className="w-full h-full p-2 text-base outline-none"
          value={nameFilter}
          onChange={(e) => handleNameFilter(e.target.value)}
        />
      </div>
      <div className="relative flex flex-row items-center gap-2 divide-x-2 rounded-md outline outline-gray-200 w-full md:w-80">
        <button onClick={handleAvailabilityListOpen} className="pl-2">
          <img
            src={Icons.Sort_Icon}
            alt="search"
            className="w-5 h-5 cursor-pointer search-icon"
          />
        </button>
        <div className="flex flex-row items-center w-full h-full gap-2 p-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
          {AvailabilityFilter.length === 0 && (
            <p className="m-0 text-base text-primarygrayborder whitespace-nowrap">
              Filter by Availability ...
            </p>
          )}
          {AvailabilityFilter.map((Availability, index) => (
            <div
              key={index}
              className="w-fit flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primarygrayborder "
            >
              <p aria-multiline={false} className="m-0 whitespace-nowrap">
                {Availability[0] + Availability.substring(1).toLowerCase()}
              </p>
              <button
                onClick={() => handleAvailabilityFilter(Availability)}
                className="flex items-center justify-center bg-white border border-white rounded-full aspect-square"
              >
                <CloseSvg color={"#BDBDBD"} />
              </button>
            </div>
          ))}
        </div>
        {AvailabilityListOpen && (
          <div className="absolute flex flex-col w-full overflow-auto bg-white border rounded-md h-52 -bottom-56 z-10">
            {days.map((Availability, index) => (
              <div
                className={classNames(
                  "hover:bg-primarylightgrey",
                  index !== days.length - 1 && "border-b"
                )}
              >
                <CheckboxSelector
                  key={index}
                  header={
                    Availability[0] + Availability.substring(1).toLowerCase()
                  }
                  isChecked={
                    AvailabilityFilter.includes(Availability) ? true : false
                  }
                  handleChange={(_e) => handleAvailabilityFilter(Availability)}
                  isBold={false}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientVisitRulesFilter;
