import Footer from "../components/Footer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  selectToken,
  selectUser,
  updateUser,
} from "../../../features/auth/authSlice";
import Header from "../components/Header";
import Steps from "../components/Steps";
import Content from "../components/Content";
import StepButtons from "../components/StepButtons";
import { FileUploader } from "react-drag-drop-files";
import { Icons } from "../../../assets";
import classNames from "classnames";
import { useUpdateTestDataMutation } from "../../../features/user/userApiSlice";
import { useRadioGroup } from "@mui/material";

const fileTypes = ["csv", "xlsx", "xls"];

const UploadCareplanLeft = ({
  handleChange,
  canUpload,
  handleStepChange,
  step,
  fileName,
  setOpaque,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const [updateTestData] = useUpdateTestDataMutation();

  const downloadCSV = async () => {
    // const response = await fetchCareplanTemplate().unwrap();
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/appointment/careplan-template`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "careplan-template.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleTestData = async (e) => {
    dispatch(
      updateUser({
        user: { ...user, showTestData: e.target.checked },
      })
    );
    // adding a API call to update the flag in BE
    await updateTestData({
      userId: user.id,
      showTestData: e.target.checked,
    });
  };

  return (
    <div className="flex flex-col lg:w-2/3 w-full h-screen px-6 py-8 relative justify-between">
      <Header setOpaque={setOpaque} />
      <Steps step={1} total={6} />
      <Content
        header={"Let's start scheduling"}
        content={`Welcome to CareSMS! To get started, please upload your Patient Care Plan data to begin using your Intelligent Scheduling Assistant. We’ll use this data to create optimized daily visit schedules for each of your Providers. All data on CareSMS is securely stored and HIPAA-compliant.`}
        onLinkClick={downloadCSV}
        link={"Download Patient Care Plan Template File"}
      />
      <div className="flex flex-row h-96 w-full p-4 gap-6">
        <div className="flex-1 flex flex-col gap-4">
          <div className="flex flex-col">
            <h2 className="font-bold sm:text-2xl text-xl items-center">
              Upload your Scheduling Data
            </h2>
            <p className="my-2 text-md">
              Upload your scheduling data to get started building Provider
              schedules in CareSMS. Once you upload your data, we will allow you
              to configure additional settings.
            </p>
          </div>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            classes="w-100"
            disabled={
              !canUpload || user?.showTestData || user?.showTestData || false
            }
          >
            <div className="flex flex-col h-full w-full justify-center items-center gap-2 border-[1px] border-dashed border-secondarygrayborder rounded-lg p-4 hover:bg-subtleblue transition-all cursor-pointer">
              <img
                src={Icons.Upload_Icon}
                alt="upload icon"
                className="mx-2 icon"
              />
              <p className="m-0 mt-2 text-sm">
                Drag & Drop or{" "}
                <span
                  className={classNames(
                    canUpload ? "text-primaryblue" : "text-primarydarkgrey"
                  )}
                >
                  Choose file
                </span>{" "}
                to upload
              </p>
              <p className="m-0 text-xs text-primarytextgrey">
                {" "}
                CSV, XLS or XLSX Max size of 800K
              </p>
            </div>
          </FileUploader>
          <p className={classNames("text-sm text-primarytextgrey")}>
            {!fileName ? (
              "Upload a new scheduling data file for CareSMS to create daily Provider schedules"
            ) : (
              <span>
                File: <span className="text-primaryblue">{fileName}</span>{" "}
                uploaded successfully
              </span>
            )}
          </p>
        </div>
        <div className="w-[1px] h-auto bg-primarygrayborder" />
        <div className="flex-1 flex flex-col gap-4">
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-2">
              <input
                disabled={fileName}
                type="checkbox"
                id="custom-checkbox"
                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 mb-2"
                checked={user?.showTestData || false}
                onChange={handleTestData}
              />
              <h2 className="font-bold sm:text-2xl text-xl items-center">
                Use Sample Data
              </h2>
            </div>
            <p className="my-2 text-md">
              Not ready to upload your own data? Use sample data to get familiar
              with the system and how the CareSMS Scheduling Assistant can
              create your daily Provider schedules!
            </p>
          </div>
        </div>
      </div>
      {/* <div className="my-2 flex justify-center">
        <div className="flex items-center">
          <input
            disabled={fileName}
            type="checkbox"
            id="custom-checkbox"
            className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            checked={user?.showTestData || false}
            onChange={handleTestData}
          />
          <label htmlFor="custom-checkbox" className="ml-2 text-gray-700">
            Use test data
          </label>
        </div>
      </div> */}
      <div>
        <StepButtons
          primaryText={"Step 2: Provider Availability"}
          secondaryText={"Go back to previous step"}
          primaryClick={() => handleStepChange(step + 1)}
          secondaryClick={() => {}}
        />
      </div>
      <div className="hidden lg:flex">
        <Footer />
      </div>
    </div>
  );
};

export default UploadCareplanLeft;
