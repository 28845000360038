import moment from "moment";
import React from "react";
import { Icons } from "../../../../assets";
import CloseSvg from "../../../../assets/svgs/CloseSvg";
import IconComponent from "../../../../components/DataTable/Icon";
import CheckboxSelector from "../../components/CheckboxSelector";
import { useUpsertProviderConstraintMutation } from "../../../../features/provider/providerApiSlice";
import classNames from "classnames";

const PROVIDER_TABLE_HEADERS = ["Name", "Start", "End", "Edit Settings"];

const DAY_MAP = {
  MONDAY: "M",
  TUESDAY: "T",
  WEDNESDAY: "W",
  THURSDAY: "Th",
  FRIDAY: "F",
  SATURDAY: "Sa",
  SUNDAY: "Su",
};

const SelectedProvidersTable = ({
  providers,
  setProviders,
  handleEditClick,
  hideRemove,
  selectProvider,
  gStartDate,
  gEndDate,
  isRunScheduler,
  handleStartDateChange,
  handleEndDateChange,
  providerDates,
  isWelComePage,
  selectAllProviders,
}) => {
  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
      ? value.map((day) => DAY_MAP[day]).join(", ")
      : type === "date"
      ? moment(value).format("MM/DD/YYYY")
      : value;
  };
  const [upsertProviderConstraintAPI] = useUpsertProviderConstraintMutation();
  const upsertProviderConstraint = async ({ label, date, id }) => {
    try {
      await upsertProviderConstraintAPI({
        id: id,
        data: {
          [label]: moment(date).format("YYYY-MM-DD"),
        },
      }).unwrap();
      const updatedProviders = providers.map((provider) => {
        if (provider.id === id) {
          return {
            ...provider,
            [label]: moment(date).format("YYYY-MM-DD"),
          };
        }
        return provider;
      });
      setProviders(updatedProviders);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className={` ${
        isWelComePage ? "mt-2 min-h-40" : "no-scrollbar"
      } px-4 my-4 overflow-x-auto overflow-y-auto max-h-96 overflow-clip`}
    >
      <table className="w-full mb-2 rounded-lg shadow-md overflow-clip">
        <thead className="sticky top-0">
          <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue">
            {PROVIDER_TABLE_HEADERS.map((header, index) => (
              <>
                {index === 0 ? (
                  <th key={index} className="text-base">
                    <div className="flex">
                      <CheckboxSelector
                        header={header}
                        isChecked={providers.every(
                          (provider) => provider.isSelected
                        )}
                        handleChange={(e) => {
                          selectAllProviders(e.target.checked);
                        }}
                      />
                    </div>
                  </th>
                ) : (
                  <th key={index} className="px-4 py-3 text-base">
                    <div className="flex">
                      <p className="m-0">{header}</p>
                    </div>
                  </th>
                )}
              </>
            ))}
          </tr>
        </thead>
        {providers.length > 0 ? (
          <tbody>
            {providers.map((provider, index) => (
              <tr
                className={classNames(
                  "text-base",
                  index !== providers.length - 1 && "border-bottom"
                )}
                key={provider.id}
              >
                <CheckboxSelector
                  key={provider.id}
                  header={provider.name}
                  isChecked={provider.isSelected}
                  handleChange={() => selectProvider(provider.id)}
                  textSize=""
                />
                <td className="items-start px-4 py-3">
                  <input
                    type="date"
                    className="w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:opacity-40"
                    min={moment().format("YYYY-MM-DD")}
                    defaultValue={provider.startDate}
                    value={providerDates[provider.id]?.startDate || gStartDate}
                    onChange={(e) => {
                      handleStartDateChange(provider.id, e.target.value);
                      upsertProviderConstraint({
                        date: e.target.value,
                        label: "current_start_date",
                        id: provider.id,
                      });
                    }}
                  />
                </td>
                <td className="px-4 py-3">
                  <input
                    type="date"
                    className="w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:opacity-40"
                    min={provider.startDate}
                    defaultValue={provider.endDate}
                    value={providerDates[provider.id]?.endDate || gEndDate}
                    onChange={(e) => {
                      handleEndDateChange(provider.id, e.target.value);
                      upsertProviderConstraint({
                        date: e.target.value,
                        label: "current_end_date",
                        id: provider.id,
                      });
                    }}
                  />
                </td>
                <td className="flex flex-row items-center gap-2 px-4 py-3 mt-2">
                  <button
                    className="text-white rounded-md"
                    onClick={() => {
                      handleEditClick(provider.id);
                    }}
                    title="View and modify this Provider's availability settings"
                  >
                    <IconComponent
                      icon="Edit_Icon"
                      i={provider.id}
                      key={provider.id}
                    />
                  </button>
                  {!hideRemove && (
                    <button
                      className="p-2 text-white bg-gray-200 rounded-md"
                      onClick={() => {
                        const newProviders = providers.map((prevProvider) => {
                          if (prevProvider.id === provider.id) {
                            return {
                              ...prevProvider,
                              isSelected: !prevProvider.isSelected,
                            };
                          }
                          return prevProvider;
                        });
                        setProviders(newProviders);
                      }}
                    >
                      <CloseSvg color={"#000"} />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className="relative">
            <div
              className="absolute w-full py-2 my-3 d-flex align-Items-center justify-content-center"
              style={{ zIndex: 0 }}
            >
              <p className="text-xl font-bold text-primarygrayborder">
                {" "}
                No Records Found
              </p>
            </div>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default SelectedProvidersTable;
