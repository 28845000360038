import React, { useEffect, useState } from "react";
import CloseSvg from "../../../../../assets/svgs/CloseSvg";
import axios from "axios";
import moment from "moment";
import { useGetPatientsMutation } from "../../../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../features/auth/authSlice";
import {
  useCreatePatientConstraintMutation,
  useUpdatePatientConstraintMutation,
} from "../../../../../features/constraints/patient/patientConstraintApiSlice.js";
import ReusableModal from "../../../../../components/Modals/ReusableModal.jsx";

const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

const PatientVisitRulesModal = ({
  handlePatientVisitRulesModal,
  details = null,
  setRefresh,
  visitRules,
  setVisitRules,
}) => {
  const [patients, setPatients] = useState([]);

  const [patientId, setPatientId] = useState(
    details === null ? [] : details.patientId
  );
  const [selectedDays, setSelectedDays] = useState(
    details === null ? [] : details.visitDays
  );
  const [from, setFrom] = useState(
    details === null ? details : details.startDate
  );
  const [to, setTo] = useState(details === null ? details : details.endDate);
  const [isError, setIsError] = React.useState(false);

  const handleSelectAll = () => {
    setSelectedDays(days);
  };

  const verifyDetails = () => {
    if (patientId === null || selectedDays.length === 0) {
      return false;
    }

    return true;
  };

  const [fetchPatientsAPI] = useGetPatientsMutation();

  const user = useSelector(selectUser);

  const fetchPatients = async () => {
    try {
      const response = await fetchPatientsAPI(user.organisation.id).unwrap();

      const patients = response.data.map((patient) => ({
        name: patient.name,
        id: patient.id,
      }));

      setPatients(patients);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const [createPatientConstraintAPI] = useCreatePatientConstraintMutation();

  const createPatientConstraint = async (data) => {
    try {
      await createPatientConstraintAPI({
        organisation_id: user.organisation.id,
        ...data,
      }).unwrap();

      setRefresh(true);
      setIsError(false);
      handlePatientVisitRulesModal();
    } catch (error) {
      console.error(error.data.message);
      setIsError(true);
    }
  };

  const [updatePatientConstraintAPI] = useUpdatePatientConstraintMutation();

  const updatePatientConstraint = async (data) => {
    try {
      await updatePatientConstraintAPI(data).unwrap();

      const updatedRules = visitRules.patient.map((rule) => {
        if (rule.id === data.id) {
          return {
            ...rule,
            patientId: data.patient_id,
            visitDays: data.visit_days,
            startDate: data.start_date,
            endDate: data.end_date,
          };
        }

        return rule;
      });

      const allRules = {
        ...visitRules,
        patient: updatedRules,
      };

      setVisitRules(allRules);

      handlePatientVisitRulesModal();
      setIsError(false);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  useEffect(() => {
    fetchPatients();
  }, []);

  return (
    <ReusableModal
      open
      title={"Home Visit Rule"}
      onClose={handlePatientVisitRulesModal}
      boxClasses="xs:w-[300px] md:w-[500px] overflow-y-auto"
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col items-start justify-start w-full gap-2 p-4">
          <p className="m-0 font-bold">Patient</p>
          <select
            className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
            value={patientId}
            onChange={(e) => setPatientId(e.target.value)}
            disabled={details !== null}
          >
            <option value={null}>Select</option>
            {patients.map((patient, index) => (
              <option key={index} value={patient.id}>
                {patient.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* <div className="w-full border border-primarygrayborder" /> */}
      <div className="flex flex-col items-start justify-between gap-2 p-2 px-4">
        <h1 className="m-0 text-lg font-bold">
          Availability
          <button className="ml-2 text-base" onClick={handleSelectAll}>
            (<span className="underline text-primaryblue">select all</span>)
          </button>
        </h1>
        <div className="flex flex-row flex-wrap w-full gap-3">
          {days.map((data, index) => (
            <label
              key={index}
              className="flex flex-row items-center gap-2 row-gap-4"
            >
              <input
                type="checkbox"
                className="w-4 h-4 checked:lightblue"
                checked={selectedDays.includes(data)}
                onChange={() => {
                  if (selectedDays.includes(data)) {
                    setSelectedDays(selectedDays.filter((day) => day !== data));
                  } else {
                    setSelectedDays([...selectedDays, data]);
                  }
                }}
              />
              <p className="m-0 text-sm">
                {data[0] + data.substring(1).toLowerCase()}
              </p>
            </label>
          ))}
        </div>
      </div>
      {/* <div className="w-full border border-primarygrayborder" /> */}
      <div className="flex flex-row items-center justify-between ">
        <div className="flex flex-col md:flex-row items-center w-100">
          <div className="flex flex-col w-full gap-2 p-2 px-4">
            <label className="font-semibold">From</label>
            <input
              type="date"
              className="p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
              min={moment().format("YYYY-MM-DD")}
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className="flex flex-col w-full gap-2 p-2 px-4">
            <label className="font-semibold">To</label>
            <input
              type="date"
              className="p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
              min={from}
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="w-full border border-primarygrayborder" />
      <div className="flex flex-col items-start justify-between gap-2 p-4">
        <div className="flex flex-row items-center justify-end w-full gap-4">
          <button
            className="px-4 py-2 font-bold text-white bg-gray-400 rounded-md"
            onClick={handlePatientVisitRulesModal}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 font-bold text-white rounded-md bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
            disabled={!verifyDetails()}
            onClick={async () => {
              if (details === null) {
                await createPatientConstraint({
                  patient_id: patientId,
                  visit_days: selectedDays,
                  start_date: from,
                  end_date: to,
                });
              } else {
                await updatePatientConstraint({
                  id: details.id,
                  patient_id: patientId,
                  visit_days: selectedDays,
                  start_date: from,
                  end_date: to,
                });
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default PatientVisitRulesModal;
