import React, { useEffect, useState } from "react";
import ScheduleCard from "./components/ScheduleCard";
import { useCreateAppointmentMutation } from "../../../features/appointment/appointmentApiSlice";
import classNames from "classnames";
import Loading from "../../../components/Loading";
import { Navigate, useNavigate } from "react-router-dom";

const GenerateSchedules = ({ providers, setCreatedCount }) => {
  const navigate = useNavigate();
  const filtredProviders = providers.filter((provider) => provider.isSelected);

  const [scheduledProviders, setScheduledProviders] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [createAppointment] = useCreateAppointmentMutation();

  const runScheduler = async () => {
    let count = 1;
    setIsProcessing(true);

    for (let index = 0; index < filtredProviders.length; index++) {
      try {
        await createAppointment(filtredProviders[index].id).unwrap();
        setCreatedCount(count);
        count++;

        const list = {
          id: filtredProviders[index].id,
          name: filtredProviders[index].name,
          speciality: filtredProviders[index].speciality,
          fileName: `${filtredProviders[index].name} (${filtredProviders[index].id}).csv`,
        };

        setScheduledProviders((prev) => {
          return [...prev, list];
        });
      } catch (error) {
        console.error(error.data.message);
      }
    }
    setIsProcessing(false);
  };

  const onDownload = () => {
    setIsDownload(true);
  };

  const onBack = () => {
    setIsDownload(false);
  };

  const onViewSchedule = () => {
    navigate("/home");
  };

  useEffect(() => {
    runScheduler();
  }, []);

  return (
    <div>
      {!isProcessing && isDownload && (
        <div className="flex mt-4 mx-6">
          <button
            className="flex items-center justify-center w-38 py-2 px-4 text-white rounded-lg bg-primaryblue"
            onClick={onBack}
          >
            Go back
          </button>
        </div>
      )}
      <div
        className={classNames(
          "flex flex-col w-full max-h-screen overflow-auto mb-28",
          (isProcessing || !isDownload) && "h-full"
        )}
      >
        {/* <h1 className="p-4 text-2xl font-bold text-primarytext">
        Successfully created schedules for {scheduledProviders.length} out of{" "}
        {filtredProviders.length}
      </h1> */}
        <div
          className={classNames(
            "flex flex-row flex-wrap items-center justify-center w-full row-gap-4 px-16 py-8 overflow-scroll column-gap-lg-5 no-scrollbar",
            (isProcessing || !isDownload) && "h-full"
          )}
        >
          {isProcessing && (
            <div className="flex flex-col justify-center">
              <Loading loading={isProcessing} />
            </div>
          )}
          {!isProcessing && !isDownload && (
            <div className="flex flex-col justify-center gap-3">
              <button
                className="flex items-center justify-center w-full py-2 px-4 text-white rounded-lg bg-primaryblue"
                onClick={onDownload}
              >
                Download providers schedules
              </button>
              <button
                className="flex items-center justify-center w-full py-2 px-4 text-white rounded-lg bg-primaryblue"
                onClick={onViewSchedule}
              >
                View schedules
              </button>
            </div>
          )}

          {!isProcessing &&
            isDownload &&
            scheduledProviders.map((provider, index) => (
              <ScheduleCard key={index} provider={provider} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default GenerateSchedules;
