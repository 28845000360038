import React from "react";

const Bolt = ({ color, height = "24" }) => {
  return (
    <svg
      width="18"
      height={height}
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6219 0.0628265C11.0355 0.222382 11.2919 0.65278 11.2444 1.10778L10.5244 8.00003H17.0357C17.3913 8.00003 17.7181 8.20301 17.8859 8.52817C18.0537 8.85332 18.0353 9.24786 17.838 9.55472L8.83805 23.5546C8.59409 23.9341 8.13344 24.0943 7.71817 23.9441C7.3029 23.7939 7.03824 23.3714 7.07476 22.9169L7.63059 16H0.964288C0.605842 16 0.276975 15.7938 0.110421 15.4646C-0.0561327 15.1355 -0.0328854 14.7377 0.17078 14.4318L9.49221 0.431893C9.74296 0.0552824 10.2083 -0.0967292 10.6219 0.0628265ZM2.80192 14H8.67857C8.94824 14 9.20558 14.1171 9.38817 14.3229C9.57076 14.5287 9.66192 14.8043 9.63953 15.083L9.30714 19.2194L15.2339 10H9.45C9.17618 10 8.91527 9.87929 8.73237 9.66796C8.54947 9.45663 8.46183 9.17463 8.49132 8.89232L8.91722 4.81539L2.80192 14Z"
        fill={color || "#3062D4"}
      />
    </svg>
  );
};

export default Bolt;
