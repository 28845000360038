import React, { useState } from "react";
import classNames from "classnames";
import OnboardingSteps from "./signup-steps";
import RightBanner from "../common/RightBanner";
import PricingPlans from "../common/PricingPlans";

const Signup = () => {
  const [plansModalOpen, setPlansModalOpen] = useState(false);

  const handlePlansModalOpen = (open) => {
    setPlansModalOpen(open);
  };

  return (
    <div
      className={classNames(
        "relative flex flex-row h-screen overflow-hidden",
        !plansModalOpen && "divide-x-2"
      )}
    >
      <OnboardingSteps handlePlansModalOpen={handlePlansModalOpen} />
      {/* <PricingPlans
        plansModalOpen={plansModalOpen}
        handlePlansModalOpen={handlePlansModalOpen}
      /> */}
      {/* <RightBanner /> */}
    </div>
  );
};

export default Signup;
