import React from 'react';
//import './ShapeComponent.css';

const ShapeComponent = ({ type }) => {
    const shapeStyle = {
        backgroundColor: type === 'circle' ? 'red' : 'blue',
    };

    return (
        <div className="shape-component" style={shapeStyle}>
            {type === 'circle' && <div className="circle"></div>}
            {type === 'rectangle' && <div className="rectangle"></div>}
        </div>
    );
};

export default ShapeComponent;
