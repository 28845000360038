import React from "react";

const Steps = ({ step = 2, total = 6 }) => {
  const percentage = (step / total) * 100;
  return (
    <div className="flex gap-4 align-items-center mx-4 my-3">
      <span>
        Step {step} of {total}
      </span>

      <div className="w-32 bg-gray-200 rounded-full h-1 dark:bg-gray-200">
        <div
          className="bg-blue-600 h-1 rounded-full"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export default Steps;
