
import React from 'react';
//import './TextboxComponent.css';


const TextboxComponent = ({ text, properties, id, onPropertyChange, setSelectedComponent }) => {
    return (
        <div className="textbox-component"
            style={{ height: '100%', width: '100%' }}
        >
            <textarea
                style={{
                    height: '100%',
                    width: '100%',
                    resize: 'none',
                    boxSizing: 'border-box',
                    border: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                    backgroundColor: `${properties?.backgroundColor}`,
                    textAlign: `${properties?.align}`,
                    color: `${properties?.textColor}`,
                    fontFamily: `${properties?.fontFamily}`,
                    fontWeight: `${properties?.fontWeight}`,
                    fontSize: `${properties?.fontSize}px`,
                    paddingLeft: `${properties.paddingLeft + 'px'}`,
                    paddingRight: `${properties.paddingRight + 'px'}`,
                    paddingTop: `${properties.paddingTop + 'px'}`,
                    paddingBottom: `${properties.paddingBottom + 'px'}`,
                    textDecoration: `${properties?.textdecoration}`,
                    lineHeight: `${properties?.lineHeight}`
                }}
                placeholder='Text'
                defaultValue={properties?.text}
                onChange={(e) => {
                    onPropertyChange({ text: e.target?.value });
                    setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                            ...prev.properties,
                            text: e.target?.value
                        }
                    }));
                    //setFlag(false)
                }}
            />
        </div>
    );
};

export default TextboxComponent;