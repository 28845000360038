
import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const AreaChartIcon = (props) => (
  <Tooltip title={props.tooltipText}>
    <svg
      fill="#1976d2"
      width={props.height}
      height={props.width}
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      class="icon">
      <path d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm-616-64h536c4.4 0 8-3.6 8-8V284c0-7.2-8.7-10.7-13.7-5.7L592 488.6l-125.4-124a8.03 8.03 0 0 0-11.3 0l-189 189.6a7.87 7.87 0 0 0-2.3 5.6V720c0 4.4 3.6 8 8 8z" />
    </svg>
    {/* <svg
      width={props.height}
      height={props.width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color='#1976d2'
    >
      <rect x="4" y="10" width="3" height="8" fill="currentColor" />
      <rect x="8" y="6" width="3" height="12" fill="currentColor" />
      <rect x="12" y="4" width="3" height="14" fill="currentColor" />

      <path
        d="M3 16L6 12L8 14L10 8L13 10L16 4"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
      />
    </svg> */}
  </Tooltip>
);

export default AreaChartIcon;



