import React, { useEffect, useState } from "react";
import { useSchedulerStore } from "../../../../context/scheduler.store";
import SchedulingSettingsHeader from "../../components/SchedulingSettingsHeader";
import ProvidersScreen from "./ProvidersScreen";
import { useGetProvidersMutation } from "../../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../features/auth/authSlice";

const ProvidersConfig = () => {
  const [opaque, setOpaque] = useState(false);
  const user = useSelector(selectUser);
  const providers = useSchedulerStore((state) => state.providers);
  const setProviders = useSchedulerStore((state) => state.setProviders);

  const [getProviders] = useGetProvidersMutation();

  const fetchProviders = async () => {
    try {
      const { data } = await getProviders(user.organisation.id).unwrap();

      setProviders(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  return (
    <div className="relative flex flex-col w-full h-screen">
      
      <SchedulingSettingsHeader title={"Providers"} />
      <ProvidersScreen
        setOpaque={setOpaque}
        providers={providers}
        setProviders={setProviders}
      />
    </div>
  );
};

export default ProvidersConfig;
