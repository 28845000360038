import React from "react";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { CancelSharp } from "@mui/icons-material";

const AppointmentCancelModal = ({ open, handleCancelModal, handleCancel }) => {
  return (
    <ReusableModal open={open} hideHeader>
      <div className="flex flex-col gap-3 p-3">
        <div className="flex flex-row gap-2">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-subtlered">
            <CancelSharp
              style={{
                color: "#EB5757",
              }}
            />
          </div>
          <div className="flex flex-col">
            <p className="m-0 font-semibold">Cancel Appointment</p>
            <p className="m-0 font-light">
              Are you sure you want to cancel this appointment? <br /> This
              action cannot be undone.
            </p>
          </div>
        </div>
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-row items-center justify-end gap-3">
          <button
            onClick={handleCancelModal}
            className="px-4 py-2 font-medium border rounded-md bg-primarylightgrey border-primarygray"
          >
            Don't Cancel
          </button>
          <button
            className="px-4 py-2 font-medium text-white border rounded-md bg-primaryblue border-primaryblue"
            onClick={async () => {
              await handleCancel();
              handleCancelModal();
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default AppointmentCancelModal;
