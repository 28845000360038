import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
      headers.set(
        "Access-Control-Allow-Origin",
        process.env.REACT_APP_SERVER_URL
      );
    }
    return headers;
  },
});

export const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    api.dispatch(logOut());
    window.location.reload();
    // console.log("Sending refresh token request");

    // const refreshResult = await baseQuery(
    //   "/auth/refresh-token",
    //   api,
    //   extraOptions
    // );

    // console.log(refreshResult);

    // if (refreshResult.data != null) {
    //   const user = api.getState().auth.user;

    //   const { accessToken } = refreshResult.data.data;

    //   api.dispatch(setCredentials({ token: accessToken, user }));

    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(logOut());
    // }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({}),
});
