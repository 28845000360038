import React from "react";

const UploadTable = ({
  headers = ["File Name", "Date", "User", "Patients", "Providers", ""],
  data = [],
}) => {
  return (
    <div className="h-[calc(100vh-400px)] overflow-auto flex flex-col gap-8">
      <div className="flex flex-col items-start justify-start gap-0">
        <p className="m-0 text-2xl font-bold">Previous Scheduling Data</p>
        <p className="m-0 text-sm text-primarytextgrey">
          Review the previous scheduling data files that you've uploaded to
          CareSMS
        </p>
      </div>
      <table className="table-auto w-full">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="px-4 py-3 text-sm bg-primarylightgrey text-center"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, o_index) => (
            <tr key={o_index} className="transition-all hover:bg-slate-50">
              {row.map((element, i_index) => {
                if (i_index === row.length - 1) {
                  return (
                    <td
                      key={i_index}
                      className="px-4 py-3 text-sm border-b-[1px] border-b-secondarygrayborder"
                    >
                      <a href={element} target="_blank" rel="noreferrer">
                        Download
                      </a>
                    </td>
                  );
                }
                return (
                  <td
                    key={i_index}
                    className="px-4 py-3 text-sm border-b-[1px] border-b-secondarygrayborder"
                  >
                    <p className="m-0">{element}</p>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UploadTable;
