import { apiSlice } from "../../app/api/apiSlice";

export const reportApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        getPatientReport: builder.mutation({
            query: (credentials) => ({
                url: `report/patient-visit?date_range=${credentials.dateRangeValue}&from_date=${credentials.from_date}&to_date=${credentials.to_date}&patient_id=${credentials.patient_id}&city=${credentials.city}&state=${credentials.state}&visit_type=${credentials.visit_type}&provider_id=${credentials.provider_id}&location=${credentials.location}`,
                method: "GET",
            }),
        }),
        getProviderActivityReport: builder.mutation({
            query: (credentials) => ({
                url: `report/provider-activity?provider_id=${credentials.provider_id}&provider_type=${credentials.provider_type}&date_range=${credentials.date_range}&from_date=${credentials.from_date}&to_date=${credentials.to_date}&state=${credentials.state}`,
                method: "GET",
            }),
        }),
        getRevenueReport: builder.mutation({
            query: (credentials) => ({
                url: `report/revenue?provider_id=${credentials.provider_id}&provider_type=${credentials.provider_type}&date_range=${credentials.date_range}&from_date=${credentials.from_date}&to_date=${credentials.to_date}&visit_type=${credentials.visit_type}&patient_id=${credentials.patient_id}`,
                method: "GET",
            }),
        }),
        getAppointmentCancellationReport: builder.mutation({
            query: (credentials) => ({
                url: `report/cancellation?provider_id=${credentials.provider_id}&provider_type=${credentials.provider_type}&date_range=${credentials.date_range}&from_date=${credentials.from_date}&to_date=${credentials.to_date}&visit_type=${credentials.visit_type}`,
                method: "GET",
            }),
        }),
        getTemplates: builder.mutation({
            query: (credentials) => ({
                url: `datastore/get-template`,
                method: "GET",
            }),
        }),
        createTemplates: builder.mutation({
            query: (credentials) => ({
                url: `datastore/create-template`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        updateTemplates: builder.mutation({
            query: (credentials) => ({
                url: `/datastore/update-template`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        getTemplatesByColumns: builder.mutation({
            query: (credentials) => ({
                url: `/datastore/get-templates-by-columns`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        getTemplateById: builder.mutation({
            query: (credentials) => ({
                url: `/datastore/get-template-by-id`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        getAllUserReports: builder.mutation({
            query: (credentials) => ({
                url: `/user-reports/get-user-reports`,
                method: "GET",
            }),
        }),
        getReportById: builder.mutation({
            query: (credentials) => ({
                url: `/user-reports/get-report/${credentials}`,
                method: "GET",
            }),
        }),
        creteUserReport: builder.mutation({
            query: (credentials) => ({
                url: `/user-reports/create-report`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        updateUserReport: builder.mutation({
            query: (credentials) => ({
                url: `/user-reports/update-report`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        getOverduePatientReport : builder.mutation({
            query: (credentials) => ({
                url: `/report/overdue?date_range=${credentials?.date_range}&from_date=${credentials.from_date}&to_date=${credentials.to_date}&city=${credentials.city}&visit_type=${credentials.visit_type}&patient_id=${credentials.patient_id}&location=${credentials.location}&provider_id=${credentials.provider_id}`,
                method: "GET",
            }),
        }),
        getNewPatientReport : builder.mutation({
            query: (credentials) => ({
                url: `/report/new-patients?date_range=${credentials.dateRangeValue}&from_date=${credentials.from_date}&to_date=${credentials.to_date}&city=${credentials.city}&state=${credentials.state}&location=${credentials.location}`,
                method: "GET",
               
            }),
        }),
    }),
});

export const {
    useGetPatientReportMutation,
    useGetProviderActivityReportMutation,
    useGetRevenueReportMutation,
    useGetAppointmentCancellationReportMutation,
    useGetTemplatesMutation,
    useCreateTemplatesMutation,
    useUpdateTemplatesMutation,
    useGetTemplatesByColumnsMutation,
    useGetAllUserReportsMutation,
    useGetReportByIdMutation,
    useCreteUserReportMutation,
    useUpdateUserReportMutation,
    useGetTemplateByIdMutation,
    useGetOverduePatientReportMutation,
    useGetNewPatientReportMutation
} = reportApiSlice;
