import { Navigate, Outlet, useLocation } from "react-router-dom";
import MenuHeader from "./components/Header/MenuHeader";
import { selectUser } from "./features/auth/authSlice";
import { useSelector } from "react-redux";
import SubscriptionAlert from "./components/Header/SubscriptionAlert";
import { useEffect, useState } from "react";
import { Alert, Collapse } from "@mui/material";
import { useLSStore } from "./context/ls-store";

const Layout = () => {
  const user = useSelector(selectUser);
  const location = useLocation();

  const authPaths = ["/signup", "/signin", "/"];
  const rootPath = "/";

  const [open, setOpen] = useState(false);

  if (
    (user?.completedOnboarding || user?.step >= 4) &&
    authPaths.includes(location?.pathname)
  ) {
    if (!user?.completedOnboarding) {
      return <Navigate to="/welcome" />;
    }
    return <Navigate to="/home" />;
  } else {
    if (rootPath === location?.pathname) {
      return <Navigate to="/signin" />;
    }
  }

  const alert = useLSStore((state) => state.alert);
  const setAlert = useLSStore((state) => state.setAlert);

  useEffect(() => {
    if (alert) {
      setOpen(true);
    }
  }, [alert]);

  return (
    <main className="overflow-hidden no-scrollbar">
      {(user?.completedOnboarding || user?.step >= 4) &&
        location?.pathname !== "/welcome" && (
          <>
            {user.subscription !== null &&
              user?.subscription?.status === "trialing" && <SubscriptionAlert />}
            <MenuHeader user={user} />
          </>
        )}
      <div className="z-50 w-full">
        <Collapse in={open}>
          <Alert
            sx={{ mb: 2 }}
            onClose={() => {
              setOpen(false);
              setAlert(null);
            }}
            severity={alert === null ? "success" : alert.severity}
          >
            {alert && alert.message}
          </Alert>
        </Collapse>
      </div>
      <Outlet />
    </main>
  );
};

export default Layout;
