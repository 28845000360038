import React from "react";

const Download = ({ color }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V10.037L11.7413 8.1989C12.0457 7.91794 12.5202 7.93692 12.8011 8.24129C13.0821 8.54566 13.0631 9.02015 12.7587 9.3011L9.50871 12.3011C9.22141 12.5663 8.77859 12.5663 8.49129 12.3011L5.24129 9.3011C4.93692 9.02015 4.91794 8.54566 5.1989 8.24129C5.47985 7.93692 5.95434 7.91794 6.25871 8.1989L8.25 10.037V1.25C8.25 0.835786 8.58579 0.5 9 0.5ZM0.75 12.5C1.16421 12.5 1.5 12.8358 1.5 13.25V15.75C1.5 16.4404 2.05964 17 2.75 17H15.25C15.9404 17 16.5 16.4404 16.5 15.75V13.25C16.5 12.8358 16.8358 12.5 17.25 12.5C17.6642 12.5 18 12.8358 18 13.25V15.75C18 17.2688 16.7688 18.5 15.25 18.5H2.75C1.23122 18.5 0 17.2688 0 15.75V13.25C0 12.8358 0.335786 12.5 0.75 12.5Z"
        fill={color || "#3062D4"}
      />
    </svg>
  );
};

export default Download;
