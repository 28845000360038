import React, { useState } from "react";
import CloseSvg from "../../../../../assets/svgs/CloseSvg";
import { useUpdateAppointmentTypeMutation } from "../../../../../features/appointment/appointmentApiSlice";
import ReusableModal from "../../../../../components/Modals/ReusableModal";

const AppointmentTypeModal = ({
  appointments,
  handleEditClick,
  details,
  setAppointmentTypes,
  screen = "Visit Type",
}) => {
  const [appointmentTypeId, setAppointmentTypeId] = useState(details.id);
  const [rotation, setRotation] = useState(details.rotation);
  const [earlyVisitWindow, setEarlyVisitWindow] = useState(
    details.earlyVisitWindow
  );
  const [duration, setDuration] = useState(details.duration || 0);
  const [rate, setRate] = useState(details.rate || 0.0);

  const verifyDetails = () => {
    return (
      isNaN(parseInt(rotation)) ||
      parseInt(rotation) < 0 ||
      isNaN(parseInt(earlyVisitWindow)) ||
      parseInt(earlyVisitWindow) < 0 ||
      isNaN(parseInt(duration)) ||
      parseInt(duration) < 0 ||
      isNaN(parseInt(rate)) ||
      parseInt(rate) < 0
    );
  };

  const [updateAppointmentTypeAPI] = useUpdateAppointmentTypeMutation();

  const updateAppointmentType = async () => {
    try {
      await updateAppointmentTypeAPI({
        appointmentTypeId,
        rotation: parseInt(rotation),
        duration:
          isNaN(parseInt(duration)) || parseInt(duration) === 0
            ? null
            : parseInt(duration),
        rate:
          isNaN(parseInt(rate)) || parseInt(rate) === 0 ? null : parseInt(rate),
        early_visit_window:
          isNaN(parseInt(earlyVisitWindow)) || parseInt(earlyVisitWindow) === 0
            ? undefined
            : parseInt(earlyVisitWindow),
      }).unwrap();

      const newAppointmentTypes = appointments.map((appointment) => {
        if (appointment.id === appointmentTypeId) {
          return {
            ...appointment,
            rotation: parseInt(rotation),
            duration:
              isNaN(parseInt(duration)) || parseInt(duration) === 0
                ? null
                : parseInt(duration),
            rate:
              isNaN(parseInt(rate)) || parseInt(rate) === 0
                ? null
                : parseInt(rate),
            earlyVisitWindow:
              isNaN(parseInt(earlyVisitWindow)) ||
              parseInt(earlyVisitWindow) === 0
                ? undefined
                : parseInt(earlyVisitWindow),
          };
        }
        return appointment;
      });

      setAppointmentTypes(newAppointmentTypes);

      handleEditClick();
    } catch (error) {
      console.error(error.data.message);
    }
  };

  return (
    <ReusableModal
      open
      title={"Visit Type"}
      onClose={handleEditClick}
      boxClasses="xs:w-[300px] md:w-[400px] overflow-y-auto"
    >
      {/* <div className="w-full border border-primarygrayborder" /> */}
      <div className="flex flex-row items-center justify-between divide-x-2">
        <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
          <p className="m-0 font-bold">Visit</p>
          <select
            value={appointmentTypeId}
            onChange={(e) => setAppointmentTypeId(e.target.value)}
            className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
          >
            <option value={null}>Select</option>
            {appointments.map((appointment, index) => (
              <option key={index} value={appointment.id}>
                {appointment.appointmentName}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* <div className="w-full border border-primarygrayborder" /> */}
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
            <p className="m-0 font-bold whitespace-nowrap">Rotation Schedule (Days)</p>
            <input
              type="number"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
              value={rotation}
              min={0}
              defaultValue={0}
              onChange={(e) => setRotation(e.target.value)}
            />
          </div>
        </div>
        {screen !== "Visit Type" && (
          <div className="flex flex-row items-center">
            <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
              <p className="m-0 font-bold whitespace-nowrap">
                Early Visit Window (Days)
              </p>
              <input
                type="number"
                className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
                value={earlyVisitWindow}
                min={0}
                defaultValue={0}
                onChange={(e) => setEarlyVisitWindow(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
      {screen === "Visit Type" && (
        <>
          {/* <div className="w-full border border-primarygrayborder" /> */}
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
                <p className="m-0 font-bold whitespace-nowrap">
                  Early Visit Window (Days)
                </p>
                <input
                  type="number"
                  className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
                  value={earlyVisitWindow}
                  min={0}
                  defaultValue={0}
                  onChange={(e) => setEarlyVisitWindow(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
                <p className="m-0 font-bold whitespace-nowrap">
                Visit Duration (mins)
                </p>
                <input
                  type="number"
                  className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
                  value={duration}
                  min={0}
                  defaultValue={0}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
                <p className="m-0 font-bold whitespace-nowrap">Bill Rate ($)</p>
                <input
                  type="number"
                  className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
                  value={rate}
                  min={0}
                  defaultValue={0}
                  onChange={(e) => setRate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="w-full border border-primarygrayborder" />
      <div className="flex flex-col items-start justify-between gap-2 p-4">
        <div className="flex flex-row items-center justify-end w-full gap-4">
          <button
            className="px-4 py-2 font-bold text-white bg-gray-400 rounded-md"
            onClick={handleEditClick}
          >
            Cancel
          </button>
          <button
            disabled={verifyDetails()}
            className="px-4 py-2 font-bold text-white rounded-md bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
            onClick={() => updateAppointmentType()}
          >
            Save
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default AppointmentTypeModal;
