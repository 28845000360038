export const BACK_OFFICE_ROLE = {
  1: "Admin",
  2: "Scheduler",
  3: "Executive",
  4: "Creator",
};

export const COMMUNITY_ROLE = {
  1: "Nurse",
  2: "Admin",
  3: "Supervisor",
};

export const USER_ROLE = {
  1: "Back Office",
  2: "Community",
  3: "Provider",
  4: "Patient",
  5: "Patient POC",
};
