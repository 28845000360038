import { update } from "react-spring";
import { apiSlice } from "../../app/api/apiSlice";

export const providerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    setGlobalVisitsPerDay: builder.mutation({
      query: (credentials) => ({
        url: "/provider/constraint/global/visits-per-day",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    upsertProviderConstraint: builder.mutation({
      query: (credentials) => ({
        url: `/provider/constraint/${credentials.id}`,
        method: "POST",
        body: { ...credentials.data },
      }),
    }),
    fetchProviderLeaves: builder.mutation({
      query: (credentials) => `/provider/leave/${credentials.id}`,
      method: "GET",
    }),
    createProviderLeave: builder.mutation({
      query: (credentials) => ({
        url: `/provider/leave/${credentials.id}`,
        method: "POST",
        body: { ...credentials.data },
      }),
    }),
    setGlobalStartEndDate: builder.mutation({
      query: (credentials) => ({
        url: `/provider/constraint/global/start-end-date`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updateProviderLeave: builder.mutation({
      query: (credentials) => ({
        url: `/provider/leave/${credentials.id}`,
        method: "PATCH",
        body: { ...credentials.data },
      }),
    }),
    updateProviderMissingFields: builder.mutation({
      query: (credentials) => ({
        url: `/provider/update/missing/${credentials.id}`,
        method: "PATCH",
        body: { ...credentials.data },
      }),
    }),
    removeProviderLeave: builder.mutation({
      query: (credentials) => ({
        url: `/provider/leave/${credentials.id}`,
        method: "DELETE",
      }),
    }),
    fetchProviderCareplans: builder.mutation({
      query: (credentials) => ({
        url: `/provider/careplans/${credentials}`,
        method: "GET",
      }),
    }),
    fetchProviderById: builder.mutation({
      query: (credentials) => ({
        url: `/provider/${credentials}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useSetGlobalVisitsPerDayMutation,
  useUpsertProviderConstraintMutation,
  useFetchProviderLeavesMutation,
  useCreateProviderLeaveMutation,
  useUpdateProviderLeaveMutation,
  useUpdateProviderMissingFieldsMutation,
  useRemoveProviderLeaveMutation,
  useFetchProviderCareplansMutation,
  useFetchProviderByIdMutation,
  useSetGlobalStartEndDateMutation,
} = providerApiSlice;
