import { update } from "react-spring";
import { apiSlice } from "../../app/api/apiSlice";

export const patientApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchPatientById: builder.mutation({
      query: (credentials) => ({
        url: `/patient/${credentials}`,
        method: "GET",
      }),
    }),
    fetchPatientCareteam: builder.mutation({
      query: (credentials) => ({
        url: `/patient/careteam/${credentials}`,
        method: "GET",
      }),
    }),
    fetchPatientHouseholdMembers: builder.mutation({
      query: (credentials) => ({
        url: `/patient/household-members/${credentials}`,
        method: "GET",
      }),
    }),
    getNewPatients: builder.mutation({
      query: (credentials) => ({
        url: `/patient/new?organisationId=${credentials.organisationId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useFetchPatientByIdMutation,
  useFetchPatientCareteamMutation,
  useFetchPatientHouseholdMembersMutation,
  useGetNewPatientsMutation,
} = patientApiSlice;
