import React from "react";
import IconComponent from "../../../components/DataTable/Icon";
import classNames from "classnames";
import { useGetPatientsMutation } from "../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { Search_Icon } from "../../../assets/images";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  FilterList,
} from "@mui/icons-material";
import CheckboxSelector from "../../Schedules/components/CheckboxSelector";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const PatientList = () => {
  const [patients, setPatients] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);

  const [statusFilter, setStatusFilter] = React.useState([]);
  const [locationFilter, setLocationFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [locationDropdown, setLocationDropdown] = React.useState(false);
  const [statusDropdown, setStatusDropdown] = React.useState(false);

  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");

  const navigate = useNavigate();

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleLocationFilter = (location) => {
    if (locationFilter.indexOf(location) === -1) {
      setLocationFilter([...locationFilter, location]);
    } else {
      setLocationFilter(locationFilter.filter((filter) => filter !== location));
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const handlePatientClick = (patient) => {
    navigate(`/care-management/patients/${patient.id}`);
  };

  const user = useSelector(selectUser);
  const [getPatients] = useGetPatientsMutation();

  const fetchPatients = async () => {
    try {
      const { data } = await getPatients(user.organisation.id).unwrap();

      setPatients(data);
      setLocations(
        Array.from(
          new Set(
            data.map((patient) =>
              patient.locationName === null
                ? "Private Residence"
                : patient.locationName
            )
          )
        )
      );
      setStatuses(Array.from(new Set(data.map((patient) => patient.status))));
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    fetchPatients();
  }, []);

  const Avatar = ({ name, image }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className="object-cover w-10 h-10 rounded-full"
            />
            <p className="m-0 font-semibold">{name}</p>
          </div>
        ) : (
          <p className="flex items-center justify-center w-10 h-10 m-0 text-white rounded-full bg-primaryblue">
            {name
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Patients{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {patients.length}
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <div className="relative w-1/3 flex flex-row items-center gap-2 rounded-md border-[2px] border-primarygrayborder overflow-clip">
              <div className="pl-2">
                <img
                  src={Search_Icon}
                  alt="search"
                  className="w-5 h-5 cursor-pointer search-icon opacity-80"
                />
              </div>
              <input
                type="text"
                placeholder="Search..."
                className="w-full h-full p-2 text-base outline-none"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </div>
            <div className="w-1/3 relative flex flex-row divide-x-[2px] divide-primarygrayborder items-center gap-2 rounded-md border-[2px] border-primarygrayborder h-10">
              <div className="ml-2 min-w-fit">
                <button
                  onClick={() => setLocationDropdown(!locationDropdown)}
                  className={classNames(
                    "rounded-full w-7 h-7",
                    locationFilter.length > 0
                      ? "bg-primaryblue"
                      : "bg-primarygrayborder"
                  )}
                >
                  <FilterList
                    style={{
                      color: "#FFF",
                      fontSize: "20px",
                    }}
                  />
                </button>
              </div>
              <div className="flex flex-row items-center h-full gap-2 px-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
                {locationFilter.length === 0 && (
                  <p className="m-0 text-base text-primarygrayborder px-2 py-[2px] whitespace-nowrap">
                    Filter by Location ...
                  </p>
                )}
                {locationFilter.map((status, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primaryblue"
                  >
                    <p aria-multiline={false} className="m-0 whitespace-nowrap">
                      {status}
                    </p>
                    <button
                      onClick={() => handleLocationFilter(status)}
                      className="flex items-center justify-center scale-90 border border-white rounded-full aspect-square"
                    >
                      <CloseSvg color={"#FFF"} />
                    </button>
                  </div>
                ))}
              </div>
              {locationDropdown && Array.isArray(patients) && (
                <div className="absolute z-50 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14 speciality-dropdown">
                  {locations.map((status, index) => (
                    <div
                      className={classNames(
                        "hover:bg-primarylightgrey",
                        index !==
                          new Set(
                            patients.map((patient) => patient.locationName)
                          ).size -
                            1 && "border-b"
                      )}
                    >
                      <CheckboxSelector
                        key={index}
                        header={status}
                        isChecked={locationFilter.indexOf(status) !== -1}
                        handleChange={(_e) => handleLocationFilter(status)}
                        isBold={false}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="w-1/3 relative flex flex-row divide-x-[2px] divide-primarygrayborder items-center gap-2 rounded-md border-[2px] border-primarygrayborder h-10">
              <div className="ml-2 min-w-fit">
                <button
                  onClick={() => setStatusDropdown(!statusDropdown)}
                  className={classNames(
                    "rounded-full w-7 h-7",
                    statusFilter.length > 0
                      ? "bg-primaryblue"
                      : "bg-primarygrayborder"
                  )}
                >
                  <FilterList
                    style={{
                      color: "#FFF",
                      fontSize: "20px",
                    }}
                  />
                </button>
              </div>
              <div className="flex flex-row items-center h-full gap-2 px-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
                {statusFilter.length === 0 && (
                  <p className="m-0 text-base text-primarygrayborder px-2 py-[2px] whitespace-nowrap">
                    Filter by Status ...
                  </p>
                )}
                {statusFilter.map((status, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primaryblue"
                  >
                    <p aria-multiline={false} className="m-0 whitespace-nowrap">
                      {status}
                    </p>
                    <button
                      onClick={() => handleStatusFilter(status)}
                      className="flex items-center justify-center scale-90 border border-white rounded-full aspect-square"
                    >
                      <CloseSvg color={"#FFF"} />
                    </button>
                  </div>
                ))}
              </div>
              {statusDropdown && Array.isArray(patients) && (
                <div className="absolute z-50 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14 speciality-dropdown">
                  {statuses.map((status, index) => (
                    <div
                      className={classNames(
                        "hover:bg-primarylightgrey",
                        index !==
                          new Set(patients.map((patient) => patient.status))
                            .size -
                            1 && "border-b"
                      )}
                    >
                      <CheckboxSelector
                        key={index}
                        header={status}
                        isChecked={statusFilter.indexOf(status) !== -1}
                        handleChange={(_e) => handleStatusFilter(status)}
                        isBold={false}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <ArrowDownwardTwoTone />
                      ) : sortNameOrder === "desc" ? (
                        <ArrowUpwardTwoTone />
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">Date of Birth</th>
                <th className="px-4 py-3">Gender</th>
                <th className="px-4 py-3">EHR ID</th>
                <th className="px-4 py-3">Private / Group Home</th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Status</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortStatusOrder === "none") {
                          setSortStatusOrder("asc");
                        }
                        if (sortStatusOrder === "asc") {
                          setSortStatusOrder("desc");
                        }
                        if (sortStatusOrder === "desc") {
                          setSortStatusOrder("none");
                        }
                      }}
                    >
                      {sortStatusOrder === "asc" ? (
                        <ArrowDownwardTwoTone />
                      ) : sortStatusOrder === "desc" ? (
                        <ArrowUpwardTwoTone />
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                {/* <th className="px-4 py-3">Edit</th> */}
              </tr>
            </thead>
            <tbody>
              {patients
                .filter((patient) =>
                  patient.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
                )
                .filter(
                  (patient) =>
                    statusFilter.length === 0 ||
                    statusFilter.indexOf(patient.status) !== -1
                )
                .filter(
                  (patient) =>
                    locationFilter.length === 0 ||
                    locationFilter.indexOf(
                      patient.locationName === null
                        ? "Private Residence"
                        : patient.locationName
                    ) !== -1
                )
                .sort((a, b) => handleSortName(a, b))
                .sort((a, b) => handleSortStatus(a, b))
                .map((patient, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== patients.length - 1 ? "border-b" : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <button
                        onClick={() => handlePatientClick(patient)}
                        className="flex flex-row items-center gap-4"
                      >
                        <Avatar name={patient.name} image={null} />
                        <p className="m-0 font-semibold underline">
                          {patient.name}
                        </p>
                      </button>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {moment(patient.dob).format("MM/DD/YYYY")}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {patient.sex === "F" ? "Female" : "Male"}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {patient.patientEhrId}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {patient.locationName === null
                        ? "Private Residence"
                        : patient.locationName}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      <div
                        className={classNames(
                          patient.status.toUpperCase() === "ACTIVE"
                            ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                            : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                          "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                        )}
                      >
                        <div
                          className={classNames(
                            "w-2 h-2 rounded-full",
                            patient.status.toUpperCase() === "ACTIVE"
                              ? "bg-primarygreen"
                              : "bg-primarytextgrey"
                          )}
                        />
                        {patient.status}
                      </div>
                    </td>
                    {/* <td className="px-4 py-3 text-left">
                    <button
                      className="p-2 text-white rounded-md"
                      onClick={() => {}}
                    >
                      <IconComponent icon={"Edit_Icon"} i={provider.id} />
                    </button>
                  </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PatientList;
