import React, { useEffect } from "react";
import Logo from "../../../assets/images/CareSMS_Logo.png";
import {
  useGoogleAuthMutation,
  useVerifyEmailMutation,
} from "../../../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import CalendarCheckSvg from "../../../assets/svgs/CalendarCheckSvg";
import LocationSvg from "../../../assets/svgs/LocationSvg";
import { ArrowRight } from "@mui/icons-material";

const Auth = ({ option, step, handleStepChange, email, setEmail }) => {
  const [errMsg, setErrMsg] = React.useState(null);

  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const [googleAuth] = useGoogleAuthMutation();
  const navigate = useNavigate();
  const handleEmailAuth = async () => {
    try {
      await verifyEmail({
        email: email,
        path: option,
        provider: "Email",
      }).unwrap();

      handleStepChange(step + 1);
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };

  const handleGoogleAuth = async () => {
    try {
      // const google_user = await googleAuth().unwrap();
      window.open(`${process.env.REACT_APP_BACKEND_URL}/auth/google`, "_self");
      // console.log(google_user, "google_user");
      // handleStepChange(step + 1);
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    setErrMsg(null);
  }, [email]);

  return (
    <div className="relative flex flex-col gap-4 w-full h-[calc(100vh-20px)]">
      <div className="flex flex-col w-full h-full">
        <div className="justify-between w-full pb-4 d-flex">
          <img src={Logo} alt="logo" className="logo" />
          <button
            className={classNames(
              "py-2 font-semibold rounded-lg transition-all px-8",
              option === "signup"
                ? "border-[0.5px] border-black text-black"
                : "border-[0.5px] border-primaryblue text-primaryblue"
            )}
            onClick={() =>
              navigate(option === "signup" ? "/signin" : "/signup")
            }
          >
            Sign {option === "signup" ? "in" : "up"}
          </button>
        </div>
        <div className="flex flex-col justify-between h-screen max-w-fit">
          <div className="flex flex-col w-full gap-8">
            <div className="flex flex-col items-start gap-2">
              <h1 className="m-0 text-4xl font-bold">
                {option === "signup"
                  ? "Create your CareSMS account "
                  : "Sign in"}
              </h1>
              <h2
                className={classNames(
                  option === "signup"
                    ? "text-primaryorange text-base font-bold"
                    : "text-primarytextgrey text-lg"
                )}
              >
                {option === "signup"
                  ? "Sign up to begin your 30-day free trial today"
                  : "Streamline home-based care delivery with CareSMS"}
              </h2>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row items-center gap-2">
                <CalendarCheckSvg />
                <p className="m-0 text-lg font-bold">
                  Instantly create daily visit schedules for each Provider
                </p>
              </div>
              <div className="flex flex-row items-center gap-2">
                <LocationSvg />
                <p className="m-0 text-lg font-bold">
                  Automatically optimize for routing and visit clustering
                </p>
              </div>
              <div className="flex flex-row items-center gap-2">
                <LocationSvg />
                <p className="m-0 text-lg font-bold">
                  Manage visit rules for each Patient or Community
                </p>
              </div>
              <div className="flex flex-row items-center gap-2">
                <LocationSvg />
                <p className="m-0 text-lg font-bold">
                  Build and share key reports across your Practice
                </p>
              </div>
              <div className="flex flex-row items-center gap-2">
                <LocationSvg />
                <p className="m-0 text-lg font-bold">
                  Improve your quality of patient care delivery
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center grow">
              <div className="items-center justify-center gap-4 py-2 pt-4 d-flex flex-column sm:pt-5">
                <button
                  onClick={handleGoogleAuth}
                  className={classNames(
                    "text-lg text-center w-full py-2 text-white rounded-md",
                    option === "signup" ? "bg-primarygreen" : "bg-primaryblue"
                  )}
                >
                  {option === "signup"
                    ? "Sign up with Google Mail"
                    : "Sign in with Google Mail"}
                </button>
                <span className="text-sm font-bold">OR</span>
                <button
                  onClick={() => handleStepChange(step + 1)}
                  className="flex items-center justify-center w-full py-2 text-lg text-black rounded-md bg-primarylightgrey"
                >
                  {option === "signup"
                    ? "Sign up with other email"
                    : "Sign in with other email"}
                  <ArrowRight />
                </button>
              </div>
            </div>
          </div>
          <p className="text-sm text-center">
            2024 CareSMS Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Auth;
