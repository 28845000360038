import { create } from "zustand";

export const useLSStore = create((set) => ({
  draggingAppointment: null,
  setDraggingAppointment: (appointmentId) =>
    set({ draggingAppointment: appointmentId }),
  selectedAppointment: null,
  setSelectedAppointment: (appointmentId) =>
    set({ selectedAppointment: appointmentId }),
  filterOpen: false,
  setFilterOpen: (open) => set({ filterOpen: open }),
  defaultWeekIndx: (function () {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    let weekNumber = Math.ceil(days / 7) - 1;

    return weekNumber;
  })(),
  setDefaultWeekIndx: (indx) => set({ defaultWeekIndx: indx }),
  editable: false,
  setEditable: (editable) => set({ editable: editable }),
  subscription: null,
  setSubscription: (subscription) => set({ subscription }),
  alert: null,
  setAlert: (alert) => set({ alert }),
}));
