import React from "react";
import IconComponent from "../../../components/DataTable/Icon";
import classNames from "classnames";
import { useGetProvidersMutation } from "../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectToken, selectUser } from "../../../features/auth/authSlice";
import { Close_Icon, Search_Icon } from "../../../assets/images";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  FilterList,
} from "@mui/icons-material";
import CheckboxSelector from "../../Schedules/components/CheckboxSelector";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { useNavigate } from "react-router-dom";
import { useLSStore } from "../../../context/ls-store";
import { useCreateProviderUserMutation } from "../../../features/user/userApiSlice";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import ProviderUpdateModal from "./components/ProviderUpdateModal";
import { useUpdateProviderMissingFieldsMutation } from "../../../features/provider/providerApiSlice";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { Icons } from "../../../assets";
import { Collapse } from "@mui/material";

const fileTypes = ["csv", "xls", "xlsx"];

const ProviderList = () => {
  const [providers, setProviders] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [specialities, setSpecialities] = React.useState([]);

  const [specialityFilter, setSpecialityFilter] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [specialityDropdown, setSpecialityDropdown] = React.useState(false);
  const [statusDropdown, setStatusDropdown] = React.useState(false);

  const [inviteModal, setInviteModal] = React.useState(false);
  const [selectedProvider, setSelectedProvider] = React.useState(null);
  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");
  const [sortSpecialityOrder, setSortSpecialityOrder] = React.useState("none");

  const navigate = useNavigate();

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleSortSpeciality = (a, b) => {
    if (sortSpecialityOrder === "none") {
      return 0;
    } else if (sortSpecialityOrder === "asc") {
      return a.speciality.localeCompare(b.speciality);
    } else {
      return b.speciality.localeCompare(a.speciality);
    }
  };

  const handleSpecialityFilter = (speciality) => {
    if (specialityFilter.indexOf(speciality) === -1) {
      setSpecialityFilter([...specialityFilter, speciality]);
    } else {
      setSpecialityFilter(
        specialityFilter.filter((filter) => filter !== speciality)
      );
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const handleProviderClick = (provider) => {
    navigate(`/care-management/providers/${provider.id}`);
  };

  const user = useSelector(selectUser);
  const [getProviders] = useGetProvidersMutation();

  const [createProviderUser] = useCreateProviderUserMutation();
  const [updateProviderMissingFields] =
    useUpdateProviderMissingFieldsMutation();

  const updateMissingFields = async (data) => {
    try {
      await updateProviderMissingFields({
        id: selectedProvider.id,
        data: {
          npi_id: data.providerNpi,
          speciality: data.providerSpeciality,
          street: data.providerStreet,
          city: data.providerCity,
          state: data.providerState,
          zip: data.providerZip,
        },
      }).unwrap();

      const updatedProviders = providers.map((provider) => {
        if (provider.id === selectedProvider.id) {
          return {
            ...provider,
            npi_id: +data.providerNpi,
            speciality: data.providerSpeciality,
            address: [
              data.providerStreet,
              data.providerCity,
              data.providerState,
              data.providerZip,
            ],
          };
        }

        return provider;
      });

      setProviders(updatedProviders);

      setAlert({
        message: "Provider Updated Successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  const inviteProvider = async (data) => {
    try {
      await createProviderUser({
        providerId: selectedProvider.id,
        userData: {
          type: 3,
          email: data.providerEmail,
          phone: data.providerPhone,
        },
      }).unwrap();

      const updatedProviders = providers.map((provider) => {
        if (provider.id === selectedProvider.id) {
          return {
            ...provider,
            email: data.providerEmail,
            phone: data.providerPhone,
          };
        }
        return provider;
      });

      setProviders(updatedProviders);

      setAlert({
        message: "Provider Invited Successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  const fetchProviders = async () => {
    try {
      const { data } = await getProviders(user.organisation.id).unwrap();

      setProviders(data);
      setStatuses(Array.from(new Set(data.map((provider) => provider.status))));
      setSpecialities(
        Array.from(new Set(data.map((provider) => provider.speciality)))
      );
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    fetchProviders();
  }, []);

  const Avatar = ({ name, image }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className="object-cover w-10 h-10 rounded-full"
            />
            <p className="m-0 font-semibold">{name}</p>
          </div>
        ) : (
          <p className="flex items-center justify-center w-10 h-10 m-0 text-white rounded-full bg-primaryblue">
            {name
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </p>
        )}
      </>
    );
  };

  const setAlert = useLSStore((state) => state.setAlert);

  const token = useSelector(selectToken);

  async function uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/provider/verify-provider-doc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": process.env.REACT_APP_SERVER_URL,
          },
          withCredentials: true,
        }
      );

      await fetchProviders();

      setOpenDropdown(false);

      setAlert({
        message: "Data Uploaded Successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (file) => {
    try {
      uploadFile(file);
    } catch (error) {
      console.log("err");
    }
  };

  const [openDropdown, setOpenDropdown] = React.useState(false);

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <div
        className={classNames(
          "w-full px-16 transition-all",
          !openDropdown && "pb-8"
        )}
      >
        <button
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
          className="flex flex-row items-center justify-between w-full px-4 py-2 bg-gray-100 rounded-md"
        >
          <div className="flex flex-row items-center">
            Upload Provider Data
            <img
              src={Icons.Upload_Icon}
              alt="upload icon"
              className="mx-2 icon"
            />
          </div>
          {openDropdown ? <ArrowDownwardTwoTone /> : <ArrowUpwardTwoTone />}
        </button>
      </div>
      <Collapse in={openDropdown} className="w-full">
        <div className="flex flex-col w-full gap-1 px-16 py-8">
          <div className="flex flex-row items-end justify-end w-full mb-2 md:mb-0">
            <p
              className="m-0 text-sm underline text-primaryblue"
              onClick={() => {}}
              role="button"
            >
              Provider Data Template
            </p>
          </div>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            classes="w-100"
          >
            <div className="flex hover:bg-subtleblue transition-all cursor-pointer flex-col w-full justify-center items-center gap-2 border-[1px] border-dashed border-secondarygrayborder rounded-lg p-4">
              <img
                src={Icons.Upload_Icon}
                alt="upload icon"
                className="mx-2 icon"
              />
              <p className="m-0 mt-2 text-sm">
                Drag & Drop or{" "}
                <span className="text-primaryblue">Choose file</span> to upload
              </p>
            </div>
          </FileUploader>
        </div>
      </Collapse>
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Providers{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {providers.length}
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <div className="relative w-1/3 flex flex-row items-center gap-2 rounded-md border-[2px] border-primarygrayborder overflow-clip">
              <div className="pl-2">
                <img
                  src={Search_Icon}
                  alt="search"
                  className="w-5 h-5 cursor-pointer search-icon opacity-80"
                />
              </div>
              <input
                type="text"
                placeholder="Search..."
                className="w-full h-full p-2 text-base outline-none"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </div>
            <div className="w-1/3 relative flex flex-row divide-x-[2px] divide-primarygrayborder items-center gap-2 rounded-md border-[2px] border-primarygrayborder h-10">
              <div className="ml-2 min-w-fit">
                <button
                  onClick={() => setSpecialityDropdown(!specialityDropdown)}
                  className={classNames(
                    "rounded-full w-7 h-7",
                    specialityFilter.length > 0
                      ? "bg-primaryblue"
                      : "bg-primarygrayborder"
                  )}
                >
                  <FilterList
                    style={{
                      color: "#FFF",
                      fontSize: "20px",
                    }}
                  />
                </button>
              </div>
              <div className="flex flex-row items-center h-full gap-2 px-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
                {specialityFilter.length === 0 && (
                  <p className="m-0 text-base text-primarygrayborder whitespace-nowrap px-2 py-[2px]">
                    Filter by Speciality ...
                  </p>
                )}
                {specialityFilter.map((speciality, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primaryblue"
                  >
                    <p aria-multiline={false} className="m-0 whitespace-nowrap">
                      {speciality}
                    </p>
                    <button
                      onClick={() => handleSpecialityFilter(speciality)}
                      className="flex items-center justify-center scale-90 border border-white rounded-full aspect-square"
                    >
                      <CloseSvg color={"#FFF"} />
                    </button>
                  </div>
                ))}
              </div>
              {specialityDropdown && Array.isArray(providers) && (
                <div className="absolute z-50 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14 speciality-dropdown">
                  {specialities.map((speciality, index) => (
                    <div
                      className={classNames(
                        "hover:bg-primarylightgrey",
                        index !==
                          new Set(
                            providers.map((provider) => provider.speciality)
                          ).size -
                            1 && "border-b"
                      )}
                    >
                      <CheckboxSelector
                        key={index}
                        header={speciality}
                        isChecked={specialityFilter.indexOf(speciality) !== -1}
                        handleChange={(_e) =>
                          handleSpecialityFilter(speciality)
                        }
                        isBold={false}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="w-1/3 relative flex flex-row divide-x-[2px] divide-primarygrayborder items-center gap-2 rounded-md border-[2px] border-primarygrayborder h-10">
              <div className="ml-2 min-w-fit">
                <button
                  onClick={() => setStatusDropdown(!statusDropdown)}
                  className={classNames(
                    "rounded-full w-7 h-7",
                    statusFilter.length > 0
                      ? "bg-primaryblue"
                      : "bg-primarygrayborder"
                  )}
                >
                  <FilterList
                    style={{
                      color: "#FFF",
                      fontSize: "20px",
                    }}
                  />
                </button>
              </div>
              <div className="flex flex-row items-center h-full gap-2 px-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
                {statusFilter.length === 0 && (
                  <p className="m-0 text-base text-primarygrayborder px-2 py-[2px] whitespace-nowrap">
                    Filter by Status ...
                  </p>
                )}
                {statusFilter.map((status, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primaryblue"
                  >
                    <p aria-multiline={false} className="m-0 whitespace-nowrap">
                      {status}
                    </p>
                    <button
                      onClick={() => handleStatusFilter(status)}
                      className="flex items-center justify-center scale-90 border border-white rounded-full aspect-square"
                    >
                      <CloseSvg color={"#FFF"} />
                    </button>
                  </div>
                ))}
              </div>
              {statusDropdown && Array.isArray(providers) && (
                <div className="absolute z-50 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14 speciality-dropdown">
                  {statuses.map((status, index) => (
                    <div
                      className={classNames(
                        "hover:bg-primarylightgrey",
                        index !==
                          new Set(providers.map((provider) => provider.status))
                            .size -
                            1 && "border-b"
                      )}
                    >
                      <CheckboxSelector
                        key={index}
                        header={status}
                        isChecked={statusFilter.indexOf(status) !== -1}
                        handleChange={(_e) => handleStatusFilter(status)}
                        isBold={false}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <ArrowDownwardTwoTone />
                      ) : sortNameOrder === "desc" ? (
                        <ArrowUpwardTwoTone />
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Speciality</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortSpecialityOrder === "none") {
                          setSortSpecialityOrder("asc");
                        }
                        if (sortSpecialityOrder === "asc") {
                          setSortSpecialityOrder("desc");
                        }
                        if (sortSpecialityOrder === "desc") {
                          setSortSpecialityOrder("none");
                        }
                      }}
                    >
                      {sortSpecialityOrder === "asc" ? (
                        <ArrowDownwardTwoTone />
                      ) : sortSpecialityOrder === "desc" ? (
                        <ArrowUpwardTwoTone />
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">NPI ID</th>
                <th className="px-4 py-3">Phone No.</th>
                <th className="px-4 py-3">Email</th>
                <th className="px-4 py-3">Address</th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Status</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortStatusOrder === "none") {
                          setSortStatusOrder("asc");
                        }
                        if (sortStatusOrder === "asc") {
                          setSortStatusOrder("desc");
                        }
                        if (sortStatusOrder === "desc") {
                          setSortStatusOrder("none");
                        }
                      }}
                    >
                      {sortStatusOrder === "asc" ? (
                        <ArrowDownwardTwoTone />
                      ) : sortStatusOrder === "desc" ? (
                        <ArrowUpwardTwoTone />
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                {/* <th className="px-4 py-3">Edit</th> */}
              </tr>
            </thead>
            <tbody>
              {providers
                .filter((provider) =>
                  provider.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
                )
                .filter(
                  (provider) =>
                    specialityFilter.length === 0 ||
                    specialityFilter.indexOf(provider.speciality) !== -1
                )
                .filter(
                  (provider) =>
                    statusFilter.length === 0 ||
                    statusFilter.indexOf(provider.status) !== -1
                )
                .sort((a, b) => handleSortName(a, b))
                .sort((a, b) => handleSortSpeciality(a, b))
                .sort((a, b) => handleSortStatus(a, b))
                .map((provider, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== providers.length - 1
                        ? "border-b"
                        : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <div className="flex flex-row items-center gap-4">
                        <button
                          onClick={() => handleProviderClick(provider)}
                          className="flex flex-row items-center gap-4"
                        >
                          <Avatar
                            name={provider.name}
                            image={provider.profileImage}
                          />
                          <p className="m-0 font-semibold underline">
                            {provider.name}
                          </p>
                        </button>
                        {provider.npi_id === "NA" ||
                        provider.locationId === null ? (
                          <button
                            onClick={() => {
                              setInviteModal(true);
                              setSelectedProvider(provider);
                            }}
                            className="bg-yellow-50 gap-1 text-yellow-600 border-[1px] flex items-center justify-center border-primaryyellow rounded-md px-2"
                          >
                            <WarningRoundedIcon
                              style={{
                                fontSize: "16px",
                              }}
                            />
                            <p className="m-0 text-sm">Missing Data Points</p>
                          </button>
                        ) : (
                          (provider.email === "NA" ||
                            provider.phone === "NA") && (
                            <button
                              onClick={() => {
                                setInviteModal(true);
                                setSelectedProvider(provider);
                              }}
                              className="bg-subtleblue border-[1px] border-primaryblue rounded-md px-2"
                            >
                              <p className="m-0 text-sm text-primaryblue">
                                Invite to{" "}
                                <span className="font-semibold">CareSMS</span>
                              </p>
                            </button>
                          )
                        )}
                      </div>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {provider.speciality}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {provider.npi_id}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {provider.phone}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {provider.email}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {provider.address.join(", ")}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      <div
                        className={classNames(
                          provider.status.toUpperCase() === "ACTIVE"
                            ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                            : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                          "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                        )}
                      >
                        <div
                          className={classNames(
                            "w-2 h-2 rounded-full",
                            provider.status.toUpperCase() === "ACTIVE"
                              ? "bg-primarygreen"
                              : "bg-primarytextgrey"
                          )}
                        />
                        {provider.status}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedProvider && (
        <ProviderUpdateModal
          open={inviteModal}
          setOpen={setInviteModal}
          handleSubmit={
            selectedProvider.npi_id === "NA"
              ? updateMissingFields
              : inviteProvider
          }
          provider={selectedProvider}
          setProvider={setSelectedProvider}
          type={selectedProvider.npi_id === "NA" ? "update" : "invite"}
        />
      )}
    </div>
  );
};

export default ProviderList;
