import { create } from "zustand";
import { regions } from "../data";

export const useMapStore = create((set) => ({
  region: {
    lat: regions?.[0].coords.latitude,
    lng: regions?.[0].coords.longitude,
    zoom: 10,
  },
  setRegion: (region) => set({ region }),
  providers: [],
  setProviders: (providers) => set({ providers }),
  providerData: [],
  setProviderData: (providerData) => set({ providerData }),
  appointmentOpen: null,
  setAppointmentOpen: (appointmentOpen) => set({ appointmentOpen }),
  isOpen: null,
  setIsOpen: (isOpen) => set({ isOpen }),
  focusMarker: ({ region, id, seq }) =>
    set({
      region: { ...region, zoom: 10 },
      isOpen: id,
      appointmentOpen: seq,
    }),
  patientSelected: null,
  setPatientSelected: (selected) => set({ patientSelected: selected }),
}));
