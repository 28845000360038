import { apiSlice } from "../../app/api/apiSlice";

export const organisationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCaretag: builder.mutation({
      query: (credentials) => ({
        url: "/caretag",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    editCaretag: builder.mutation({
      query: (credentials) => ({
        url: "/caretag",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    getAllCaretags: builder.mutation({
      query: () => ({
        url: `/caretag`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useCreateCaretagMutation,
  useEditCaretagMutation,
  useGetAllCaretagsMutation,
} = organisationApiSlice;
