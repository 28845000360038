import io from "socket.io-client";
import { SOCKET_URL } from "../constants/endpoints";
import { useSelector } from "react-redux";
import { selectToken } from "../features/auth/authSlice";

const useSocket = () => {
  const token = useSelector(selectToken);
  // Replace 'http://localhost:3000' with your server's URL
  const socket = io(SOCKET_URL, {
    transports: ["websocket", "polling"],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
    forceNew: true,
    timeout: 20000,
    upgrade: true,
    pingInterval: 25000,
    pingTimeout: 60000,
    rememberUpgrade: true,
    secure: true,
    rejectUnauthorized: false,
    auth: {
      token: `Bearer ${token}` || null,
    },
  });
  return socket;
};

export default useSocket;

// import io from "socket.io-client";
// import { SOCKET_URL } from "../constants/endpoints";
// import { store } from "../app/store"; // Import the store if needed to access the token
// import { useMemo } from "react";

// const useSocketService = () => {
//   const token = store.getState().auth.token; // Access token from the Redux store
//   console.log(token, "token", store.getState());
//   // Replace 'http://localhost:3000' with your server's URL
//   const socket = useMemo(
//     () =>
//       io(SOCKET_URL, {
//         transports: ["websocket", "polling"],
//         reconnection: true,
//         reconnectionDelay: 1000,
//         reconnectionDelayMax: 5000,
//         reconnectionAttempts: 5,
//         forceNew: true,
//         timeout: 20000,
//         upgrade: true,
//         pingInterval: 25000,
//         pingTimeout: 60000,
//         rememberUpgrade: true,
//         secure: true,
//         rejectUnauthorized: false,
//         auth: {
//           token: `Bearer ${token}` || null,
//         },
//       }),
//     [token]
//   );
//   return socket;
// };

// export default useSocketService;
