import axios from "axios";
import classNames from "classnames";
import moment from "moment";
import React, { useState } from "react";
import { useSetGlobalStartEndDateMutation } from "../../../../features/provider/providerApiSlice";

const GlobalSchedulingSettings = React.forwardRef(
  ({ providers, setProviders }, ref) => {
    const [globalOpen, setGlobalOpen] = useState(true);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [setGlobalDates] = useSetGlobalStartEndDateMutation();

    const handleGlobalVisitPerDay = async () => {
      try {
        await setGlobalDates({
          providerIds: providers.map((provider) => provider.id),
          startDate: startDate,
          endDate: endDate,
        }).unwrap();

        const updatedProviders = providers.map((provider) => ({
          ...provider,
          startDate: startDate,
          endDate: endDate,
        }));

        setProviders(updatedProviders);
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <div ref={ref} className="flex flex-col items-start w-full px-4 md:flex-row md:items-center">
        <div className="flex flex-col gap-4 items start">
          <p
            className={classNames(
              "m-0 text-2xl font-bold",
              globalOpen ? "opacity-100" : "opacity-40"
            )}
          >
            Select Providers
          </p>
          <div className="flex flex-row items-end gap-12">
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <label
                  className={classNames(
                    "font-medium text-sm",
                    globalOpen ? "opacity-100" : "opacity-40"
                  )}
                >
                  Global start date
                </label>
                <button
                  onClick={() => {
                    setStartDate(moment().format("YYYY-MM-DD"));
                  }}
                >
                  <p className="m-0 text-sm font-medium text-primaryblue">
                    Set current date
                  </p>
                </button>
              </div>
              <input
                type="date"
                min={moment().format("YYYY-MM-DD")}
                className="w-64 p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:opacity-40"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                disabled={!globalOpen || providers.length === 0}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className={classNames(
                  "font-medium text-sm",
                  globalOpen ? "opacity-100" : "opacity-40"
                )}
              >
                Global end date
              </label>
              <input
                type="date"
                className="w-64 p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:opacity-40"
                min={startDate}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                disabled={!globalOpen || providers.length === 0}
              />
            </div>
            <button
              className="px-8 py-2 font-bold text-white rounded-lg bg-primaryblue disabled:opacity-40"
              onClick={handleGlobalVisitPerDay}
              disabled={
                !globalOpen || !startDate || !endDate || providers.length === 0
              }
            >
              Apply
            </button>
          </div>
        </div>
        {/* <div className="flex gap-4 mt-3 md:flex-col md:mt-0">
          <div className="flex flex-row items-center gap-3">
            <input
              type="checkbox"
              className="w-4 h-4 checked:lightblue"
              checked={globalOpen}
              onChange={(e) => setGlobalOpen(e.target.checked)}
            />
            <label className={classNames("font-medium ")}>Apply to all</label>
          </div>
          <button
            className="px-4 py-2 font-bold text-white rounded-lg bg-primaryblue disabled:opacity-40"
            onClick={handleGlobalVisitPerDay}
            disabled={
              !globalOpen || !startDate || !endDate || providers.length === 0
            }
          >
            Save
          </button>
        </div> */}
      </div>
    );
  }
);

export default GlobalSchedulingSettings;
