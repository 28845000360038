import React from "react";
import { PlayArrow } from "@mui/icons-material";
import Logo from "../../../assets/images/CareSMS_Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateUser } from "../../../features/auth/authSlice";
import { useSaveMetadataMutation } from "../../../features/organisation/organisationApiSlice";
import { useNavigate } from "react-router-dom";

const noOfProvidersList = ["1-10", "11-50", "51-100", "101-500"];

const noOfPatientsList = [
  "1-100",
  "101-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
];

const noOfCommunitiesList = ["None", "1-10", "11-50", "51-100", "101-500"];

const OrganisationInfo = () => {
  const [errMsg, setErrMsg] = React.useState(null);
  const [noOfProviders, setNoOfProviders] = React.useState(null);
  const [noOfPatients, setNoOfPatients] = React.useState(null);
  const [noOfCommunities, setNoOfCommunities] = React.useState(null);
  const [scheduleRules, setScheduleRules] = React.useState(false);

  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [saveMetaData, { isLoading }] = useSaveMetadataMutation();

  const handleSaveMetaData = async () => {
    try {
      const { data } = await saveMetaData({
        noOfProviders,
        noOfPatients,
        noOfCommunities,
      }).unwrap();

      dispatch(
        updateUser({
          user: { ...data.user, step: 4 },
        })
      );
      navigate("/welcome");
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };

  return (
    <div className="relative flex flex-col w-full gap-8 mt-8 sm:w-11/12 base:py-18">
      <div className="flex flex-col gap-8">
        <img src={Logo} alt="logo" className="-ml-2 logo" />
        <h1 className="m-0 text-2xl font-bold">
          Tell us about Scheduling Operations
        </h1>
      </div>
      <div className="flex flex-col w-full gap-6">
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Number of In-Home Providers
            <span className="text-red-500">*</span>
          </label>
          <select
            name="noOfProviders"
            id="noOfProviders"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            value={noOfProviders}
            onChange={(e) => setNoOfProviders(e.target.value)}
          >
            {noOfProvidersList.map((no) => (
              <option value={no} key={no}>
                {no}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Number of patients
          </label>
          <select
            name="noOfPatients"
            id="noOfPatients"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            value={noOfPatients}
            onChange={(e) => setNoOfPatients(e.target.value)}
          >
            {noOfPatientsList.map((no) => (
              <option value={no} key={no}>
                {no}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Number of Communities
          </label>
          <select
            name="noOfCommunities"
            id="noOfCommunities"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            value={noOfCommunities}
            onChange={(e) => setNoOfCommunities(e.target.value)}
          >
            {noOfCommunitiesList.map((no) => (
              <option value={no} key={no}>
                {no}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Do you have scheduling rules?
          </label>
          <select
            name="scheduling_rules"
            id="scheduling_rules"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            value={scheduleRules}
            onChange={(e) => setScheduleRules(e.target.value)}
          >
            <option value={false} className="text-primarygrayborder">
              No
            </option>
            <option value={true} className="text-primarygrayborder">
              Yes
            </option>
          </select>
        </div>
        {errMsg && (
          <p className="m-0 text-xs text-center text-danger">{errMsg}</p>
        )}
        <button
          disabled={isLoading}
          onClick={handleSaveMetaData}
          className="flex items-center justify-center w-full gap-2 p-2 mt-8 font-semibold text-white rounded-lg bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
        >
          Next
          <div className="relative flex flex-row">
            <PlayArrow
              style={{
                color: "#fff",
                width: "18px",
                height: "18px",
                objectFit: "contain",
              }}
            />
            <PlayArrow
              style={{
                color: "#fff",
                width: "18px",
                height: "18px",
                objectFit: "contain",
                position: "absolute",
                left: -5,
              }}
            />
          </div>
        </button>
        {/* <button
          disabled={minStep === step}
          onClick={() => handleStepChange(step - 1)}
          className="flex items-center justify-center w-full gap-2 p-2 mt-8 font-semibold text-black bg-white rounded-lg shadow-sm"
        >
          Go back to previous step
        </button> */}
      </div>
      <p className="w-full mt-12 text-xs text-center">
        2024 CareSMS Inc. All rights reserved.
      </p>
    </div>
  );
};

export default OrganisationInfo;
