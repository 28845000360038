import React, { useEffect, useState } from "react";
import UploadCareplanLeft from "./UploadCareplanLeft";
import UploadCareplanRight from "./UploadCareplanRight";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, updateUser } from "../../../features/auth/authSlice";
import { useSchedulerStore } from "../../../context/scheduler.store";
import { useCheckEHRUploadStatusMutation } from "../../../features/organisation/organisationApiSlice";
import Footer from "../components/Footer";
import { extractUniqueObjects } from "../../../utilities/resuseFunctions";
import { useGetUserMutation } from "../../../features/user/userApiSlice";

const UploadCareplan = ({
  handleStepChange,
  step,
  setUploadSteps,
  setIsFileUpload,
}) => {
  const [errorTitle, setErrorTitle] = useState(null);
  const [errors, setErrors] = useState([]);
  const token = useSelector(selectToken);
  const [opaque, setOpaque] = useState(false);

  const fileName = useSchedulerStore((state) => state.fileName);
  const setFileName = useSchedulerStore((state) => state.setFileName);

  const [fileSize, setFileSize] = useState(null);

  const [canUpload, setCanUpload] = useState(false);

  const [steps, setSteps] = useState([]);

  const [checkStatus, setCheckStatus] = useState(true);

  function storeSizeInMBorKB(size) {
    if (size > 800 * 1000) {
      setErrorTitle("File Size should be less than 800KB");
      return;
    }

    if (size < 1000) {
      setFileSize(`${size}B`);
    } else if (size < 1000 * 1000) {
      setFileSize(`${(size / 1000).toFixed(2)}KB`);
    }
  }

  const [checkEHRStatus] = useCheckEHRUploadStatusMutation();

  async function uploadFile(file) {
    setErrorTitle(null);
    setFileSize(null);
    setFileName(null);
    setSteps([]);
    setErrors([]);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/verify-ehr-doc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": process.env.REACT_APP_SERVER_URL,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setFileName(file.name);
        setCheckStatus(true);
        // storeSizeInMBorKB(file.size);
        setIsFileUpload(true);
      }
    } catch (error) {
      const { message, detail } = error.response.data;
      setErrorTitle(message);

      if (detail) {
        const errorDetails = extractUniqueObjects(detail);
        setErrors(errorDetails);
      }
    }
  }

  const handleChange = (file) => {
    try {
      uploadFile(file);
    } catch (error) {
      console.log("err");
    }
  };

  const [getUser] = useGetUserMutation();

  const dispatch = useDispatch();

  const fetchUser = async () => {
    try {
      const { data } = await getUser().unwrap();

      dispatch(
        updateUser({
          user: data,
        })
      );
    } catch (error) {
      console.error("Failed to fetch user: ", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (checkStatus) {
      const interval = setInterval(async () => {
        try {
          const { data } = await checkEHRStatus().unwrap();

          if (data === null || data.canUploadAgain) {
            setCanUpload(true);
            clearInterval(interval);
            setCheckStatus(false);
          }

          if (data !== null) {
            setSteps(data.status.steps);
            setUploadSteps(data.status.steps);

            if (data.status.canUploadAgain) {
              setCanUpload(true);
              clearInterval(interval);
              setCheckStatus(false);
            }
          }
        } catch (error) {
          console.log(error);
          clearInterval(interval);
          setCheckStatus(false);
        }
      }, 4000);

      return () => clearInterval(interval); // Clean up the interval on component unmount
    }
  }, [checkStatus]);

  useEffect(() => {}, [canUpload]);

  return (
    <div className="relative flex flex-col items-stretch lg:flex-row h-fit">
      <UploadCareplanLeft
        handleChange={handleChange}
        canUpload={canUpload}
        handleStepChange={handleStepChange}
        step={step}
        fileName={fileName}
        setOpaque={setOpaque}
      />
      <UploadCareplanRight
        setUploadSteps={setUploadSteps}
        steps={steps}
        errors={errors}
      />
      <div className="flex lg:hidden">
        <Footer />
      </div>
    </div>
  );
};

export default UploadCareplan;
