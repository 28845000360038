import React, { useState } from "react";
import { Typography } from "@mui/material";
import "./Tag.css";
import clsx from "clsx";
import CloseSvg from "../../assets/svgs/CloseSvg";

const Tag = ({
  text,
  success,
  varient,
  onClick = () => {},
  className,
  isBold,
  isRem, // isRem -> isRemovable
  onClose,
  icon,
  Icon,
  viewBox,
  patientDOB,
}) => {
  const [isRemovable, setIsRemovabled] = useState(false);
  const splitReplace = (text) => {
    const symbols = ["<", ">", ")"];
    let t = text || "";
    for (let index = 0; index < symbols.length; index++) {
      const symbol = symbols[index];
      t = t?.replace(symbol, "") || "";
    }
    t = t?.split("(");
    return t;
  };
  const [t1, t2] = splitReplace(text);

  const onTagClick = () => {
    setIsRemovabled(!isRemovable);
  };
  const variantText = varient + "Text";

  const color =
    variantText === "metatagText"
      ? "#1d7c4d"
      : variantText === "patientText"
      ? "#ffa500"
      : variantText === "urgentText"
      ? "#fb131f"
      : "#fff";
  return (
    <button
      // disabled={success}
      onClick={isRem ? onTagClick : onClick}
      className={clsx(
        "btn tagView p-0 px-2 d-flex align-items-center",
        varient && !success && varient,
        success && "tagSuccess",
        className
      )}
    >
      {(icon || Icon) && (
        <div className="tagIcon">
          {icon && <img alt="icon" src={icon} />}
          {Icon && <Icon color={color} viewBox={viewBox} />}
        </div>
      )}
      <div>
        <div className="d-flex">
          <Typography
            className={clsx(
              "tagText",
              varient && varient + "Text",
              success && "tagSuccessText",
              isBold && "tagBold"
            )}
          >
            {t1}
          </Typography>
          {isRemovable && <CloseSvg color={color} onClick={onClose} />}
        </div>
        {patientDOB && (
          <Typography
            className={variantText}
            style={{ fontSize: 12, textAlign: "center" }}
          >
            {patientDOB}
          </Typography>
        )}
      </div>
    </button>
  );
};

export default Tag;
