import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import {
  useCancelAppointmentMutation,
  useFetchAppointmentsMutation,
  useFetchConsolidatedAppointmentsMutation,
  useFetchMapProvidersMutation,
  useFilterAppointmentOptionsMutation,
  useGetAppointmentCountMutation,
  useLockAppointmentMutation,
  useRescheduleAppointmentMutation,
} from "../../features/appointment/appointmentApiSlice";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectToken, selectUser } from "../../features/auth/authSlice";
import Unlock from "../../assets/svgs/Unlock";
import Lock from "../../assets/svgs/Lock";
import CheckSvg from "../../assets/svgs/CheckSvg";
import LeftArrowSvg from "../../assets/svgs/LeftArrowSvg";
import Tabs from "../../components/Tabs";
import Map from "../../components/Maps/Map";
import { useMapStore } from "../../context/map.store";
import { avatarName } from "../../utilities/resuseFunctions";
import axios from "axios";
import { useLSStore } from "../../context/ls-store";
import AppointmentSelectModal from "../LongitudinalSchedules/components/AppointmentSelectModal";
import AppointmentCancelModal from "../LongitudinalSchedules/components/AppointmentCancelModal";
import Dropdown from "../../assets/svgs/Dropdown";
import Download from "../../assets/svgs/Download";
import UserSvg from "../../assets/svgs/user";
import {
  Edit_Icon,
  Search_Icon,
  Sort_Icon,
  User_Icon,
  Users_Icon,
} from "../../assets/images";
import UsersSvg from "../../assets/svgs/UsersSvg";
import CloseSvg from "../../assets/svgs/CloseSvg";
import AppointmentLockModal from "../LongitudinalSchedules/components/AppointmentLockModal";
import { DropArea } from "../LongitudinalSchedules/components/DropArea";
import { flushSync } from "react-dom";
import { Delete } from "@mui/icons-material";
import AppointmentAddModal from "../LongitudinalSchedules/components/AppointmentAddModal";
import Chat from "../Chat/Chat";
import SelectableButton from "../../components/Buttons/SelectableButton";

const CommandCenterV2 = () => {
  const [getAppointmentCount] = useGetAppointmentCountMutation();
  const [startDate, setStartDate] = React.useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const [selectedDate, setSelectedDate] = React.useState(moment().toDate());
  const [count, setCount] = React.useState([]);
  const [selectedProvider, setSelectedProvider] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState("Scheduler");

  const user = useSelector(selectUser);
  const token = useSelector(selectToken);

  const { providers, setProviders, providerData, setProviderData } =
    useMapStore((state) => state);
  const [fetchMapProviders] = useFetchMapProvidersMutation();

  const fetchAppointmentCount = async () => {
    try {
      const { data } = await getAppointmentCount({
        providerId: user.type === 3 ? user.details.provider_id : 0,
        organisationId: user.organisation.id,
        startDate,
        endDate,
      }).unwrap();

      setCount(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  const [appointments, setAppointments] = React.useState([]);

  const [fetchAppointments] = useFetchAppointmentsMutation();

  const fetchAppointmentsFunction = async () => {
    try {
      const { data } = await fetchAppointments({
        providerId:
          user.type === 3 ? user.details.provider_id : selectedProvider.id,
        appointmentType: "all",
        locationType: "all",
        locationId: null,
        patientId: null,
        startDate: moment(selectedDate).format("YYYY-MM-DD"),
        endDate: moment(selectedDate).format("YYYY-MM-DD"),
      });

      setAppointments(data.data);

      const { data: mapData } = await fetchMapProviders({
        mapFor: "PATIENT",
        date: moment(selectedDate).format("YYYY-MM-DD"),
        providerId:
          user.type === 3 ? user.details.provider_id : selectedProvider.id,
      });
      setProviderData(mapData?.data || []);
    } catch (err) {
      console.error(err);
    }
  };

  const [fetchConsolidatedAppointmentsAPI] =
    useFetchConsolidatedAppointmentsMutation();

  const [consolidatedAppointments, setConsolidatedAppointments] =
    React.useState([]);

  const fetchAppointmentsConsolidated = async () => {
    try {
      const { data } = await fetchConsolidatedAppointmentsAPI({
        organisationId: user.organisation.id,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      });

      setConsolidatedAppointments(data.data);
      const { data: mapData } = await fetchMapProviders({
        mapFor: "PROVIDER",
        date: moment(selectedDate).format("YYYY-MM-DD"),
        providerId: "0",
      });
      setProviders(mapData?.data || []);
    } catch (err) {
      console.error(err);
    }
  };

  const downloadCSV = async () => {
    const providerId =
      user.type === 3 ? user.details.provider_id : selectedProvider.id;
    const name = user.type === 3 ? user.details.name : selectedProvider.name;
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/appointment/csv?providerId=${providerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name} (${providerId}).csv`);
    document.body.appendChild(link);
    link.click();
  };

  const onMonthChange = (val) => {
    setStartDate(moment(val.activeStartDate).format("YYYY-MM-DD"));
    setEndDate(moment(val.activeStartDate).endOf("month").format("YYYY-MM-DD"));
  };

  const onDateChange = (val) => {
    setSelectedDate(val);
  };

  useEffect(() => {
    setSelectedProvider(null);
    if (user.type === 1) fetchAppointmentsConsolidated();
    if (user.type === 3) fetchAppointmentsFunction();
  }, [selectedDate]);

  useEffect(() => {
    if (selectedProvider) fetchAppointmentsFunction();
  }, [selectedProvider]);

  useEffect(() => {
    fetchAppointmentCount();
  }, [startDate]);

  const [recallSchedule, setRecallSchedule] = useState(false);

  const selectedAppointment = useLSStore((state) => state.selectedAppointment);
  const setSelectedAppointment = useLSStore(
    (state) => state.setSelectedAppointment
  );

  const [cancelAppointment] = useCancelAppointmentMutation();
  const [lockAppointment] = useLockAppointmentMutation();

  const handleCancel = async () => {
    try {
      await cancelAppointment({
        appointmentId: appointmentToCancel.appointmentId,
      }).unwrap();

      console.log(`Appointment ${appointmentToCancel.appointmentId} cancelled`);

      setRecallSchedule(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLock = async () => {
    try {
      await lockAppointment({
        appointmentId: appointmentToLock.appointmentId,
      }).unwrap();

      console.log(`Appointment ${appointmentToLock.appointmentId} locked`);

      setRecallSchedule(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (recallSchedule) {
      // setAppointments([]);
      fetchAppointmentsFunction();
      setRecallSchedule(false);
    }
  }, [recallSchedule]);

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openLockModal, setOpenLockModal] = useState(false);
  const [appointmentToCancel, setAppointmentToCancel] = useState(null);
  const [appointmentToLock, setAppointmentToLock] = useState(null);

  const handleCancelModal = () => {
    if (openCancelModal) setAppointmentToCancel(null);
    setOpenCancelModal(!openCancelModal);
  };

  const handleLockModal = () => {
    if (openLockModal) setAppointmentToLock(null);
    setOpenLockModal(!openLockModal);
  };

  const [draggingAppointment, setDraggingAppointment] = useState(null);

  const [rescheduleAppointments] = useRescheduleAppointmentMutation();

  const onDrop = async (index) => {
    if (draggingAppointment === null) return;

    const { data } = await rescheduleAppointments({
      dayToBeMoved: draggingAppointment.visitDate,
      indexToBeMoved: index,
      appointmentId: draggingAppointment.appointmentId,
    }).unwrap();

    const { appointmentsTo } = data;

    const newAppointments = {
      ...appointments,
      [draggingAppointment.visitDate]: appointmentsTo,
    };

    if (document.startViewTransition) {
      document.startViewTransition(() => {
        flushSync(() => {
          setAppointments(newAppointments);
        });
      });
    } else {
      setAppointments(newAppointments);
    }
  };

  const [editable, setEditable] = useState(false);

  const [addOpen, setAddOpen] = useState(false);

  const [filterOptions, setFilterOptions] = useState(null);

  const [showProviderList, setShowProviderList] = useState(false);

  const [filterAppointmentOptions] = useFilterAppointmentOptionsMutation();

  const handleProviderList = () => {
    setShowProviderList(!showProviderList);
  };

  const fetchFilterOptions = async () => {
    try {
      if (selectedProvider !== null) {
        const { data } = await filterAppointmentOptions({
          providerId: selectedProvider.id,
        });

        setFilterOptions(data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (selectedProvider) fetchFilterOptions();
  }, [selectedProvider]);

  const isProvider = user.type === 3 || !!selectedProvider?.id;

  const Mileage = isProvider
    ? appointments[moment(selectedDate).format("YYYY-MM-DD")]?.reduce(
        (total, current, idx) => {
          if (
            idx === 0 ||
            idx ===
              appointments[moment(selectedDate).format("YYYY-MM-DD")].length
          ) {
            return total + Number(current.distanceFromHome);
          }
          return total + Number(current.distance);
        },
        0
      )
    : 0;

  return (
    <div className="relative flex flex-col md:flex-row h-screen overflow-scroll divide-x-[1px]">
      <div
        className={classNames(
          "flex flex-col w-full gap-8 p-6 overflow-auto md:w-1/2",
          selectedAppointment || addOpen ? "opacity-40" : "opacity-100"
        )}
      >
        <h1 className="m-0 font-semibold">Command Center</h1>
        <Tabs
          tabs={["Map", "Calendar"]}
          components={[
            <Map
              isProvider={user.type === 3 || !!selectedProvider?.id}
              data={
                user.type === 3 || !!selectedProvider?.id
                  ? providerData
                  : providers
              }
              selectedProvider={providers.find((p) =>
                p.providers.find((ps) => ps.id === selectedProvider?.id)
              )}
            />,
            <Calendar
              className={"h-[500px]"}
              onClickMonth={onMonthChange}
              onActiveStartDateChange={onMonthChange}
              value={selectedDate}
              onChange={onDateChange}
              tileContent={({ date, view }) => {
                return (
                  <>
                    {count.find(
                      (x) => x.date === moment(date).format("YYYY-MM-DD")
                    ) === undefined ? (
                      <></>
                    ) : (
                      <div
                        className={classNames(
                          "flex items-center justify-center w-5 h-5 mx-auto mt-2 text-[10px] rounded-full",
                          moment(selectedDate).format("YYYY-MM-DD") !==
                            moment(date).format("YYYY-MM-DD")
                            ? "bg-primaryblue text-white"
                            : "bg-white text-primaryblue"
                        )}
                      >
                        {
                          count.find(
                            (x) => x.date === moment(date).format("YYYY-MM-DD")
                          )?.noOfAppointments
                        }
                      </div>
                    )}
                  </>
                );
              }}
            />,
          ]}
        />
      </div>
      <div
        className={classNames(
          "flex flex-col w-full min-h-svh gap-8 p-6 overflow-auto md:w-1/2",
          selectedAppointment || addOpen ? "opacity-40" : "opacity-100"
        )}
      >
        <div className="flex flex-col gap-3 md:flex-row">
          <SelectableButton
            onClick={() => setSelectedTab("Scheduler")}
            title={"Scheduler"}
            isSelected={selectedTab === "Scheduler"}
            className={"py-2 "}
          />
          <SelectableButton
            onClick={() => setSelectedTab("Messenger")}
            title={"Messenger"}
            isSelected={selectedTab === "Messenger"}
            className={"py-2 "}
          />
        </div>
        {selectedTab === "Scheduler" && (
          <>
            <div className="flex flex-col gap-3 md:flex-row">
              <button
                disabled={selectedProvider !== null}
                onClick={handleProviderList}
                className="flex relative items-center justify-center w-full h-10 gap-2 py-2 text-white rounded-md border-[1px] border-primaryblue bg-primaryblue"
              >
                {user.type === 3 || selectedProvider ? (
                  <UserSvg color={"#FFF"} />
                ) : (
                  <UsersSvg color={"#FFF"} />
                )}
                {user.type === 3 ? (
                  <p className="m-0 text-xl">{user.details.name}</p>
                ) : selectedProvider ? (
                  <p className="m-0 text-xl">{selectedProvider.name}</p>
                ) : (
                  <p className="m-0 text-xl">All Providers</p>
                )}
                {selectedProvider === null && (
                  <div className={classNames("")}>
                    <Dropdown color={"#FFF"} />
                  </div>
                )}
                <div
                  className={classNames(
                    "absolute w-full max-h-40 bg-white border-[1px] rounded-md overflow-y-auto flex-col text-black z-[999] top-[120%]",
                    !showProviderList ? "hidden" : "flex"
                  )}
                >
                  {consolidatedAppointments.map((appointments, index) => (
                    <button
                      onClick={() => {
                        setSelectedProvider(appointments.provider);
                        handleProviderList();
                      }}
                      className={classNames(
                        "flex flex-row items-center hover:bg-primarylightgrey gap-2 p-2",
                        index === consolidatedAppointments.length - 1
                          ? "border-none"
                          : "border-b-[1px]"
                      )}
                    >
                      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-primarygrayborder">
                        <p className="m-0 font-semibold text-white">
                          {avatarName(appointments.provider.name)}
                        </p>
                      </div>
                      <p className="m-0">{appointments.provider.name}</p>
                    </button>
                  ))}
                </div>
              </button>
              <button
                className="flex items-center justify-center w-10 h-10 bg-white border-[1px] rounded-md border-primaryblue disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 ease-in-out"
                onClick={downloadCSV}
                disabled={
                  (user.type !== 3 && !selectedProvider) || selectedAppointment
                }
              >
                <Download color={"#3062D4"} />
              </button>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row gap-4">
                    <button
                      disabled={selectedProvider === null || user.type !== 1}
                      onClick={() => {
                        setSelectedProvider(null);
                      }}
                      className={classNames(
                        "w-10 h-10 rounded-md flex items-center justify-center bg-primarylightgrey disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 ease-in-out"
                      )}
                    >
                      <div className="rotate-90">
                        <Dropdown color={"#000"} />
                      </div>
                    </button>
                    <p className="m-0 text-3xl">
                      {moment(selectedDate).format("YYYY-MM-DD") ===
                      moment().format("YYYY-MM-DD")
                        ? "Today"
                        : moment(selectedDate).format("MMMM DD, YYYY")}
                    </p>
                  </div>
                  <div className="flex flex-row gap-4">
                    <button
                      disabled={selectedProvider === null}
                      onClick={() => {
                        setAddOpen(true);
                      }}
                      className={classNames(
                        "w-10 h-10 rounded-md flex items-center justify-center bg-primarylightgrey disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 ease-in-out"
                      )}
                    >
                      <div className="rotate-45">
                        <CloseSvg color={"#000"} />
                      </div>
                    </button>
                    <button
                      disabled={selectedProvider === null}
                      onClick={() => {
                        setEditable(!editable);
                      }}
                      className="flex items-center justify-center w-10 h-10 transition-all duration-200 ease-in-out rounded-md bg-primarylightgrey disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {!editable ? (
                        <img
                          src={Edit_Icon}
                          alt="search"
                          className="object-contain w-5 h-5"
                        />
                      ) : (
                        <CheckSvg color={"#0d6efd"} />
                      )}
                    </button>
                  </div>
                </div>

                <div className="flex flex-row gap-4 justify-stretch">
                  <div className="relative flex flex-row w-full items-center gap-2 divide-x-2 rounded-md border-[2px] border-primarylightgrey overflow-clip">
                    <div className="pl-2">
                      <img
                        src={Search_Icon}
                        alt="search"
                        className="w-5 h-5 cursor-pointer search-icon"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Search by Provider Name ..."
                      className="w-full h-full p-2 text-base outline-none"
                    />
                  </div>
                  <button
                    disabled={selectedProvider !== null}
                    className="flex items-center self-end justify-center h-10 transition-all duration-200 ease-in-out rounded-md min-w-10 bg-primarylightgrey disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <img
                      src={Sort_Icon}
                      alt="search"
                      className="object-contain w-5 h-5"
                    />
                  </button>
                </div>
              </div>
              {isProvider && (
                <div className="flex justify-end">
                  <span className="bold-700 text-xl color-o">
                    {" "}
                    Total mileage: {Mileage?.toFixed(2)} Miles
                  </span>
                </div>
              )}
              {!selectedProvider && user.type === 1 ? (
                <div className="flex flex-col items-center justify-between gap-8 p-2">
                  {consolidatedAppointments.map((appointments) => (
                    <div className="flex flex-col w-full gap-4 p-2 rounded-lg shadow-md border-[1px] border-gray-100">
                      <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-row items-center gap-2">
                          <div className="flex items-center justify-center w-8 h-8 rounded-full bg-primarygrayborder">
                            <p className="m-0 font-semibold text-white">
                              {avatarName(appointments.provider.name)}
                            </p>
                          </div>
                          <p className="m-0">{appointments.provider.name}</p>
                        </div>
                        <div className="flex flex-col items-start">
                          <p className="m-0 text-sm font-semibold">
                            Distance Travelled
                          </p>
                          <p className="m-0 text-sm">
                            {appointments.totalDistance} miles
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-row items-center gap-2">
                          <p className="m-0 text-xs">
                            {appointments.noOfAppointments} visits
                          </p>
                          <div className="h-6 bg-primarygrayborder w-[1px]" />
                          <p className="m-0 text-sm text-primaryblue">
                            {appointments.noOfCompletedAppointments} visits
                            completed
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            setSelectedProvider(appointments.provider);
                          }}
                          className="text-xs m-0 py-1 px-2 border-[1px] border-primarygrayborder font-light rounded-lg"
                        >
                          View schedules
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col gap-0">
                  <DropArea onDrop={() => onDrop(0)} />
                  {appointments[moment(selectedDate).format("YYYY-MM-DD")]?.map(
                    (appointment, index) => (
                      <>
                        <div
                          draggable={editable}
                          onDragStart={() =>
                            setDraggingAppointment(appointment)
                          }
                          onDragEnd={() => setDraggingAppointment(null)}
                          className={classNames(
                            "flex flex-col w-full gap-4 p-4 bg-white rounded-md shadow-md border-primarygrayborder",
                            editable && "cursor-pointer"
                          )}
                        >
                          <div className="flex flex-row justify-between">
                            <div className="flex flex-row items-center gap-4">
                              <div
                                className={classNames(
                                  "flex items-center justify-center rounded-full text-white text-sm font-semibold w-10 h-10",
                                  appointment.status === "UNLOCKED"
                                    ? "bg-[#F2C94C]"
                                    : appointment.status === "LOCKED"
                                    ? "bg-[#3062D4]"
                                    : "bg-[#6FCF97]"
                                )}
                              >
                                {index + 1}
                              </div>
                              <div className="flex flex-col items-start gap-0">
                                <span className="flex flex-row items-center gap-2 whitespace-nowrap">
                                  <span className="m-0 text-lg">
                                    {appointment.patient.name}
                                  </span>
                                  <span>|</span>
                                  <span className="m-0 text-lg">
                                    {appointment.location.name ??
                                      "Private Residence"}
                                  </span>
                                </span>
                                <span className="m-0 text-sm font-extralight">
                                  {index === 0
                                    ? `${appointment.distanceFromHome} from home`
                                    : `${appointment.distance} from previous appointment`}
                                </span>
                              </div>
                            </div>
                            <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-gray-50">
                              {appointment.status === "UNLOCKED" ? (
                                <Unlock color={"#F2C94C"} />
                              ) : appointment.status === "LOCKED" ? (
                                <Lock color={"#3062D4"} />
                              ) : (
                                <CheckSvg color={"#6FCF97"} />
                              )}
                            </div>
                          </div>
                          <div className="flex w-full justify-evenly">
                            <button
                              disabled={selectedAppointment || addOpen}
                              className="text-sm m-0 py-1 px-2 border-[1px] border-primarylightgrey bg-primarylightgrey font-medium rounded-lg"
                            >
                              {appointment.appointmentType.name}
                            </button>
                            <button
                              disabled={selectedAppointment || addOpen}
                              onClick={() => {
                                setSelectedAppointment(appointment);
                              }}
                              className="text-sm m-0 py-1 px-2 border-[1px] font-medium border-primarygrayborder rounded-lg"
                            >
                              Appointment details
                            </button>
                            <button
                              disabled={selectedAppointment || addOpen}
                              className={classNames(
                                "text-sm m-0 py-1 px-2 bg-white font-medium rounded-lg flex items-center gap-1",
                                appointment.status === "PUBLISHED"
                                  ? "text-primarygreen border-[1px] border-primarygreen"
                                  : appointment.status === "UNLOCKED"
                                  ? "text-primaryyellow border-[1px] border-primaryyellow"
                                  : "text-primaryblue border-[1px] border-primaryblue"
                              )}
                              onClick={() => {
                                setAppointmentToLock(appointment);
                                handleLockModal();
                              }}
                            >
                              {appointment.status === "UNLOCKED" ? (
                                <Unlock color={"#F2C94C"} />
                              ) : appointment.status === "LOCKED" ? (
                                <Lock color={"#3062D4"} />
                              ) : (
                                <CheckSvg color={"#27AE60"} />
                              )}
                              {appointment.status === "PUBLISHED"
                                ? "Published"
                                : appointment.status === "UNLOCKED"
                                ? "Unlocked"
                                : "Locked"}
                            </button>
                            <button
                              disabled={selectedAppointment || addOpen}
                              onClick={() => {
                                setAppointmentToCancel(appointment);
                                handleCancelModal();
                              }}
                              className="text-sm flex font-medium flex-row items-center gap-1 m-0 py-1 px-2 border-[1px] border-primaryred text-primaryred rounded-lg bg-subtlered"
                            >
                              <Delete
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                              Cancel
                            </button>
                          </div>
                        </div>
                        <DropArea onDrop={() => onDrop(index + 1)} />
                      </>
                    )
                  )}
                </div>
              )}
            </div>
          </>
        )}
        {selectedTab === "Messenger" && (
          <div>
            <Chat isFullScreen={false} />
          </div>
        )}
      </div>
      <div className="z-50 overflow-auto opacity-100 min-h-svh">
        <AppointmentSelectModal
          selectedProvider={selectedProvider}
          setRecallSchedule={setRecallSchedule}
        />
      </div>
      {filterOptions && selectedProvider && (
        <div className="z-50 opacity-100">
          <AppointmentAddModal
            selectedProvider={selectedProvider}
            addOpen={addOpen}
            setAddOpen={setAddOpen}
            weeks={null}
            onAdd={() => {}}
            filterOptions={filterOptions}
            setRecallSchedule={setRecallSchedule}
          />
        </div>
      )}
      <AppointmentCancelModal
        open={openCancelModal}
        handleCancelModal={handleCancelModal}
        handleCancel={handleCancel}
      />
      <AppointmentLockModal
        open={openLockModal}
        handleLockModal={handleLockModal}
        handleLock={handleLock}
        status={appointmentToLock?.status}
      />
    </div>
  );
};

export default CommandCenterV2;
