import { Avatar } from "@mui/material";
import classNames from "classnames";
import React from "react";
import axios from "axios";
import { Icons } from "../../../../assets";
import { useSelector } from "react-redux";
import { selectToken } from "../../../../features/auth/authSlice";
import { useFetchCSVMutation } from "../../../../features/appointment/appointmentApiSlice";

const ScheduleCard = ({ provider }) => {
  const token = useSelector(selectToken);

  const [fetchCSVAPI] = useFetchCSVMutation();

  const downloadCSV = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/appointment/csv?providerId=${provider.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", provider.fileName);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div
      className={classNames(
        "flex items-center justify-between flex-row gap-8 p-4 border rounded-lg shadow-md border-primarygrayborder"
      )}
    >
      <div className="flex flex-row items-start justify-center gap-3">
        <Avatar
          style={{
            width: "48px",
            height: "48px",
            fontSize: "16px",
            backgroundColor: "#D7E4FF",
            color: "#3062D4",
          }}
        >
          {provider.name.split(" ")[0][0] + provider.name.split(" ")[1][0]}
        </Avatar>
        <div className="flex flex-col items-start justify-start w-full">
          <p className="m-0 text-base font-bold">{provider.name}</p>
          <p className="m-0 text-sm text-primarytextgrey">
            {provider.speciality}
          </p>
        </div>
      </div>
      <button
        onClick={downloadCSV}
        className="flex flex-row items-center justify-center p-2 border rounded-lg border-primarygrayborder"
      >
        <img src={Icons.Upload_Icon} alt="upload icon" className="mx-2 icon" />
        <p className="m-0 text-sm font-light">Export as CSV</p>
      </button>
    </div>
  );
};

export default ScheduleCard;
