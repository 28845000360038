import React from "react";
import "../../Admin/FileUpload/FileUpload.css";
import Nurse from "../../../assets/images/nurse.png";
import classNames from "classnames";

const UploadCareplanRight = ({ steps, errors }) => {
  return (
    <div className="lg:w-1/3 w-full py-24 h-screen  base:py-36 bg-primarylightgrey " >
      <div className=" flex justify-center">
        <img src={Nurse} className="w-60 h-60 rounded-full" />
      </div>
      <div className="flex justify-center mx-2 h-2/3">
        <div className="flex flex-col items-start gap-4 py-4  overflow-auto">
          {steps?.map((step, indx) => (
            <div key={indx} className="flex flex-row items-center gap-2">
              <div
                className={classNames(
                  "text-sm m-0 w-5 h-5 rounded-full flex items-center justify-center text-white",
                  step.error ? "bg-primaryred" : "bg-primaryblue"
                )}
              >
                {indx + 1}
              </div>
              <p
                className={classNames(
                  "m-0 text-sm",
                  step.error ? "text-primaryred" : "text-primarytextgrey"
                )}
              >
                {step.discription}
              </p>
            </div>
          ))}
          {errors?.map((detail, indx) => (
            <div key={indx} className="flex flex-row items-center gap-2">
              <div
                className={classNames(
                  "text-sm m-0 w-8 h-8 rounded-full flex items-center justify-center text-white",
                  "bg-primaryred"
                )}
              >
                {indx + 1}
              </div>
              <p className={classNames("m-0 text-sm", "text-primaryred")}>
                {Object.keys(detail)
                  .map((d) => `${d}: ${detail[d]}`)
                  .join(", ")}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UploadCareplanRight;
