import React, { useEffect, useState } from "react";
import CloseSvg from "../../../../../assets/svgs/CloseSvg";
import axios from "axios";
import moment from "moment";
import { useFetchOrganisationCommunitiesMutation } from "../../../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../features/auth/authSlice";
import {
  useCreateCommunityConstraintMutation,
  useUpdateCommunityConstraintMutation,
} from "../../../../../features/constraints/community/communityConstraintApiSlice";
import ReusableModal from "../../../../../components/Modals/ReusableModal";

const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

const frequencies = [
  {
    id: 1,
    name: "NA",
    value: 0,
  },
  {
    id: 2,
    name: "1 week",
    value: 7,
  },
  {
    id: 3,
    name: "2 weeks",
    value: 14,
  },
  {
    id: 4,
    name: "3 weeks",
    value: 21,
  },
  {
    id: 6,
    name: "1 month",
    value: 30,
  },
  {
    id: 7,
    name: "2 month",
    value: 60,
  },
  {
    id: 8,
    name: "3 month",
    value: 90,
  },
];

const CommunityVisitRulesModal = ({
  handleCommunityVisitRulesModal,
  setRefresh,
  details = null,
  visitRules,
  setVisitRules,
  fetchVisitRules,
  isWelcomePage,
}) => {
  const [communities, setCommunities] = useState([]);

  const [communityId, setCommunityId] = useState(
    details === null ? [] : details.communityId
  );
  const [selectedDays, setSelectedDays] = useState(
    details === null
      ? ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"]
      : details.visitDays
  );
  const [visitsPerDay, setVisitsPerDay] = useState(
    details === null ? details : details.capacity
  );
  const [frequency, setFrequency] = useState(
    details === null ? details : details.frequency
  );
  const [from, setFrom] = useState(
    details === null ? moment().format("YYYY-MM-DD") : details.startDate
  );
  const [to, setTo] = useState(details === null ? details : details.endDate);

  const handleSelectAll = () => {
    setSelectedDays(days);
  };

  const verifyDetails = () => {
    if (
      communityId === null ||
      selectedDays.length === 0 ||
      visitsPerDay === null ||
      frequency === null
    ) {
      return false;
    }

    return true;
  };

  const [fetchOrganisationCommunitiesAPI] =
    useFetchOrganisationCommunitiesMutation();

  const user = useSelector(selectUser);

  const fetchCommunityLocations = async () => {
    try {
      const response = await fetchOrganisationCommunitiesAPI(
        user.organisation.id
      ).unwrap();

      const communities = response.data
        .filter((location) => location._type === "COMMUNITY_HOME")
        .map((location) => ({ name: location.name, id: location.id }));
      setCommunities(communities);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const [createCommunityConstraintAPI] = useCreateCommunityConstraintMutation();

  const createCommunityConstraint = async (data) => {
    try {
      const response = await createCommunityConstraintAPI({
        community_id: data.community_id,
        organisation_id: user.organisation.id,
        ...data,
      }).unwrap();
      setRefresh(true);
      handleCommunityVisitRulesModal();
      return response;
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const [updateCommunityConstraintAPI] = useUpdateCommunityConstraintMutation();

  const updateCommunityConstraint = async (data) => {
    try {
      await updateCommunityConstraintAPI(data).unwrap();

      const updatedRules = visitRules.community.map((rule) => {
        if (rule.id === data.id) {
          return {
            ...rule,
            organisation_id: user.organisation.id,
            communityId: data.community_id,
            visitDays: data.visit_days,
            capacity: data.max_appointments_per_visit,
            frequency: data.frequency,
            startDate: data.start_date,
            endDate: data.end_date,
          };
        }
        return rule;
      });
      const allRules = {
        ...visitRules,
        community: updatedRules,
      };

      setVisitRules(allRules);

      handleCommunityVisitRulesModal();
    } catch (error) {
      console.error(error.data.message);
    }
  };

  useEffect(() => {
    fetchCommunityLocations();
  }, []);

  return (
    <ReusableModal
      open
      title={"Community Visit Rule"}
      onClose={handleCommunityVisitRulesModal}
      boxClasses="xs:w-[300px] md:w-[500px] overflow-y-auto"
    >
      <div className="flex flex-row items-center justify-between xs:w-[300px] w-full">
        <div className="flex flex-col items-start justify-start w-full gap-2 p-4">
          <p className="m-0 font-bold">Community</p>
          <select
            className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
            value={communityId}
            onChange={(e) => setCommunityId(e.target.value)}
            disabled={details !== null}
          >
            <option value={null}>Select</option>
            {communities.map((community, index) => (
              <option key={index} value={community.id}>
                {community.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* <div className="w-full border border-primarygrayborder" /> */}
      <div className="flex flex-col items-start justify-between gap-2 p-2 px-4">
        <h1 className="m-0 text-lg font-bold">
          Availability
          <button className="ml-2 text-base" onClick={handleSelectAll}>
            (<span className="underline text-primaryblue">select all</span>)
          </button>
        </h1>
        <div className="flex flex-row flex-wrap w-full gap-3">
          {days.map((data, index) => (
            <label
              key={index}
              className="flex flex-row items-center gap-2 row-gap-4"
            >
              <input
                type="checkbox"
                className="w-4 h-4 checked:lightblue"
                checked={selectedDays.includes(data)}
                onChange={() => {
                  if (selectedDays.includes(data)) {
                    setSelectedDays(selectedDays.filter((day) => day !== data));
                  } else {
                    setSelectedDays([...selectedDays, data]);
                  }
                }}
              />
              <p className="m-0 text-sm">
                {data[0] + data.substring(1).toLowerCase()}
              </p>
            </label>
          ))}
        </div>
      </div>
      {/* <div className="w-full border border-primarygrayborder" /> */}
      <div className="flex flex-col py-2">
        <div className="flex flex-col items-center md:flex-row md:py-2">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
            <p className="m-0 font-bold whitespace-nowrap">
              Appointments per Visit
            </p>
            <select
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
              value={visitsPerDay}
              onChange={(e) => setVisitsPerDay(e.target.value)}
            >
              <option value={null}>Select</option>
              {Array.from({ length: 26 }, (_, i) => i + 5).map(
                (data, index) => (
                  <option key={index} value={data}>
                    {data}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
            <p className="m-0 font-bold">Visit Frequency</p>
            <select
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
            >
              <option value={null}>Select</option>
              {frequencies.map((data, index) => (
                <option key={index} value={data.value}>
                  {data.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex flex-col items-center md:flex-row md:py-2">
          <div className="flex flex-col w-full gap-2 p-2 px-4">
            <label className="font-semibold">From</label>
            <input
              type="date"
              className="w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
              min={moment().format("YYYY-MM-DD")}
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className="flex flex-col w-full gap-2 p-2 px-4">
            <label className="font-semibold">To</label>
            <input
              type="date"
              className="w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded md:w-fit focus:ring-blue-500 focus:border-blue-500"
              min={from}
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="w-full border border-primarygrayborder" />
      <div className="flex flex-col items-start justify-between gap-2 p-4">
        <div className="flex flex-row items-center justify-end w-full gap-4">
          <button
            className="px-4 py-2 font-bold text-white bg-gray-400 rounded-md"
            onClick={handleCommunityVisitRulesModal}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 font-bold text-white rounded-md bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
            disabled={!verifyDetails()}
            onClick={async () => {
              if (details === null) {
                await createCommunityConstraint({
                  community_id: communityId,
                  visit_days: selectedDays,
                  max_appointments_per_visit: +visitsPerDay,
                  frequency: +frequency,
                  start_date: from || moment().format("YYYY-MM-DD"),
                  end_date: to,
                });
              } else {
                await updateCommunityConstraint({
                  id: details.id,
                  community_id: communityId,
                  visit_days: selectedDays,
                  max_appointments_per_visit: +visitsPerDay,
                  frequency: +frequency,
                  start_date: from,
                  end_date: to,
                });
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default CommunityVisitRulesModal;
