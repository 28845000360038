import { Check, DownloadForOfflineRounded } from "@mui/icons-material";
import React from "react";

const demoInvoices = [
  {
    id: 1,
    invoiceNumber: "INV-1234",
    date: "01/03/2024",
    amount: "$801",
    status: "PAID",
  },
  {
    id: 2,
    invoiceNumber: "INV-5678",
    date: "01/02/2024",
    amount: "$712",
    status: "PAID",
  },
  {
    id: 3,
    invoiceNumber: "INV-9876",
    date: "01/01/2024",
    amount: "$712",
    status: "PAID",
  },
  {
    id: 4,
    invoiceNumber: "INV-5432",
    date: "01/12/2023",
    amount: "$890",
    status: "PAID",
  },
];

const Invoices = () => {
  return (
    <div className="flex flex-col gap-6 px-12 py-10">
      <div className="flex flex-row justify-between w-full">
        <h5 className="m-0 text-3xl font-semibold">Invoices</h5>
      </div>
      <table className="w-full">
        <thead>
          <tr className="bg-primarylightgrey">
            <th className="px-4 py-3 text-left">Invoice</th>
            <th className="px-4 py-3 text-left">Date</th>
            <th className="px-4 py-3 text-left">Amount</th>
            <th className="px-4 py-3 text-left">Status</th>
            <th className="px-4 py-3 text-left"></th>
          </tr>
        </thead>
        <tbody>
          {demoInvoices.length > 1 ? (
            demoInvoices.map((invoice) => (
              <tr
                key={invoice.id}
                className="border-b border-primarygrayborder"
              >
                <td className="px-4 py-3 text-left">{invoice.invoiceNumber}</td>
                <td className="px-4 py-3 text-left">{invoice.date}</td>
                <td className="px-4 py-3 text-left">{invoice.amount}</td>
                <td className="px-4 py-3 text-left">
                  {invoice.status === "PAID" ? (
                    <div className="flex flex-row items-center gap-2 px-2 py-1 rounded-md bg-subtlegreen w-fit">
                      <Check
                        style={{
                          color: "#0E4E30",
                          width: "16px",
                          height: "16px",
                          objectFit: "contain",
                        }}
                      />
                      <p className="m-0 text-sm text-primarytextgreen">Paid</p>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <DownloadForOfflineRounded
                    style={{
                      color: "#3062D4",
                    }}
                  />
                </td>
              </tr>
            ))
          ) : (
            <div
              className="absolute w-full py-2 my-3 d-flex align-Items-center justify-content-center"
              style={{ zIndex: 0 }}
            >
              <p className="text-xl font-bold text-primarygrayborder">
                {" "}
                No Records Found
              </p>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Invoices;
