import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { Search_Icon } from "../../../assets/images";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  EmailOutlined,
  FilterList,
  LocationOnOutlined,
  PeopleSharp,
  Phone,
} from "@mui/icons-material";
import CheckboxSelector from "../../Schedules/components/CheckboxSelector";
import moment from "moment/moment";
import {
  useFetchProviderByIdMutation,
  useFetchProviderCareplansMutation,
} from "../../../features/provider/providerApiSlice";
import { useParams } from "react-router-dom";

const ProviderInfo = () => {
  const [patients, setPatients] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [provider, setProvider] = React.useState(null);

  const [statusFilter, setStatusFilter] = React.useState([]);
  const [locationFilter, setLocationFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [locationDropdown, setLocationDropdown] = React.useState(false);
  const [statusDropdown, setStatusDropdown] = React.useState(false);

  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");

  const { id } = useParams();

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleLocationFilter = (location) => {
    if (locationFilter.indexOf(location) === -1) {
      setLocationFilter([...locationFilter, location]);
    } else {
      setLocationFilter(locationFilter.filter((filter) => filter !== location));
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const [getPatients] = useFetchProviderCareplansMutation();

  const [getProviderById] = useFetchProviderByIdMutation();

  const fetchPatients = async () => {
    try {
      const { data } = await getPatients(id).unwrap();

      setPatients(data);
      setLocations(
        Array.from(
          new Set(
            data.map((patient) =>
              patient.locationName === null
                ? "Private Residence"
                : patient.locationName
            )
          )
        )
      );
      setStatuses(Array.from(new Set(data.map((patient) => patient.status))));
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  const fetchProvider = async () => {
    try {
      const { data } = await getProviderById(id).unwrap();

      setProvider(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchProvider();
      fetchPatients();
    }
  }, [id]);

  const Avatar = ({
    name,
    image,
    size = "w-10 h-10",
    textSize = "text-base",
    fontWeight = "font-normal",
  }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className={classNames(
                "object-cover w-10 h-10 rounded-full",
                size
              )}
            />
          </div>
        ) : (
          <p
            className={classNames(
              "flex items-center justify-center m-0 text-white rounded-full bg-primaryblue",
              size,
              textSize,
              fontWeight
            )}
          >
            {name
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-row items-start">
      <div className="w-4/12 py-4 pl-16 pr-8">
        <div className="w-full overflow-x-auto overflow-y-auto h-[calc(100vh-128px)] bg-white rounded-lg shadow border-1 border-primarygrayborder">
          <div className="flex flex-col items-center justify-end gap-2 p-4 border-b border-b-primarygrayborder">
            <div className="relative">
              <Avatar
                name={provider?.name ?? ""}
                image={null}
                size="w-20 h-20"
                textSize="text-3xl"
                fontWeight="font-semibold"
              />
              <div className="absolute right-0 w-4 h-4 rounded-full bottom-14 bg-primarygreen" />
            </div>
            {provider && (
              <p className="m-0 text-lg font-bold">Dr. {provider?.name}</p>
            )}
            <p className="m-0 text-sm text-primarytextgrey">
              {provider?.speciality}
            </p>
          </div>
          <div className="flex flex-col h-auto gap-4 p-4 border-b border-b-primarygrayborder">
            <div className="flex items-center gap-4">
              {provider && (
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <EmailOutlined
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
              )}
              <p className="m-0">{provider?.email}</p>
            </div>
            <div className="flex items-center gap-4">
              {provider && (
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <Phone
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
              )}
              <p className="m-0">{provider?.phone}</p>
            </div>
            <div className="flex items-start gap-4">
              {provider && (
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <LocationOnOutlined
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
              )}
              <p className="m-0">{provider?.address}</p>
            </div>
          </div>
          <div className="h-auto p-4">
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 rounded-md bg-subtleblue">
                <PeopleSharp
                  style={{
                    color: "#3062D4",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p className="m-0 font-semibold text-primaryblue">Patients</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start w-8/12 py-4">
        <div className="flex flex-row items-center justify-between w-full pl-8 pr-16">
          <div className="flex flex-row items-center w-full gap-12">
            <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
              Patients{" "}
              <span className="ml-2 text-base font-bold text-primaryblue">
                {patients.length}
              </span>
            </h1>
            <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
              <div className="relative w-1/3 flex flex-row items-center gap-2 rounded-md border-[2px] border-primarygrayborder overflow-clip">
                <div className="pl-2">
                  <img
                    src={Search_Icon}
                    alt="search"
                    className="w-5 h-5 cursor-pointer search-icon opacity-80"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Search..."
                  className="w-full h-full p-2 text-base outline-none"
                  value={searchFilter}
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              </div>
              <div className="w-1/3 relative flex flex-row divide-x-[2px] divide-primarygrayborder items-center gap-2 rounded-md border-[2px] border-primarygrayborder h-10">
                <div className="ml-2 min-w-fit">
                  <button
                    onClick={() => setLocationDropdown(!locationDropdown)}
                    className={classNames(
                      "rounded-full w-7 h-7",
                      locationFilter.length > 0
                        ? "bg-primaryblue"
                        : "bg-primarygrayborder"
                    )}
                  >
                    <FilterList
                      style={{
                        color: "#FFF",
                        fontSize: "20px",
                      }}
                    />
                  </button>
                </div>
                <div className="flex flex-row items-center h-full gap-2 px-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
                  {locationFilter.length === 0 && (
                    <p className="m-0 text-base text-primarygrayborder px-2 py-[2px] whitespace-nowrap">
                      Filter by Location ...
                    </p>
                  )}
                  {locationFilter.map((status, index) => (
                    <div
                      key={index}
                      className="flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primaryblue"
                    >
                      <p
                        aria-multiline={false}
                        className="m-0 whitespace-nowrap"
                      >
                        {status}
                      </p>
                      <button
                        onClick={() => handleLocationFilter(status)}
                        className="flex items-center justify-center scale-90 border border-white rounded-full aspect-square"
                      >
                        <CloseSvg color={"#FFF"} />
                      </button>
                    </div>
                  ))}
                </div>
                {locationDropdown && Array.isArray(patients) && (
                  <div className="absolute z-50 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14 speciality-dropdown">
                    {locations.map((status, index) => (
                      <div
                        className={classNames(
                          "hover:bg-primarylightgrey",
                          index !==
                            new Set(
                              patients.map((patient) => patient.locationName)
                            ).size -
                              1 && "border-b"
                        )}
                      >
                        <CheckboxSelector
                          key={index}
                          header={status}
                          isChecked={locationFilter.indexOf(status) !== -1}
                          handleChange={(_e) => handleLocationFilter(status)}
                          isBold={false}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="w-1/3 relative flex flex-row divide-x-[2px] divide-primarygrayborder items-center gap-2 rounded-md border-[2px] border-primarygrayborder h-10">
                <div className="ml-2 min-w-fit">
                  <button
                    onClick={() => setStatusDropdown(!statusDropdown)}
                    className={classNames(
                      "rounded-full w-7 h-7",
                      statusFilter.length > 0
                        ? "bg-primaryblue"
                        : "bg-primarygrayborder"
                    )}
                  >
                    <FilterList
                      style={{
                        color: "#FFF",
                        fontSize: "20px",
                      }}
                    />
                  </button>
                </div>
                <div className="flex flex-row items-center h-full gap-2 px-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
                  {statusFilter.length === 0 && (
                    <p className="m-0 text-base text-primarygrayborder px-2 py-[2px] whitespace-nowrap">
                      Filter by Status ...
                    </p>
                  )}
                  {statusFilter.map((status, index) => (
                    <div
                      key={index}
                      className="flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primaryblue"
                    >
                      <p
                        aria-multiline={false}
                        className="m-0 whitespace-nowrap"
                      >
                        {status}
                      </p>
                      <button
                        onClick={() => handleStatusFilter(status)}
                        className="flex items-center justify-center scale-90 border border-white rounded-full aspect-square"
                      >
                        <CloseSvg color={"#FFF"} />
                      </button>
                    </div>
                  ))}
                </div>
                {statusDropdown && Array.isArray(patients) && (
                  <div className="absolute z-50 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14 speciality-dropdown">
                    {statuses.map((status, index) => (
                      <div
                        className={classNames(
                          "hover:bg-primarylightgrey",
                          index !==
                            new Set(patients.map((patient) => patient.status))
                              .size -
                              1 && "border-b"
                        )}
                      >
                        <CheckboxSelector
                          key={index}
                          header={status}
                          isChecked={statusFilter.indexOf(status) !== -1}
                          handleChange={(_e) => handleStatusFilter(status)}
                          isBold={false}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pl-8 pr-16">
          <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
            <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
              <thead>
                <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                  <th className="px-4 py-3">
                    <div className="flex flex-row items-center gap-2">
                      <p className="m-0">Name</p>
                      <button
                        className="flex flex-col items-center gap-1"
                        onClick={() => {
                          if (sortNameOrder === "none") {
                            setSortNameOrder("asc");
                          }
                          if (sortNameOrder === "asc") {
                            setSortNameOrder("desc");
                          }
                          if (sortNameOrder === "desc") {
                            setSortNameOrder("none");
                          }
                        }}
                      >
                        {sortNameOrder === "asc" ? (
                          <ArrowDownwardTwoTone />
                        ) : sortNameOrder === "desc" ? (
                          <ArrowUpwardTwoTone />
                        ) : (
                          <div className="flex items-center h-4">
                            <div className="w-6 h-[2px] bg-white" />
                          </div>
                        )}
                      </button>
                    </div>
                  </th>
                  <th className="px-4 py-3">Visited On</th>
                  <th className="px-4 py-3">Date of Birth</th>
                  <th className="px-4 py-3">Gender</th>
                  <th className="px-4 py-3">EHR ID</th>
                  <th className="px-4 py-3">Private / Group Home</th>
                  <th className="px-4 py-3">
                    <div className="flex flex-row items-center gap-2">
                      <p className="m-0">Status</p>
                      <button
                        className="flex flex-col items-center gap-1"
                        onClick={() => {
                          if (sortStatusOrder === "none") {
                            setSortStatusOrder("asc");
                          }
                          if (sortStatusOrder === "asc") {
                            setSortStatusOrder("desc");
                          }
                          if (sortStatusOrder === "desc") {
                            setSortStatusOrder("none");
                          }
                        }}
                      >
                        {sortStatusOrder === "asc" ? (
                          <ArrowDownwardTwoTone />
                        ) : sortStatusOrder === "desc" ? (
                          <ArrowUpwardTwoTone />
                        ) : (
                          <div className="flex items-center h-4">
                            <div className="w-6 h-[2px] bg-white" />
                          </div>
                        )}
                      </button>
                    </div>
                  </th>
                  {/* <th className="px-4 py-3">Edit</th> */}
                </tr>
              </thead>
              <tbody>
                {patients
                  .filter((patient) =>
                    patient.name
                      .toLowerCase()
                      .includes(searchFilter.toLowerCase())
                  )
                  .filter(
                    (patient) =>
                      statusFilter.length === 0 ||
                      statusFilter.indexOf(patient.status) !== -1
                  )
                  .filter(
                    (patient) =>
                      locationFilter.length === 0 ||
                      locationFilter.indexOf(
                        patient.locationName === null
                          ? "Private Residence"
                          : patient.locationName
                      ) !== -1
                  )
                  .sort((a, b) => handleSortName(a, b))
                  .sort((a, b) => handleSortStatus(a, b))
                  .map((patient, index) => (
                    <tr
                      className={classNames(
                        "text-left text-gray-900 h-24 whitespace-nowrap",
                        index !== patients.length - 1
                          ? "border-b"
                          : "border-none"
                      )}
                      key={index}
                    >
                      <td className="px-4 py-3 text-left">
                        <button className="flex flex-row items-center gap-4">
                          <Avatar name={patient.name} image={null} />
                          <p className="m-0 font-semibold underline">
                            {patient.name}
                          </p>
                        </button>
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        {moment(patient.lvd).format("MM/DD/YYYY")}
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        {moment(patient.dob).format("MM/DD/YYYY")}
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        {patient.sex === "F" ? "Female" : "Male"}
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        {patient.patientEhrId}
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        {patient.locationName === null
                          ? "Private Residence"
                          : patient.locationName}
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        <div
                          className={classNames(
                            patient.status.toUpperCase() === "ACTIVE"
                              ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                              : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                            "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                          )}
                        >
                          <div
                            className={classNames(
                              "w-2 h-2 rounded-full",
                              patient.status.toUpperCase() === "ACTIVE"
                                ? "bg-primarygreen"
                                : "bg-primarytextgrey"
                            )}
                          />
                          {patient.status}
                        </div>
                      </td>
                      {/* <td className="px-4 py-3 text-left">
                    <button
                      className="p-2 text-white rounded-md"
                      onClick={() => {}}
                    >
                      <IconComponent icon={"Edit_Icon"} i={provider.id} />
                    </button>
                  </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderInfo;
