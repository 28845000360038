import React from 'react'
import {
    useGoogleAuthMutation,
    useVerifyEmailMutation,
} from "../../../features/auth/authApiSlice";
import Logo from "../../../assets/images/CareSMS_Logo.png";
import { useNavigate } from "react-router-dom";
import { PlayArrow } from '@mui/icons-material';

function EnterOtherEmail({ option, step, handleStepChange, email, setEmail }) {

    const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
    const [errMsg, setErrMsg] = React.useState(null);
    const navigate = useNavigate();
    const handleEmailAuth = async () => {
        try {
            await verifyEmail({
                email: email,
                path: option,
                provider: "Email",
            }).unwrap();
            handleStepChange(step + 1);
        } catch (error) {
            if (!error.status) setErrMsg("No response from server");
            else if (error.status === 400 || error.status === 404)
                setErrMsg(error.data.message);
            else setErrMsg("An error occurred. Please try again.");
        }
    };
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    return (
        <div className="relative flex flex-col gap-8 w-11/12 base:mt-36">
            <div className="flex flex-col mr-auto w-full gap-10">
                <div className="flex flex-col w-full gap-5">
                    <img src={Logo} alt="logo" className="-ml-2 logo" />
                    <div className="flex flex-col w-full gap-2 m-0 text-left">
                        <h1 className="m-0 text-2xl font-bold">
                            {option === "signup"
                                ? "Create your CareSMS Scheduling Account"
                                : "Signin to your CareSMS Scheduling Account"}
                        </h1>
                        <h2 className="m-0 text-base font-normal">
                            Start creating optimized schedules for your Providers in seconds,
                            <br className="hidden sm:block" /> not hours with the CareSMS Scheduling
                            Assistant.
                        </h2>
                    </div>
                </div>
                <div className="flex flex-col w-full gap-8 sm:gap-8">
                    <div className="flex flex-col gap-2">
                        <label htmlFor="email" className="text-sm font-semibold">
                            Email address
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="p-2 text-sm border rounded-lg border-primarylightgrey"
                            placeholder="john.doe@gmail.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {errMsg && <p className="m-0 text-xs text-danger">{errMsg}</p>}
                    </div>
                    <button
                        // disabled={isLoading}
                        disabled={!isValidEmail(email)}
                        onClick={handleEmailAuth}
                        className="flex items-center justify-center w-full gap-2 p-2 font-semibold text-white transition-all duration-300 ease-in-out rounded-lg bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
                    >
                        Sign {option === "signup" ? "up" : "in"} with email
                        <div className="relative flex flex-row">
                            <PlayArrow
                                style={{
                                    color: "#fff",
                                    width: "18px",
                                    height: "18px",
                                    objectFit: "contain",
                                }}
                            />
                            <PlayArrow
                                style={{
                                    color: "#fff",
                                    width: "18px",
                                    height: "18px",
                                    objectFit: "contain",
                                    position: "absolute",
                                    left: -5,
                                }}
                            />
                        </div>
                    </button>
                    <p className="m-0 text-center">
                        {option === "signup" ? "Already" : "Don't"} have an account?{" "}
                        <a
                            className="no-underline text-primaryblue"
                            href={option === "signup" ? "/signin" : "/signup"}
                        >
                            Sign {option === "signup" ? "In" : "Up"}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default EnterOtherEmail