import React, { useEffect, useState } from "react";
import PaymentMethods from "./components/PaymentMethods";
import PlanDetails from "./components/PlanDetails";
import Invoices from "./components/Invoices";
import { useGetSubscriptionMutation } from "../../features/billing/billingApiSlice";
import moment from "moment";
import { useLSStore } from "../../context/ls-store";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";

const Billing = () => {
  const [getSubscription] = useGetSubscriptionMutation();

  // const subscription = useLSStore((state) => state.subscription);
  // const setSubscription = useLSStore((state) => state.setSubscription);

  // console.log(subscription, "subscription");

  // const fetchSubscription = async () => {
  //   try {
  //     const response = await getSubscription().unwrap();
  //     const { subscription, metaData } = response.data;

  //     if (subscription.status === "trialing") {
  //       console.log(
  //         `Subscription started on: ${moment(
  //           new Date(subscription.current_period_start * 1000)
  //         ).format("YYYY-MM-DD")} and ends on: ${moment(
  //           new Date(subscription.current_period_end * 1000)
  //         ).format("YYYY-MM-DD")}`
  //       );
  //     }

  //     setSubscription({
  //       ...subscription,
  //       ...metaData,
  //     });
  //   } catch (error) {
  //     console.error("Failed to fetch subscription", error);
  //   }
  // };

  // useEffect(() => {
  //   console.log(subscription);
  // }, []);

  // useEffect(() => {
  //   fetchSubscription();
  // }, []);

  const user = useSelector(selectUser);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col justify-start gap-2 px-12 py-10">
        <h1 className="m-0 text-3xl font-semibold">Billing Details</h1>
        <h2 className="m-0 text-lg text-primarytextgrey">
          Review your organization's billing plan and payment methods
        </h2>
      </div>
      <div className="w-full h-[1px] bg-primarygrayborder" />
      <div className="flex flex-col gap-2 px-12 py-10">
        <h4 className="m-0 text-sm text-primarytextgrey">Next billing date</h4>
        <h3 className="m-0 text-3xl font-semibold">
          {user.subscription.currentPeriodEnd
            ? moment(
                new Date(user.subscription.currentPeriodEnd * 1000)
              ).format("MMM DD, YYYY")
            : "NA"}
        </h3>
      </div>
      <div className="w-full h-[1px] bg-primarygrayborder" />
      <PaymentMethods />
      <div className="w-full h-[1px] bg-primarygrayborder" />
      <PlanDetails user={user} />
      <div className="w-full h-[1px] bg-primarygrayborder" />
      <Invoices />
    </div>
  );
};

export default Billing;
