import classNames from "classnames";
import moment from "moment";
import React from "react";
import CloseSvg from "../../assets/svgs/CloseSvg";
import {
  useClosestPatientAppointmentDistancesMutation,
  useCreateNewPatientAppointmentMutation,
} from "../../features/appointment/appointmentApiSlice";
import MapV3 from "../../components/Maps/MapV3";
import ReusableModal from "../../components/Modals/ReusableModal";
import Select from "react-select";

const PatientSchedulingModal = ({
  filterOpen,
  providerAppointment,
  setFilterOpen,
  setProviderAppointment,
  appointments,
  setAppointments,
  dates,
  dateIndex,
  patientId,
  appointmentTypes,
}) => {
  const [fetchClosestDistance] =
    useClosestPatientAppointmentDistancesMutation();

  const [updatedAppointments, setUpdatedAppointments] = React.useState([]);

  const [uniqueLocations, setUniqueLocations] = React.useState([]);

  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);

  const [appointmentType, setAppointmentType] = React.useState(null);

  const [updates, setUpdates] = React.useState([]);

  const [createAppointment] = useCreateNewPatientAppointmentMutation();

  const [distanceFromPreviousAppointment, setDistanceFromPreviousAppointment] =
    React.useState(0);

  const [distanceFromProvider, setDistanceFromProvider] = React.useState(0);

  const getClosestDistance = async (appointments) => {
    const { data } = await fetchClosestDistance({
      date: moment(dates[dateIndex]).format("YYYY-MM-DD"),
      patientId: patientId,
      providerId: providerAppointment.provider.id,
      position: providerAppointment.position,
    });

    const previousAppointments = appointments
      .slice(0, providerAppointment.position - 1)
      .map((appointment) => {
        return {
          ...appointment,
          sequence: appointment.sequenceNo,
        };
      });

    const appointment = {
      patient: {
        name: data.data.name,
      },
      sequence: providerAppointment.position,
      location: data.data.location,
      distance: previousAppointments.length === 0 ? 0 : data.data.distance[0],
      distanceFromHome:
        previousAppointments.length === 0 ? data.data.distance[0] : 0,
    };

    if (previousAppointments.length === 0) {
      setDistanceFromProvider(data.data.distance[0]);
      setDistanceFromPreviousAppointment(0);
    } else {
      setDistanceFromProvider(0);
      setDistanceFromPreviousAppointment(data.data.distance[0]);
    }

    const nextAppointments = appointments
      .slice(providerAppointment.position - 1)
      .map((appointment, index) => {
        return {
          ...appointment,
          sequence: appointment.sequenceNo + 1,
          distance: index === 0 ? data.data.distance[1] : appointment.distance,
        };
      });

    const updatedAppointments = [
      ...previousAppointments,
      appointment,
      ...nextAppointments,
    ];

    const updates = nextAppointments.map((appointment) => {
      return {
        id: appointment.appointmentId,
        sequenceNumber: appointment.sequence,
        distanceFromPreviousAppointment: +appointment.distance,
      };
    });

    setUpdates(updates);

    setUpdatedAppointments(updatedAppointments);

    const locationMap = updatedAppointments.reduce(
      (acc, appointment, index) => {
        if (!acc[appointment.location.id]) {
          acc[appointment.location.id] = {
            location: appointment.location,
            appointments: [],
          };
        }
        acc[appointment.location.id].appointments.push({
          sequence: index + 1,
          patient: appointment.patient.name,
          proposedPatient: index + 1 === providerAppointment.position,
        });
        return acc;
      },
      {}
    );

    const uniqueLocations = Object.values(locationMap);

    setUniqueLocations(uniqueLocations);
  };

  React.useEffect(() => {
    if (providerAppointment) getClosestDistance(appointments);
  }, [appointments]);

  React.useEffect(() => {}, [updatedAppointments]);

  return (
    <>
      <div
        className={classNames(
          "absolute top-[0px] z-100 transition-all min-h-fit max-h-screen overflow-auto bg-white w-[564px] border-l border-t border-b border-secondarygrayborder right-0 opacity-100",
          filterOpen ? "right-0" : "right-[-400px]"
        )}
      >
        <div className="flex flex-row items-center justify-between w-full p-4">
          <div className="flex flex-col gap-2">
            <p className="m-0 text-2xl font-bold text-primaryblack">
              {providerAppointment.provider.name}
            </p>
            <p className="m-0 text-gray-400">
              {moment(dates[dateIndex]).format("dddd MMMM Do, YYYY")}
            </p>
          </div>
          <button
            onClick={() => {
              setFilterOpen(false);
              setProviderAppointment(null);
              setAppointments([]);
              setUpdatedAppointments([]);
              setUniqueLocations([]);
            }}
            className="flex items-center justify-center w-10 rounded-full aspect-square bg-primarylightgrey"
          >
            <CloseSvg color={"#3062D4"} />
          </button>
        </div>
        <div className="w-full h-[1px] bg-gray-200" />
        <div className="p-4 max-w-[480px]">
          {uniqueLocations.length > 0 && (
            <MapV3
              locations={uniqueLocations}
              provider={providerAppointment.provider}
            />
          )}
        </div>
        <div className="w-full h-[1px] bg-gray-200" />
        <div className="flex flex-col w-full gap-4 p-4 mb-24 overflow-auto">
          {updatedAppointments.map((appointment, index) => (
            <div
              key={index}
              className={classNames(
                "flex items-center gap-4 p-4 border rounded-lg shadow-sm",
                providerAppointment.position === index + 1 && "bg-subtleorange"
              )}
            >
              <div
                className={classNames(
                  "flex items-center justify-center h-8 rounded-full min-w-8 bg-primaryblue",
                  providerAppointment.position === index + 1
                    ? "bg-primaryorange"
                    : "bg-primaryblue"
                )}
              >
                <p className="m-0 font-bold text-white">
                  {appointment.sequence}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="m-0 text-lg font-bold">
                  {appointment.patient.name} | {appointment.location.name}
                </p>
                <p
                  className={classNames(
                    "m-0 text-sm",
                    providerAppointment.position === index + 1
                      ? "text-black"
                      : "text-gray-400"
                  )}
                >
                  {index === 0
                    ? `${appointment.distanceFromHome} miles from Home`
                    : `${appointment.distance} miles from Visit ${index}`}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full h-[1px] bg-gray-200" />
        <div className="fixed bottom-0 flex items-center justify-center p-4 bg-white w-[564px] border-t">
          <button
            onClick={() => setOpenConfirmModal(true)}
            className="py-2 px-4 font-bold border-[1px] shadow-sm rounded-md text-primarytextgreen border-primarytextgreen"
          >
            Confirm and Schedule
          </button>
        </div>
      </div>
      <ReusableModal open={openConfirmModal} hideHeader>
        <div className="flex flex-col items-center min-w-[480px]">
          <div className="flex flex-row items-center justify-between w-full p-6">
            <p className="m-0 text-2xl font-bold text-primaryblack">
              New Appointment
            </p>
            <button
              onClick={() => setOpenConfirmModal(false)}
              className="flex border-[2px] border-primarygrayborder items-center justify-center w-8 rounded-full aspect-square bg-primarylightgrey"
            >
              <CloseSvg color={"#828282"} />
            </button>
          </div>
          <div className="h-[1px] w-full bg-primarygrayborder" />
          <div className="flex flex-col w-full gap-2 p-4">
            <label htmlFor="patientSelect" className="text-sm font-bold">
              Appointment Type
            </label>
            <div className="w-full">
              <Select
                options={appointmentTypes}
                isSearchable
                isClearable
                onChange={(selectedOption) => {
                  setAppointmentType(selectedOption);
                }}
              />
            </div>
          </div>
          <div className="h-[1px] w-full bg-primarygrayborder" />
          <div className="flex flex-row items-center justify-between w-full gap-6 p-4">
            <button
              onClick={() => setOpenConfirmModal(false)}
              className="w-1/2 p-2 font-semibold rounded-md bg-primarylightgrey border-[2px] text-primarytextgrey border-primarygrayborder"
            >
              Cancel
            </button>
            <button
              disabled={!appointmentType}
              onClick={async () => {
                await createAppointment({
                  appointment: {
                    visitDate: moment(dates[dateIndex]).format("YYYY-MM-DD"),
                    sequenceNumber: providerAppointment.position,
                    appointmentType: appointmentType.value,
                    patientId: patientId,
                    providerId: providerAppointment.provider.id,
                    distanceFromPreviousAppointment,
                    distanceFromProvider,
                  },
                  updates,
                }).unwrap();
                setOpenConfirmModal(false);
              }}
              className="w-1/2 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
            >
              Create
            </button>
          </div>
        </div>
      </ReusableModal>
    </>
  );
};

export default PatientSchedulingModal;
