// src/PropertiesPanel.js

import Button from '@mui/material/Button';
import ChartProperties from './PropertiesPanel/ChartProperties';
import CloseIcon from '@mui/icons-material/Close';
import ImageProperties from './PropertiesPanel/ImageProperties';
import React from 'react';
import SettingsIcon from "@mui/icons-material/Settings"
import TableProperties from './PropertiesPanel/TableProperties';
import TextboxProperties from './PropertiesPanel/TextboxProperties';

//import './PropertiesPanel.css';

const PropertiesPanel = ({
    component,
    onPropertyChange,
    setSelectedComponent,
    showAlert,
    setFlag,
    flag,
    onCloseDrawer,
    reportAreaRef,
    setIsSavedClicked,
    isSaveClicked,
    selectedTemplate,
    setIsCreateNewTemplate,
    isNewReportActive,
    reportId,
    generateChartData,
    echartsRef,
    generatePieChartData,
    generateDoubleChartData,
    setCurrentReportData,
    getUserTemplates,
    isAnimated,
    setIsAnimated
}) => {
    const handlePropertyChange = (e) => {
        const { name, value } = e.target;
        onPropertyChange(name, value);
    };

    const [openSection, setOpenSection] = React.useState(null);
    const [openItem, setOpenItem] = React.useState(null);

    const handleClose = () => {
        setOpenSection(null);
        setOpenItem(null);
    };

    const handleClosePanel = () => {
        onCloseDrawer();
        handleClose();
    };
    return (
        <div className="properties-panel " style={{ backgroundColor: "#ecf4f7" }}>
            <div className='flex justify-between items-center py-2 sticky z-10 top-0' style={{ backgroundColor: "rgb(10, 58, 103)" }}>
                <div className='flex items-center text-white text-sm font-semibold px-3 bg-[rgb(10,58,103)]'>
                    <SettingsIcon className="mr-2" />
                    <span>Properties</span>
                </div>
                <button className='mr-4 text-white'
                    // onClick={onCloseDrawer}
                    onClick={handleClosePanel}
                >
                    <CloseIcon></CloseIcon>
                </button>
            </div>
            <hr className='my-0' />
            {
                <>
                    {component?.type === 'table' && (<TableProperties
                        component={component}
                        showAlert={showAlert}
                        onPropertyChange={onPropertyChange}
                        setSelectedComponent={setSelectedComponent}
                        flag={flag}
                        setFlag={setFlag}
                        reportAreaRef={reportAreaRef}
                        setIsSaveClicked={setIsSavedClicked}
                        isSaveClicked={isSaveClicked}
                        selectedTemplate={selectedTemplate}
                        setIsCreateNewTemplate={setIsCreateNewTemplate}
                        isNewReportActive={isNewReportActive}
                        reportId={reportId}
                        setCurrentReportData={setCurrentReportData}
                        getUserTemplates={getUserTemplates}
                        openSection={openSection}
                        setOpenSection={setOpenSection}
                        openItem={openItem}
                        setOpenItem={setOpenItem}

                    />
                    )}
                    {component?.type === 'textbox' && (<TextboxProperties
                        component={component}
                        showAlert={showAlert}
                        onPropertyChange={onPropertyChange}
                        setSelectedComponent={setSelectedComponent}
                        flag={flag}
                        setFlag={setFlag}
                        reportAreaRef={reportAreaRef}
                        openSection={openSection}
                        setOpenSection={setOpenSection}
                    />
                    )}
                    {component?.type === 'chart' && (<ChartProperties
                        component={component}
                        onPropertyChange={onPropertyChange}
                        setSelectedComponent={setSelectedComponent}
                        setFlag={setFlag}
                        reportAreaRef={reportAreaRef}
                        showAlert={showAlert}
                        generateChartData={generateChartData}
                        echartsRef={echartsRef}
                        generatePieChartData={generatePieChartData}
                        generateDoubleChartData={generateDoubleChartData}
                        openSection={openSection}
                        setOpenSection={setOpenSection}
                        isAnimated={isAnimated}
                        setIsAnimated={setIsAnimated}
                    />
                    )}
                    {component?.type === 'image' && (<ImageProperties
                        component={component}
                        onPropertyChange={onPropertyChange}
                        setSelectedComponent={setSelectedComponent}
                        setFlag={setFlag}
                        reportAreaRef={reportAreaRef}
                        openSection={openSection}
                        setOpenSection={setOpenSection}
                    />
                    )}
                </>
            }
        </div>
    );
};

export default PropertiesPanel;
