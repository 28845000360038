import React from "react";
import classNames from "classnames";
import { Search_Icon } from "../../../assets/images";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  FilterList,
} from "@mui/icons-material";
import CheckboxSelector from "../../Schedules/components/CheckboxSelector";
import IconComponent from "../../../components/DataTable/Icon";
import CaretagModal from "./components/CaretagModal";
import { useGetAllCaretagsMutation } from "../../../features/caretag/caretagApiSlice";

// const caretags = [
//   {
//     id: 1,
//     name: "labs",
//     description: "Request for labs",
//     status: "Inactive",
//   },
//   {
//     id: 2,
//     name: "imaging",
//     description: "Request for imaging",
//     status: "Inactive",
//   },
//   {
//     id: 3,
//     name: "visitrequest",
//     description: "Request for visits",
//     status: "Active",
//   },
//   {
//     id: 4,
//     name: "movein",
//     description: "Request for move-in paperwork and all paperwork",
//     status: "Active",
//   },
//   {
//     id: 5,
//     name: "hospital",
//     description: "Going to or returning from hospital/SNF",
//     status: "Active",
//   },
//   {
//     id: 6,
//     name: "concern",
//     description: "Any clinical concerns/behaviors",
//     status: "Active",
//   },
// ];

const CaretagList = () => {
  const [selectedCaretag, setSelectedCaretag] = React.useState(null);
  const [caretagModalOpen, setCaretagModalOpen] = React.useState(false);

  const [statusFilter, setStatusFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [statuses, setStatuses] = React.useState([]);

  const [statusDropdown, setStatusDropdown] = React.useState(false);

  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");

  const [getCaretags] = useGetAllCaretagsMutation();

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const [caretags, setCaretags] = React.useState([]);

  const fetchCaretags = async () => {
    try {
      const { data } = await getCaretags().unwrap();

      setCaretags(data);

      const statuses = Array.from(
        new Set(data.map((caretag) => caretag.status))
      );

      setStatuses(statuses);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    const statuses = Array.from(
      new Set(caretags.map((caretag) => caretag.status))
    );

    setStatuses(statuses);
  }, [caretags]);

  React.useEffect(() => {
    fetchCaretags();
  }, []);

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Caretags{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {caretags.length}
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <div className="relative w-1/3 flex flex-row items-center gap-2 rounded-md border-[2px] border-primarygrayborder overflow-clip">
              <div className="pl-2">
                <img
                  src={Search_Icon}
                  alt="search"
                  className="w-5 h-5 cursor-pointer search-icon opacity-80"
                />
              </div>
              <input
                type="text"
                placeholder="Search..."
                className="w-full h-full p-2 text-base outline-none"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </div>
            <div className="w-1/3 relative flex flex-row divide-x-[2px] divide-primarygrayborder items-center gap-2 rounded-md border-[2px] border-primarygrayborder h-10">
              <div className="ml-2 min-w-fit">
                <button
                  onClick={() => setStatusDropdown(!statusDropdown)}
                  className={classNames(
                    "rounded-full w-7 h-7",
                    statusFilter.length > 0
                      ? "bg-primaryblue"
                      : "bg-primarygrayborder"
                  )}
                >
                  <FilterList
                    style={{
                      color: "#FFF",
                      fontSize: "20px",
                    }}
                  />
                </button>
              </div>
              <div className="flex flex-row items-center h-full gap-2 px-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
                {statusFilter.length === 0 && (
                  <p className="m-0 text-base text-primarygrayborder px-2 py-[2px] whitespace-nowrap">
                    Filter by Status ...
                  </p>
                )}
                {statusFilter.map((status, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primaryblue"
                  >
                    <p aria-multiline={false} className="m-0 whitespace-nowrap">
                      {status}
                    </p>
                    <button
                      onClick={() => handleStatusFilter(status)}
                      className="flex items-center justify-center scale-90 border border-white rounded-full aspect-square"
                    >
                      <CloseSvg color={"#FFF"} />
                    </button>
                  </div>
                ))}
              </div>
              {statusDropdown && Array.isArray(caretags) && (
                <div className="absolute z-50 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14 speciality-dropdown">
                  {statuses.map((status, index) => (
                    <div
                      className={classNames(
                        "hover:bg-primarylightgrey",
                        index !==
                          new Set(caretags.map((provider) => provider.status))
                            .size -
                            1 && "border-b"
                      )}
                    >
                      <CheckboxSelector
                        key={index}
                        header={status}
                        isChecked={statusFilter.indexOf(status) !== -1}
                        handleChange={(_e) => handleStatusFilter(status)}
                        isBold={false}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            setCaretagModalOpen(true);
          }}
          className="flex items-center justify-center px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue whitespace-nowrap"
        >
          Add Caretag
        </button>
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <ArrowDownwardTwoTone />
                      ) : sortNameOrder === "desc" ? (
                        <ArrowUpwardTwoTone />
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Description</p>
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Status</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortStatusOrder === "none") {
                          setSortStatusOrder("asc");
                        }
                        if (sortStatusOrder === "asc") {
                          setSortStatusOrder("desc");
                        }
                        if (sortStatusOrder === "desc") {
                          setSortStatusOrder("none");
                        }
                      }}
                    >
                      {sortStatusOrder === "asc" ? (
                        <ArrowDownwardTwoTone />
                      ) : sortStatusOrder === "desc" ? (
                        <ArrowUpwardTwoTone />
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">Edit</th>
              </tr>
            </thead>
            <tbody>
              {caretags
                .filter((caretag) =>
                  caretag.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
                )
                .filter(
                  (caretag) =>
                    statusFilter.length === 0 ||
                    statusFilter.indexOf(caretag.status) !== -1
                )
                .sort((a, b) => handleSortName(a, b))
                .sort((a, b) => handleSortStatus(a, b))
                .map((caretag, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== caretags.length - 1 ? "border-b" : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <div className="flex flex-row items-center gap-4">
                        <p className="m-0 font-semibold underline">
                          {caretag.name}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {caretag.description}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      <div
                        className={classNames(
                          caretag.status.toUpperCase() === "ACTIVE"
                            ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                            : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                          "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                        )}
                      >
                        <div
                          className={classNames(
                            "w-2 h-2 rounded-full",
                            caretag.status.toUpperCase() === "ACTIVE"
                              ? "bg-primarygreen"
                              : "bg-primarytextgrey"
                          )}
                        />
                        {caretag.status}
                      </div>
                    </td>
                    <td className="px-4 py-3 text-left">
                      <button
                        onClick={() => {
                          setSelectedCaretag(caretag);
                          setCaretagModalOpen(true);
                        }}
                        className="text-white rounded-md"
                      >
                        <IconComponent
                          icon="Edit_Icon"
                          i={caretag.id}
                          key={caretag.id}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <CaretagModal
        type={selectedCaretag ? "update" : "create"}
        open={caretagModalOpen}
        setOpen={setCaretagModalOpen}
        data={selectedCaretag}
        setData={setSelectedCaretag}
        caretags={caretags}
        setCaretags={setCaretags}
      />
    </div>
  );
};

export default CaretagList;
