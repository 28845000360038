import classNames from "classnames";
import React from "react";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import moment from "moment";
import ArrowUpRight from "../../../assets/svgs/ArrowUpRight";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { usePublishToAthenaMutation } from "../../../features/athena/athenaApiSlice";

const PublishAppointmentModal = ({
  publishOpen,
  setPublishOpen,
  startDate,
  setStartDate,
  startDateOpen,
  setStartDateOpen,
  endDate,
  setEndDate,
  endDateOpen,
  setEndDateOpen,
  weeks,
  setSuccessCard,
  selectedProvider,
  user,
}) => {
  const [publishToAthena] = usePublishToAthenaMutation();

  const onPublish = async () => {
    try {
      if (
        !user?.subscription?.status ||
        user?.subscription?.status !== "active"
      ) {
        alert(
          "You have not subscribed to the Athena integration plan, please subscribe if you want to publish to Athena"
        );
        return;
      }
      if (!user?.organisation?.practiceId) {
        alert("You don't have a practice id, please add it to publish");
        return;
      }
      if (!startDate || !endDate) {
        alert("You didn't selected any start date or end date");
        return;
      }
      await publishToAthena({
        ProviderID: selectedProvider.id,
        userId: user.id,
        startDate,
        endDate,
        practiceId: user?.organisation?.practiceId,
      }).unwrap();

      setPublishOpen(false);
      setSuccessCard(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ReusableModal open={publishOpen} hideHeader>
      <div
        className={classNames(
          "absolute top-0 left-0 flex items-center justify-center w-screen h-full transition-all",
          publishOpen ? "z-50 opacity-100" : "-z-10 opacity-0"
        )}
      >
        <div className="bg-white w-[600px] rounded-md shadow-md flex flex-col">
          <div className="flex flex-row items-center justify-between w-full p-4">
            <p className="m-0 text-2xl font-bold text-primaryblack">
              Publish Appointments
            </p>
            <button
              onClick={() => {
                setPublishOpen(false);
              }}
              className="flex items-center justify-center w-10 rounded-full aspect-square bg-primarylightgrey"
            >
              <CloseSvg color={"#3062D4"} />
            </button>
          </div>
          <div className="w-full border border-primarygrayborder" />
          <p className="px-4 pt-4 m-0 text-base text-left text-primarytextgrey">
            Please specify the date range for this provider's schedule that you
            would like to publish to Athena.
          </p>
          <div className="flex flex-col w-full gap-8 p-4">
            <div className="relative flex flex-col gap-3">
              <div className="flex flex-row items-center justify-between">
                <p className="m-0 text-xl font-semibold">Start Date</p>
              </div>
              <div className="flex flex-row items-center w-full px-2 py-1 border rounded-md border-primarygrayborder">
                <input
                  type="text"
                  className="w-full px-2 outline-none"
                  value={startDate}
                  placeholder={moment(new Date()).format("MMMM DD, YYYY")}
                />
                <button
                  className="rotate-[135deg] px-1 w-fit"
                  onClick={() => {
                    setStartDateOpen(!startDateOpen);
                  }}
                >
                  <ArrowUpRight color={"#000"} />
                </button>
              </div>
              <div
                className={classNames(
                  "absolute left-0 w-full bg-white border rounded-md top-20 border-primarygrayborder z-50",
                  startDateOpen
                    ? "max-h-44 overflow-x-auto opacity-100"
                    : "hidden h-0 overflow-hidden opacity-30"
                )}
              >
                {weeks.map((x, ind) => (
                  <button
                    key={ind}
                    onClick={() => {
                      setStartDate(moment(x.date).format("MMMM DD, YYYY"));
                      setStartDateOpen(false);
                    }}
                    className="flex flex-col items-start w-full transition-all hover:bg-slate-300"
                  >
                    <p className="p-2 m-0 text-base font-semibold cursor-pointer text-primaryblack">
                      {moment(x.date).format("MMMM DD, YYYY")}
                    </p>
                    {ind !== 6 && (
                      <div className="w-full border border-primarygrayborder" />
                    )}
                  </button>
                ))}
              </div>
            </div>
            <div className="relative flex flex-col gap-3">
              <div className="flex flex-row items-center justify-between">
                <p className="m-0 text-xl font-semibold">End Date</p>
              </div>
              <div className="flex flex-row items-center w-full px-2 py-1 border rounded-md border-primarygrayborder">
                <input
                  type="text"
                  className="w-full px-2 outline-none"
                  value={endDate}
                  placeholder={moment(new Date()).format("MMMM DD, YYYY")}
                />
                <button
                  className="rotate-[135deg] px-1 w-fit"
                  onClick={() => {
                    setEndDateOpen(!endDateOpen);
                  }}
                >
                  <ArrowUpRight color={"#000"} />
                </button>
              </div>
              <div
                className={classNames(
                  "absolute left-0 w-full bg-white border rounded-md top-20 border-primarygrayborder z-50",
                  endDateOpen
                    ? "max-h-44 overflow-x-auto opacity-100"
                    : "hidden h-0 overflow-hidden opacity-30"
                )}
              >
                {weeks.map((x, ind) => (
                  <button
                    key={ind}
                    onClick={() => {
                      setEndDate(moment(x.date).format("MMMM DD, YYYY"));
                      setEndDateOpen(false);
                    }}
                    className="flex flex-col items-start w-full transition-all hover:bg-slate-300"
                  >
                    <p className="p-2 m-0 text-base font-semibold cursor-pointer text-primaryblack">
                      {moment(x.date).format("MMMM DD, YYYY")}
                    </p>
                    {ind !== 6 && (
                      <div className="w-full border border-primarygrayborder" />
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row p-4">
            <div className="flex flex-row items-center justify-end w-full gap-2">
              <button
                onClick={() => {
                  setPublishOpen(false);
                }}
                className="flex flex-row items-center justify-center px-3 py-2 border-2 rounded-md shadow-sm w-fit border-primaryred"
              >
                <p className="m-0 text-sm font-bold text-primaryred">
                  No, don't publish to EHR
                </p>
              </button>
              <button
                onClick={onPublish}
                className="flex flex-row items-center justify-center px-3 py-2 border-2 rounded-md shadow-sm w-fit bg-primarygreen border-primarygreen"
              >
                <p className="m-0 text-sm font-bold text-white">
                  Yes, publish to EHR
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ReusableModal>
  );
};

export default PublishAppointmentModal;
