import React from "react";
import classNames from "classnames";
import { Search_Icon } from "../../../assets/images";
import {
  HouseOutlined,
  LocationOnOutlined,
  MonitorHeartOutlined,
  PeopleSharp,
} from "@mui/icons-material";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFetchPatientByIdMutation,
  useFetchPatientCareteamMutation,
  // useFetchPatientHouseholdMembersMutation,
} from "../../../features/patient/patientApiSlice";

const PatientInfo = () => {
  // const [patients, setPatients] = React.useState([]);
  const [careteam, setCareteam] = React.useState([]);
  const [patient, setPatient] = React.useState(null);

  const [searchFilter, setSearchFilter] = React.useState("");

  const [tab, setTab] = React.useState("care-team");

  const { id } = useParams();

  const navigate = useNavigate();

  const handlePatientClick = (patient) => {
    navigate(`/care-management/patients/${patient.id}`);
  };

  const handleProviderClick = (provider) => {
    navigate(`/care-management/providers/${provider.id}`);
  };

  const handleTabChange = (newTab) => {
    if (newTab !== tab) {
      setTab(newTab);
    }
  };

  // const [getPatients] = useFetchPatientHouseholdMembersMutation();
  const [getPatientCareteam] = useFetchPatientCareteamMutation();

  const [getPatientById] = useFetchPatientByIdMutation();

  // const fetchPatients = async () => {
  //   try {
  //     const { data } = await getPatients(patient.locationId).unwrap();

  //     setPatients(data);
  //   } catch (error) {
  //     console.log(error.response);
  //     console.error(error.data.message);
  //   }
  // };

  const fetchPatient = async () => {
    try {
      const { data } = await getPatientById(id).unwrap();

      setPatient(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  const fetchCareteam = async () => {
    try {
      const { data } = await getPatientCareteam(id).unwrap();

      setCareteam(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchPatient();
      fetchCareteam();
    }
  }, [id]);

  // React.useEffect(() => {
  //   if (patient && patient.noOfMembers > 0) {
  //     fetchPatients();
  //   }
  // }, [patient]);

  const Avatar = ({
    name,
    image,
    size = "w-10 h-10",
    textSize = "text-base",
    fontWeight = "font-normal",
  }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className={classNames(
                "object-cover w-10 h-10 rounded-full",
                size
              )}
            />
          </div>
        ) : (
          <p
            className={classNames(
              "flex items-center justify-center m-0 text-white rounded-full bg-primaryblue",
              size,
              textSize,
              fontWeight
            )}
          >
            {name
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-row items-start">
      <div className="w-4/12 py-4 pl-16 pr-8">
        <div className="w-full overflow-x-auto overflow-y-auto h-[calc(100vh-128px)] bg-white rounded-lg shadow border-1 border-primarygrayborder">
          <div className="flex flex-col items-center justify-end gap-2 p-4 border-b border-b-primarygrayborder">
            <div className="relative">
              <Avatar
                name={patient?.name ?? ""}
                image={null}
                size="w-20 h-20"
                textSize="text-3xl"
                fontWeight="font-semibold"
              />
              <div className="absolute right-0 w-4 h-4 rounded-full bottom-14 bg-primarygreen" />
            </div>
            {patient && (
              <p className="m-0 text-lg font-bold">{patient?.name}</p>
            )}
            <p className="m-0 text-sm text-primarytextgrey">
              EHR {patient?.patient_ehr_id} | {patient?.sex}
            </p>
            <p className="m-0 text-sm text-primarytextgrey">
              {patient?.locationName == null
                ? "Private Residence"
                : patient?.locationName}
            </p>
          </div>
          <div className="flex flex-col h-auto gap-4 p-4 border-b border-b-primarygrayborder">
            <div className="flex items-center gap-4">
              {patient && (
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <MonitorHeartOutlined
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
              )}
              {patient && (
                <p className="m-0">
                  {moment(patient?.dob).format("MMMM DD, YYYY")}
                </p>
              )}
            </div>
            {patient && patient?.noOfMembers > 0 && (
              <div className="flex items-center gap-4">
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <HouseOutlined
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
                <p className="m-0">{patient?.noOfMembers} members</p>
              </div>
            )}
            <div className="flex items-start gap-4">
              {patient && (
                <div className="flex items-center justify-center w-10 h-10 bg-transparent rounded-md">
                  <LocationOnOutlined
                    style={{
                      color: "#828282",
                    }}
                  />
                </div>
              )}
              <p className="m-0">{patient?.locationAddress}</p>
            </div>
          </div>
          <div className="flex flex-col h-auto gap-4 p-4">
            <button
              onClick={() => handleTabChange("care-team")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "care-team" && "bg-subtleblue"
                )}
              >
                <PeopleSharp
                  style={{
                    color: tab === "care-team" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "care-team" && "text-primaryblue"
                )}
              >
                Care Team
              </p>
            </button>
            {/* {patient?.noOfMembers > 0 && (
              <button
                onClick={() => handleTabChange("household")}
                className="flex items-center gap-4 cursor-pointer"
              >
                <div
                  className={classNames(
                    "flex items-center justify-center w-10 h-10 rounded-md",
                    tab === "household" && "bg-subtleblue"
                  )}
                >
                  <PeopleSharp
                    style={{
                      color: tab === "household" ? "#3062D4" : "#000",
                      fontSize: "20px",
                    }}
                  />
                </div>
                <p
                  className={classNames(
                    "m-0 font-semibold",
                    tab === "household" && "text-primaryblue"
                  )}
                >
                  Household Members ({patient?.noOfMembers})
                </p>
              </button>
            )} */}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start w-8/12 py-4">
        <div className="flex flex-row items-center justify-between w-full pl-8 pr-16">
          <div className="flex flex-row items-center w-full gap-12">
            <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
              {/* {tab === "care-team" ? "Care Team" : "Household Members"}{" "} */}

              <span className="ml-2 text-base font-bold text-primaryblue">
                {/* {tab === "care-team" ? careteam.length : patients.length} */}
                {careteam.length}
              </span>
            </h1>
            <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
              <div className="relative w-1/3 flex flex-row items-center gap-2 rounded-md border-[2px] border-primarygrayborder overflow-clip">
                <div className="pl-2">
                  <img
                    src={Search_Icon}
                    alt="search"
                    className="w-5 h-5 cursor-pointer search-icon opacity-80"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Search..."
                  className="w-full h-full p-2 text-base outline-none"
                  value={searchFilter}
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pl-8 pr-16">
          <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
            <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
              <thead>
                <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                  <th className="px-4 py-3">
                    <p className="m-0">Name</p>
                  </th>
                  <th className="px-4 py-3">
                    <p className="m-0">Speciality</p>
                  </th>
                  <th className="px-4 py-3">NPI ID</th>
                  <th className="px-4 py-3">Phone No.</th>
                  <th className="px-4 py-3">Email</th>
                  <th className="px-4 py-3">
                    <p className="m-0">Status</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {careteam
                  .filter((provider) =>
                    provider.name
                      .toLowerCase()
                      .includes(searchFilter.toLowerCase())
                  )
                  .map((provider, index) => (
                    <tr
                      className={classNames(
                        "text-left text-gray-900 h-24 whitespace-nowrap"
                      )}
                      key={index}
                    >
                      <td className="px-4 py-3 text-left">
                        <div className="flex flex-row items-center gap-4">
                          <button
                            onClick={() => handleProviderClick(provider)}
                            className="flex flex-row items-center gap-4"
                          >
                            <Avatar
                              name={provider.name}
                              image={provider.profileImage}
                            />
                            <p className="m-0 font-semibold underline">
                              {provider.name}
                            </p>
                          </button>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        {provider.speciality}
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        {provider.npi_id}
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        {provider.phone}
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        {provider.email}
                      </td>
                      <td className="px-4 py-3 text-left text-gray-700">
                        <div
                          className={classNames(
                            provider.status.toUpperCase() === "ACTIVE"
                              ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                              : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                            "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                          )}
                        >
                          <div
                            className={classNames(
                              "w-2 h-2 rounded-full",
                              provider.status.toUpperCase() === "ACTIVE"
                                ? "bg-primarygreen"
                                : "bg-primarytextgrey"
                            )}
                          />
                          {provider.status}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
