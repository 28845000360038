import React, { useState } from "react";
import CloseSvg from "../../../../../assets/svgs/CloseSvg";
import CommunityVisitRulesFilter from "../components/CommunityVisitRulesFilter";
import CommunityVisitRulesTable from "../components/CommunityVisitRulesTable";

const CommunityVisitRules = ({
  rules,
  handleCommunityVisitRulesModal,
  constraintToEdit,
  setConstraintToEdit,
  visitRules,
  setVisitRules,
  isWelcomePage,

}) => {
  const [nameFilter, setNameFilter] = useState("");
  const [frequencyFilter, setrequencyFilter] = useState([]);
  const [AvailabilityFilter, setAvailabilityFilter] = useState([]);

  const handleNameFilter = (name) => {
    setNameFilter(name);
  };

  const handleFrequencyFilter = (frequency) => {
    if (frequencyFilter.includes(frequency)) {
      setrequencyFilter(
        frequencyFilter.filter((filter) => filter !== frequency)
      );
    }
    if (!frequencyFilter.includes(frequency)) {
      setrequencyFilter([...frequencyFilter, frequency]);
    }
  };
  const handleAvailabilityFilter = (Availability) => {
    if (AvailabilityFilter.includes(Availability)) {
      setAvailabilityFilter(
        AvailabilityFilter.filter((filter) => filter !== Availability)
      );
    }

    if (!AvailabilityFilter.includes(Availability)) {
      setAvailabilityFilter([...AvailabilityFilter, Availability]);
    }
  };

  const handleEditClick = (constraint) => {
    if (constraintToEdit) {
      setConstraintToEdit(null);
    } else {
      setConstraintToEdit(constraint);
    }
  };

  const filterCommunityVisitRules = (rule) => {
    const nameMatch = rule.name
      .toLowerCase()
      .includes(nameFilter.toLowerCase());
    const frequencyMatch = frequencyFilter.includes(rule.frequency);

    let AvailabilityMatch = false;

    AvailabilityFilter.forEach((day) => {
      if (!AvailabilityMatch) {
        AvailabilityMatch = rule.visitDays.includes(day.toUpperCase());
      }
    });

    return (
      nameMatch &&
      (AvailabilityFilter.length === 0 || AvailabilityMatch) &&
      (frequencyFilter.length === 0 || frequencyMatch)
    );
  };

  return (
    <>
      <div className={`max-h-screen ${isWelcomePage ? "py-2" : 'p-4 '} overflow-hidden`}>
        <div className="flex flex-col w-full gap-2 mb-4">
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-lg font-bold">
              You have {Array.isArray(rules) ? rules.length : 0} Community Visit
              Rule{Array.isArray(rules) && rules.length > 1 && "s"}
            </p>
          </div>
          <CommunityVisitRulesFilter
            handleNameFilter={handleNameFilter}
            handleFrequencyFilter={handleFrequencyFilter}
            handleAvailabilityFilter={handleAvailabilityFilter}
            nameFilter={nameFilter}
            frequencyFilter={frequencyFilter}
            AvailabilityFilter={AvailabilityFilter}
          />
        </div>
        <CommunityVisitRulesTable
          rules={rules?.filter(filterCommunityVisitRules)}
          handleEditClick={handleEditClick}
          visitRules={visitRules}
          setVisitRules={setVisitRules}
          isWelcomePage={isWelcomePage}

        />
      </div>
    </>
  );
};

export default CommunityVisitRules;
