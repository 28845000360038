import React, { useEffect } from "react";
import { useGoogleUserMutation } from "../../features/auth/authApiSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import axios from "axios";

const GoogleCallback = () => {
  const location = useLocation();
  // const [googleUser] = useGoogleUserMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const init = async () => {
      // Parse the query string
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");
      // const { data } = await googleUser().unwrap();
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/auth/google/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = resp?.data?.data;
      dispatch(
        setCredentials({
          token: data.accessToken,
          user: data.user,
        })
      );
      if (data.hasCompletedOnboarding) {
        navigate("/home");
      } else {
        if (data?.user?.step >= 4) {
          navigate("/welcome");
        } else {
          const number = 3;
          const numberString = number.toString();
          const base64Encoded = btoa(numberString);
          navigate(`/signin?step=${base64Encoded}`);
        }
      }
    };
    init();
  }, []);
  return null;
};

export default GoogleCallback;
