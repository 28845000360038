import React, { useState } from "react";
import ProvidersScreen from "../../Schedules/Configure/ProvidersConfig/ProvidersScreen";

const ProviderParameterRight = ({ providers, setProviders }) => {
  const [opaque, setOpaque] = useState(false);

  return (
    <div className="w-full py-1 ">
      
      <ProvidersScreen
        setOpaque={setOpaque}
        providers={providers}
        setProviders={setProviders}
        isGlobalShow={false}
        isWelComePage={true}
      />
    </div>
  );
};

export default ProviderParameterRight;
