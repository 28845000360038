import moment from "moment/moment";
import { toast } from "react-toastify";

export const avatarName = (name) => {
  if (!name) return "";
  const splits = name.split(" ");
  const tmpName =
    splits.length > 1 ? splits.map((i) => i.slice(0, 1)).join("") : name;
  return tmpName?.slice(0, 2)?.toUpperCase();
};

export const convertToMb = (size) => {
  return (size / (1024 * 1024)).toFixed(2) + "MB";
};

export const queryString = (obj) => {
  return Object.keys(obj)
    .map((i) => {
      return obj?.[i] ? `${i}=${obj[i]}` : undefined;
    })
    .filter(Boolean)
    .join("&");
};

export function numberToPhone(number) {
  if (number) {
    return number
      .replace(/\D+/g, "")
      .replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "($1) $2-$3"); //mask numbers (xxx) xxx-xxxx
  } else {
    return "";
  }
}

export function convertToNumber(value) {
  const ssn = value.replace(/[^\d]/g, "");
  return ssn;
}

export const customToast = (message, type) => {
  const toastType = type ? toast[type] : toast;
  toastType(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export function capitalizeFirstWords(str) {
  return (
    str
      ?.split(" ")
      ?.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      ?.join(" ") || ""
  );
}

export const getNextWeekDates = (date) => {
  // Get the current date
  const currentDate = new Date(date);

  // Set the current date to the beginning of the next week
  currentDate.setDate(currentDate.getDate() + (8 - currentDate.getDay()));

  // Calculate the start date of the next week
  const nextWeekStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  // Calculate the end date of the next week
  const nextWeekEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 6
  );

  // Format the dates as strings (assuming you want them in the format "YYYY-MM-DD")
  const formattedNextWeekStartDate = nextWeekStartDate
    .toISOString()
    .slice(0, 10);
  const formattedNextWeekEndDate = nextWeekEndDate.toISOString().slice(0, 10);

  // Output the start and end dates of the next week
  console.log("Next week start date:", formattedNextWeekStartDate);
  console.log("Next week end date:", formattedNextWeekEndDate);

  return {
    startDate: formattedNextWeekStartDate,
    endDate: formattedNextWeekEndDate,
  };
};

export function createImageWithAlphabets(
  firstAlphabet,
  secondAlphabet,
  color,
  textColor,
  isBold
) {
  const num = firstAlphabet?.length;
  const canvas = document.createElement("canvas");
  const size = num > 2 ? 400 : 300; // Adjust the size as needed
  canvas.width = size; // Adjust the width as needed
  canvas.height = size; // Adjust the height as needed

  const ctx = canvas.getContext("2d");
  const centerX = size / 2;
  const centerY = size / 2;
  const radius = size / 2;
  ctx.fillStyle = color || "#607D8B"; // Set the background color
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.font = `${isBold ? 700 : ""} 100px Inter`; // Adjust the font size and style
  ctx.fillStyle = textColor || "black"; // Set the text color

  if (secondAlphabet) {
    // Draw the first alphabet
    ctx.fillText(firstAlphabet, centerX - 70, centerY + 40); // Adjust the coordinates as needed

    // Draw the second alphabet
    ctx.fillText(secondAlphabet, centerX + 10, centerY + 40); // Adjust the coordinates as needed
  } else {
    if (num > 3) {
      // Draw the first Number
      ctx.fillText(firstAlphabet, centerX - 120, centerY + 40); // Adjust the coordinates as needed
    } else if (num > 1) {
      ctx.fillText(firstAlphabet, centerX - 70, centerY + 40); // Adjust the coordinates as needed
    } else {
      // Draw the first Number
      ctx.fillText(firstAlphabet, centerX - 30, centerY + 40); // Adjust the coordinates as needed
    }
  }

  // Add border
  ctx.strokeStyle = "black"; // Set the border color
  ctx.lineWidth = 1; // Set the border width

  ctx.beginPath();
  ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
  ctx.closePath();
  ctx.stroke();

  return canvas.toDataURL();
}

export const groupedVisits = (isProvider, visits) =>
  isProvider
    ? Object.values(
        visits.reduce((result, item) => {
          const latlngkey = `${item.PatientLatitude}-${item.PatientLongitude}`;
          const key = `${item.Facility}`;

          if (!result[key] || (key === "Private Home" && !result[latlngkey])) {
            const field = {
              PatientLatitude: item.PatientLatitude,
              PatientLongitude: item.PatientLongitude,
              Facility: item.Facility,
              data: [],
            };
            if (key === "Private Home") {
              if (!result[latlngkey]) {
                result[latlngkey] = field;
              }
            } else {
              result[key] = field;
            }
          }

          if (key === "Private Home") {
            result[latlngkey]?.data?.push(item);
            if (!result[latlngkey].PatientLatitude) {
              result[latlngkey].PatientLatitude = item.PatientLatitude;
              result[latlngkey].PatientLongitude = item.PatientLongitude;
            }
          } else {
            result[key]?.data?.push(item);
            if (!result[key].PatientLatitude) {
              result[key].PatientLatitude = item.PatientLatitude;
              result[key].PatientLongitude = item.PatientLongitude;
            }
          }
          return result;
        }, {})
      )
    : [];

export const parseAt = ({ message, regexAt }) => {
  const matchesAt = [...message.matchAll(regexAt)];

  const resultAt = matchesAt.map((match) => ({
    userId: parseInt(match[2]),
    name: match[1],
  }));
  return resultAt;
};

export const parseRef = ({ message, regexRef }) => {
  const matchesRef = [...message.matchAll(regexRef)];
  const resultRef = matchesRef.map((match) => ({
    patientId: String(match[2]),
    name: match[1],
  }));
  return resultRef;
};

export const parseHash = ({ message, regexHash }) => {
  const matchesHash = [...message.matchAll(regexHash)];

  const resultHash = matchesHash.map((match) => ({
    id: parseInt(match[2]),
    name: match[1],
  }));
  return resultHash;
};

// Function to get the start and end indices of a substring in a string
export const getIndices = (str, substr) => {
  const startIndex = str.indexOf(substr);
  const endIndex = startIndex + substr.length;
  return { startIndex, endIndex };
};

export const regexAt = /@\[(.*?)\]\((\d+)\)/g;
export const regexRef = /<\[(.*?)\]\((\d+)\)/g;
export const regexHash = /#\[(.*?)\]\((\d+)\)/g;

export const parseMessage = ({ message }) => {
  const resultAt = parseAt({ message, regexAt });

  const resultRef = parseRef({ message, regexRef });

  const resultHash = parseHash({ message, regexHash });

  const replaceAt = message.replaceAll(regexAt, "@$1");

  const replaceRef = replaceAt.replaceAll(regexRef, "<$1>");

  const replaceHash = replaceRef.replaceAll(regexHash, "$1");

  // Get start and end indices for each format
  const taggedMembers = resultAt.map((obj) => ({
    ...obj,
    ...getIndices(replaceHash, `@${obj.name}`),
  }));
  const taggedPatients = resultRef.map((obj) => ({
    ...obj,
    ...getIndices(replaceHash, `<${obj.name}>`),
  }));
  const currentHashTags = resultHash.map((obj) => ({
    ...obj,
    ...getIndices(replaceHash, `${obj.name}`),
  }));
  return { taggedMembers, taggedPatients, currentHashTags, replaceHash };
};

export function calculateAge(birthdate) {
  const today = new Date();
  const birthdateObj = new Date(birthdate);
  let age = today.getFullYear() - birthdateObj.getFullYear();
  const monthDiff = today.getMonth() - birthdateObj.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthdateObj.getDate())
  ) {
    age--;
  }
  return age;
}

export const onDownload = (url, filename) => {
  if (!url) {
    console.error("URL is not available.");
    return;
  }

  // Create a new anchor element
  const anchor = document.createElement("a");

  // Set the href attribute to the URL
  anchor.href = url;

  // Add an attribute to set the download file name (optional)
  // You can change 'downloaded_data.txt' to the desired filename
  anchor.setAttribute("download", filename);

  // Trigger a click event on the anchor element
  // This will start the download process
  anchor.click();
};

export const isWithin24Hours = (expiryTime) => {
  const currentTime = moment();
  const parsedExpiryTime = moment(expiryTime);

  // Calculate the duration between the two times
  const duration = moment.duration(parsedExpiryTime.diff(currentTime));

  // Check if the duration is less than or equal to 24 hours
  return duration.asHours() <= 24;
};

export const convertMentionsMessage = ({
  message,
  patients,
  caretags,
  users,
}) => {
  const convertText = (inputText, replacements) => {
    replacements.forEach(([pattern, replacement]) => {
      // Extract words matching the pattern
      const matches = inputText.match(pattern);
      matches?.forEach((match) => {
        const isCareTag = match?.startsWith("#");
        const isPatient = match?.startsWith("<");
        const isMention = match?.startsWith("@");
        if (isCareTag) {
          const careTag = replacement.find((f) => f.display === match);
          if (!careTag) return;
          inputText = inputText.replace(match, `#[${match}](${careTag?.id})`);
        }
        if (isPatient) {
          const patient = replacement.find((f) =>
            match?.toLowerCase()?.includes(f.name?.toLowerCase())
          );
          if (!patient) return;
          inputText = inputText.replace(
            match,
            `<[${match.replace(/<|>/g, "")}](${patient?.id})`
          );
        }
        if (isMention) {
          const user = replacement.find(
            (f) =>
              f.display.toLowerCase() === match?.replace(/@/g, "").toLowerCase()
          );
          if (!user) return;
          inputText = inputText.replace(
            match,
            `@[${match.replace(/@/g, "")}](${user?.id})`
          );
        }
      });
    });

    return inputText;
  };
  const dynamicReplacements = [
    [/#(\w+)/g, caretags],
    [/@(\w+\s\w+)/g, users],
    [/<(.+?)>/g, patients],
  ];
  const convertedText = convertText(message, dynamicReplacements);
  return convertedText;
};

// Function to extract unique objects by column_name
export const extractUniqueObjects = (data) => {
  const map = new Map();
  data.forEach((item) => {
    if (!map.has(item.column_name || item.current_column)) {
      if (item.row_index) {
        delete item.row_index;
      }
      map.set(item.column_name || item.current_column, item);
    }
  });
  return Array.from(map.values());
};
