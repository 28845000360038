import React, { useEffect, useState } from "react";
import ChatSvg from "../../../assets/svgs/Chat";
import InfoSvg from "../../../assets/svgs/InfoSvg";
import LocationSvg from "../../../assets/svgs/LocationSvg";
import UserSvg from "../../../assets/svgs/user";
import HealthSvg from "../../../assets/svgs/HealthSvg";
import CalendarCheckSvg from "../../../assets/svgs/CalendarCheckSvg";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import classNames from "classnames";
import { useLSStore } from "../../../context/ls-store";
import { SCHEDULE_BASE_URL } from "../../../constants/endpoints";
import axios from "axios";
import { capitalize, status } from "../data";
import Lock from "../../../assets/svgs/Lock";
import Unlock from "../../../assets/svgs/Unlock";
import CheckSvg from "../../../assets/svgs/CheckSvg";
import moment from "moment";
import {
  useCancelAppointmentMutation,
  useLockAppointmentMutation,
} from "../../../features/appointment/appointmentApiSlice";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { CancelSharp } from "@mui/icons-material";
import AppointmentCancelModal from "./AppointmentCancelModal";
import AppointmentLockModal from "./AppointmentLockModal";

const AppointmentSelectModal = ({ selectedProvider, setRecallSchedule }) => {
  const selectedAppointment = useLSStore((state) => state.selectedAppointment);
  const setSelectedAppointment = useLSStore(
    (state) => state.setSelectedAppointment
  );

  const [cancelAppointment] = useCancelAppointmentMutation();
  const [lockAppointment] = useLockAppointmentMutation();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openLockModal, setOpenLockModal] = useState(false);

  const handleCancel = async () => {
    try {
      await cancelAppointment({
        appointmentId: selectedAppointment.appointmentId,
      }).unwrap();

      console.log(`Appointment ${selectedAppointment.appointmentId} cancelled`);

      setRecallSchedule(true);
    } catch (error) {
      console.log(error);
    }

    setSelectedAppointment(null);
  };

  const handleLock = async () => {
    try {
      await lockAppointment({
        appointmentId: selectedAppointment.appointmentId,
      }).unwrap();

      console.log(`Appointment ${selectedAppointment.appointmentId} locked`);

      setRecallSchedule(true);

      setSelectedAppointment(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelModal = () => {
    setOpenCancelModal(!openCancelModal);
  };

  const handleLockModal = () => {
    setOpenLockModal(!openLockModal);
  };

  return (
    <>
      {/* <ReusableModal open={selectedAppointment} hideHeader> */}
      <div
        className={classNames(
          "absolute top-[0px] overflow-scroll transition-all min-h-svh bg-white w-96 border-l border-t border-b border-secondarygrayborder right-0 opacity-100",
          selectedAppointment ? "right-0" : "right-[-400px]"
        )}
      >
        <div className="flex flex-row items-center justify-between w-full p-4">
          <p className="m-0 text-2xl font-bold text-primaryblack">
            Appointment Details
          </p>
          <button
            onClick={() => {
              setSelectedAppointment(null);
            }}
            className="flex items-center justify-center w-10 rounded-full aspect-square bg-primarylightgrey"
          >
            <CloseSvg color={"#3062D4"} />
          </button>
        </div>
        <div className="w-full border border-primarygrayborder" />
        <div className="flex flex-col w-full gap-12 p-4">
          <div className="flex flex-row items-center justify-start gap-4">
            <CalendarCheckSvg color={"#3062D4"} />
            {selectedAppointment !== null ? (
              <p className="m-0 text-base text-primaryblack w-fit">
                Scheduled on{" "}
                {moment(selectedAppointment.visitDate).format("ll")}
              </p>
            ) : (
              <div className="relative w-64 h-6 overflow-hidden border rounded-md border-primarylightgrey bg-primarylightgrey animate-pulse">
                <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
              </div>
            )}
          </div>
          <div className="flex flex-row items-center justify-start gap-4">
            <LocationSvg color={"#3062D4"} />
            <div className="flex flex-col w-fit">
              {selectedAppointment !== null ? (
                <>
                  <p className="m-0 text-base text-primaryblack w-fit">
                    {selectedAppointment.location.name}
                  </p>
                  <p className="m-0 text-base text-primarytextgrey w-fit">
                    {selectedAppointment.location.address}
                  </p>
                </>
              ) : (
                <>
                  <div className="relative h-6 mb-1 overflow-hidden border rounded-md w-52 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                  <div className="relative h-6 overflow-hidden border rounded-md w-72 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-4">
            <UserSvg color={"#3062D4"} />
            <div className="flex flex-col w-fit">
              {selectedAppointment !== null ? (
                <>
                  <p className="m-0 text-base text-primaryblack w-fit">
                    {selectedAppointment.patient.name} | ID{" "}
                    {selectedAppointment.patient.idInEhr}
                  </p>
                  <p className="m-0 text-base text-primarytextgrey w-fit">
                    Last seen:{" "}
                    {selectedAppointment.lastSeenOn === "NA"
                      ? selectedAppointment.lastSeenOn
                      : moment(selectedAppointment.lastSeenOn).format("ll")}
                  </p>
                </>
              ) : (
                <>
                  <div className="relative h-6 mb-1 overflow-hidden border rounded-md w-44 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                  <div className="relative h-6 overflow-hidden border rounded-md w-52 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-4">
            <HealthSvg color={"#3062D4"} />
            <div className="flex flex-col w-fit">
              {selectedProvider !== null ? (
                <>
                  <p className="m-0 text-base text-primaryblack w-fit">
                    Dr. {selectedProvider.name}
                  </p>
                  <p className="m-0 text-base text-primarytextgrey w-fit">
                    {selectedProvider.speciality}
                  </p>
                </>
              ) : (
                <>
                  <div className="relative h-6 mb-1 overflow-hidden border rounded-md w-36 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                  <div className="relative h-6 overflow-hidden border rounded-md w-28 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-4">
            <InfoSvg color={"#3062D4"} />
            {selectedAppointment !== null ? (
              <p className="m-0 text-base text-primaryblack w-fit">
                {selectedAppointment.appointmentType.name}
              </p>
            ) : (
              <div className="relative h-6 mb-1 overflow-hidden border rounded-md w-36 border-primarylightgrey bg-primarylightgrey animate-pulse">
                <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
              </div>
            )}
          </div>
        </div>
        <div className="w-full border border-primarygrayborder" />
        <div className="flex flex-col w-full gap-6 p-4">
          <div className="flex flex-row items-center justify-start gap-4">
            <ChatSvg color={"#3062D4"} />
            <p className="m-0 text-base font-bold text-primaryblack w-fit">
              Appointment Notes
            </p>
          </div>
          {selectedProvider !== null ? (
            <div className="flex flex-col w-full">
              <p className="m-0 text-base">
                <span className="font-bold">Dr. {selectedProvider.name}</span>{" "}
                Check on small wound during next visit.
              </p>
              <p className="m-0 text-xs italic text-primarytextgrey">
                9:37am 8/25/23
              </p>
            </div>
          ) : (
            <div className="relative w-full h-16 mb-1 overflow-hidden border rounded-md border-primarylightgrey bg-primarylightgrey animate-pulse">
              <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
            </div>
          )}
          <input
            type="text"
            placeholder="Add a note for this appointment..."
            className="w-full px-4 py-2 rounded-md border-1 placeholder:text-primarytextgrey border-primarygrayborder"
          />
        </div>
        <div className="w-full border border-primarygrayborder" />
        <div className="flex flex-col w-full gap-4 p-4 mb-4">
          <div className="w-full">
            {selectedAppointment !== null ? (
              <button
                className={classNames(
                  "flex flex-row items-center justify-center w-full gap-2 px-3 py-2 rounded-md shadow-sm",
                  selectedAppointment.status === status.published
                    ? "bg-primarygreen"
                    : selectedAppointment.status === status.unlocked
                    ? "bg-primaryyellow"
                    : "bg-primaryblue"
                )}
                disabled={selectedAppointment.status === status.published}
                onClick={handleLockModal}
              >
                {selectedAppointment.status === status.published ? (
                  <div className="w-[18px] h-[18px] bg-white rounded-full">
                    <CheckSvg color={"#6FCF97"} />
                  </div>
                ) : selectedAppointment.status === status.unlocked ? (
                  <Unlock color={"#FFF"} />
                ) : (
                  <Lock color={"#FFF"} />
                )}
                <p className="m-0 text-base text-white">
                  {selectedAppointment.status === status.published
                    ? "Published"
                    : selectedAppointment.status === status.unlocked
                    ? "Unlocked"
                    : "Locked"}
                </p>
              </button>
            ) : (
              <div className="relative w-full h-10 overflow-hidden border rounded-md border-primarylightgrey bg-primarylightgrey animate-pulse">
                <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
              </div>
            )}
          </div>
          <div className="w-full">
            <button
              onClick={handleCancelModal}
              className="flex flex-row items-center justify-center w-full px-3 py-2 border rounded-md shadow-sm border-primarygrayborder"
            >
              <p className="m-0 text-base text-primaryred">
                Cancel Appointment
              </p>
            </button>
          </div>
        </div>
      </div>
      <AppointmentCancelModal
        open={openCancelModal}
        handleCancelModal={handleCancelModal}
        handleCancel={handleCancel}
      />
      <AppointmentLockModal
        open={openLockModal}
        handleLockModal={handleLockModal}
        handleLock={handleLock}
        status={selectedAppointment?.status}
      />
    </>
  );
};

export default AppointmentSelectModal;
