import React from "react";

const ProviderUser = ({ user }) => {
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-12">
      <div className="flex items-center justify-center w-24 h-24 text-white bg-gray-500 rounded-full">
        <h1 className="m-0 text-4xl">
          {user.details.name.split(" ")[0][0]}
          {user.details.name.split(" ")[1][0]}
        </h1>
      </div>
      <div className="flex flex-row gap-6">
        <div className="flex flex-col gap-8 font-bold">
          <p className="m-0">Name:</p>
          <p className="m-0">Email:</p>
          <p className="m-0">Phone:</p>
          <p className="m-0">Speciality:</p>
          <p className="m-0">Address:</p>
        </div>
        <div className="flex flex-col gap-8 text-primarytextgrey">
          <p className="m-0">{user.details.name}</p>
          <p className="m-0">{user.email}</p>
          <p className="m-0">{formatPhoneNumber(user.phone)}</p>
          <p className="m-0">{user.details.speciality}</p>
          <p className="m-0">{user.details.location.join(", ")}</p>
        </div>
      </div>
      <div className="w-full h-[1px] bg-primarygrayborder" />
      <div className="flex flex-row gap-6">
        <div className="flex flex-col gap-8 font-bold">
          <p className="m-0">NPI Id:</p>
          <p className="m-0">EHR Id:</p>
          <p className="m-0">EHR Name:</p>
        </div>
        <div className="flex flex-col gap-8 text-primarytextgrey">
          <p className="m-0">{user.details.npi_id}</p>
          <p className="m-0">{user.details.provider_ehr_id}</p>
          <p className="m-0">{user.details.ehr.name}</p>
        </div>
      </div>
      <div className="w-full h-[1px] bg-primarygrayborder" />
      <div className="flex flex-row gap-6">
        <div className="flex flex-col gap-8 font-bold">
          <p className="m-0">Organization:</p>
        </div>
        <div className="flex flex-col gap-8 text-primarytextgrey">
          <p className="m-0">{user.organisation.name}</p>
        </div>
      </div>
    </div>
  );
};

export default ProviderUser;
