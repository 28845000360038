export const status = {
  published: "PUBLISHED",
  locked: "LOCKED",
  unlocked: "UNLOCKED",
};

export const appointmentStatuses = [
  "All",
  status.published,
  status.locked,
  status.unlocked,
];

export const locationTypes = ["All", "Community", "Private Home"];

export function capitalize(string) {
  const str_arr = string.split(" ");

  for (let i = 0; i < str_arr.length; i++) {
    str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1);
  }
  return str_arr.join(" ");
}
