import React, { useState, useEffect } from "react";
import UploadCareplan from "./UploadCareplan";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import ProviderParameter from "./ProviderParameter";
import AppointmentType from "./AppointmentType";
import Scheduler from "./Scheduler";
import SchedulingRules from "./SchedulingRules";
import { useSchedulerStore } from "../../context/scheduler.store";
import { useGetProvidersMutation } from "../../features/organisation/organisationApiSlice";
import { useFetchAppointmentTypesMutation } from "../../features/appointment/appointmentApiSlice";

const Welcome = () => {
  const user = useSelector(selectUser);
  const [currentStep, setCurrentStep] = useState(5);
  const providers = useSchedulerStore((state) => state.providers);
  const setProviders = useSchedulerStore((state) => state.setProviders);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [getProviders] = useGetProvidersMutation();
  const [getAppointmentTypes] = useFetchAppointmentTypesMutation();
  const [isFileUpload, setIsFileUpload] = React.useState(false)

  useEffect(() => {
    fetchProviders();
    fetchAppointmentTypes();
    setCurrentStep(user.step <= 4 ? 5 : user.step + 1);
  }, []);
  const fetchProviders = async () => {
    try {
      const { data } = await getProviders(user.organisation.id).unwrap();
      setProviders(data);
    } catch (error) {
      console.log(error.response);
      console.error(error?.data?.message);
    }
  };
  const fetchAppointmentTypes = async () => {
    try {
      const { data } = await getAppointmentTypes(user.organisation.id);
      setAppointmentTypes(data.data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };
  const handleStepChange = (index) => {
    setCurrentStep(index);
  };

  useEffect(() => {
    if (currentStep === 6) {
      fetchProviders();
    }
    if (currentStep === 8) {
      fetchAppointmentTypes();
    }
  }, [currentStep]);

  return (
    <div className="flex flex-col h-screen  overflow-x-clip no-scrollbar relative">
      <div className="relative w-full">
        <div
          className={classNames(
            "absolute top-0 z-50 w-full transition-all ease-in-out h-screen overflow-auto no-scrollbar",
            currentStep === 5 && "",
            currentStep > 5 && "-translate-x-full right-28",
            currentStep < 5 && "translate-x-full left-28"
          )}
        >
          <UploadCareplan
            handleStepChange={handleStepChange}
            step={currentStep}
            setIsFileUpload={setIsFileUpload}
          />
        </div>

        <div
          className={classNames(
            "absolute top-0 z-50 w-full transition-all ease-in-out h-screen overflow-auto no-scrollbar",
            currentStep === 6 && "",
            currentStep > 6 && "-translate-x-full right-28",
            currentStep < 6 && "translate-x-full left-28"
          )}
        >
          <ProviderParameter
            handleStepChange={handleStepChange}
            step={currentStep}
            providers={providers}
            setProviders={setProviders}
          />
        </div>
        <div
          className={classNames(
            "absolute top-0 z-50 w-full transition-all ease-in-out h-screen overflow-auto no-scrollbar",
            currentStep === 7 && "",
            currentStep > 7 && "-translate-x-full right-28",
            currentStep < 7 && "translate-x-full left-28"
          )}
        >
          <SchedulingRules
            handleStepChange={handleStepChange}
            step={currentStep}
            isFileUpload={isFileUpload}
          />
        </div>
        <div
          className={classNames(
            "absolute top-0 z-50 w-full transition-all ease-in-out h-screen overflow-auto no-scrollbar ",
            currentStep === 8 && "",
            currentStep > 8 && "-translate-x-full right-28",
            currentStep < 8 && "translate-x-full left-28"
          )}
        >
          <AppointmentType
            handleStepChange={handleStepChange}
            step={currentStep}
            providers={providers}
            setProviders={setProviders}
            appointmentTypes={appointmentTypes}
            setAppointmentTypes={setAppointmentTypes}
          />
        </div>
        <div
          className={classNames(
            "absolute top-0 z-50 w-full transition-all ease-in-out h-screen overflow-auto no-scrollbar",
            currentStep === 9 && "",
            currentStep > 9 && "-translate-x-full right-28",
            currentStep < 9 && "translate-x-full left-28"
          )}
        >
          <Scheduler handleStepChange={handleStepChange} step={currentStep} />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
