import classNames from "classnames";
import React, { useState } from "react";
import { useLSStore } from "../../../context/ls-store";
import {
  Add_Icon,
  Edit_Icon,
  Forward_Icon,
  Sort_Icon,
  Users_Icon,
} from "../../../assets/images";
import CalendarSvg from "../../../assets/svgs/CalendarSvg";
import CheckSvg from "../../../assets/svgs/CheckSvg";
import { capitalize } from "../data";
import ArrowUpRight from "../../../assets/svgs/ArrowUpRight";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import {
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import MapSvg from "../../../assets/svgs/MapSvg";

const AppointmentOptions = ({
  filterOpen,
  setFilterOpen,
  providers,
  loaderVisible,
  selectedProvider,
  addOpen,
  setAddOpen,
  setSelectedProvider,
  filtersOn,
  publishOpen,
  setPublishOpen,
  weeks,
  enableFilter,
  weeksList,
  setOpenMap,
}) => {
  const [providerDropdown, setProviderDropdown] = useState(false);

  const defaultWeekIndx = useLSStore((state) => state.defaultWeekIndx);
  const selectedAppointment = useLSStore((state) => state.selectedAppointment);
  const editable = useLSStore((state) => state.editable);

  const setEditable = useLSStore((state) => state.setEditable);
  const setDefaultWeekIndx = useLSStore((state) => state.setDefaultWeekIndx);

  const user = useSelector(selectUser);

  const hidePublishToAthena =
    !user?.subscription?.status || user?.subscription?.status !== "active";

  return (
    <div
      className={classNames(
        "flex flex-row items-stretch w-full gap-16 px-6 py-4",
        (selectedAppointment !== null || filterOpen === true) && "opacity-40"
      )}
    >
      <div className="flex flex-row w-3/5 gap-8">
        {selectedProvider !== null ? (
          <button
            disabled={
              selectedAppointment !== null ||
              filterOpen === true ||
              loaderVisible ||
              addOpen === true ||
              publishOpen === true
            }
            onClick={() => {
              if (user.type === 1) setProviderDropdown(!providerDropdown);
            }}
            className="relative flex flex-row items-center justify-center w-6/12 gap-2 px-3 py-2 border-2 rounded-md bg-primaryblue border-primaryblue disabled:opacity-40"
          >
            {user.type === 1 && (
              <img
                src={Users_Icon}
                alt="Filter"
                className="object-contain w-5 h-5"
              />
            )}
            <p className="m-0 text-base font-bold text-white">
              {selectedProvider.name}
            </p>
            {user.type === 1 && (
              <button className="rotate-[135deg] w-fit">
                <ArrowUpRight color={"#FFF"} />
              </button>
            )}
            <div
              className={classNames(
                "absolute left-0 z-30 w-full overflow-x-auto bg-white border-2 rounded-md top-16 border-secondarygrayborder transition-all",
                providerDropdown
                  ? "flex flex-col max-h-56 opacity-100"
                  : "hidden h-0 overflow-hidden opacity-30"
              )}
            >
              {providers.map((x, indx) => (
                <div
                  key={x.id}
                  onClick={() => {
                    setSelectedProvider(x);
                  }}
                  className={classNames(
                    "w-full transition-all hover:bg-slate-300 cursor-pointer",
                    selectedProvider.id === x.id && "bg-slate-300"
                  )}
                >
                  <p className="p-2 m-0 text-base font-semibold">{x.name}</p>
                  {indx !== providers.length - 1 && (
                    <div className="w-full border border-primarygrayborder" />
                  )}
                </div>
              ))}
            </div>
          </button>
        ) : (
          <div
            disabled={
              selectedAppointment !== null ||
              filterOpen === true ||
              addOpen === true ||
              publishOpen === true
            }
            onClick={() => {
              setProviderDropdown(!providerDropdown);
            }}
            className="relative flex flex-row items-center justify-center w-6/12 gap-2 px-3 py-2 border-2 rounded-md h-14 border-primaryblue bg-primaryblue opacity-40"
          />
        )}
        {/* <button
          disabled={
            selectedAppointment !== null ||
            filterOpen === true ||
            loaderVisible === true ||
            addOpen === true ||
            publishOpen === true
          }
          className="flex flex-row items-center justify-center w-4/12 gap-2 px-3 py-2 bg-white border-2 rounded-md border-secondarygrayborder disabled:opacity-40"
        >
          <CalendarSvg className="object-contain w-5 h-5 fill-black" />
          <p className="m-0 text-base font-bold text-black">
            {weeks.filter(
              (x) => x.date === moment(new Date()).format("YYYY-MM-DD")
            ).length === 0
              ? `${moment(new Date(weeks[0].date)).format("MMM DD")} - ${moment(
                  new Date(weeks[6].date)
                ).format("MMM DD")}`
              : "This Week"}
          </p>
        </button> */}
        <div className="flex items-center w-4/12">
          <select
            disabled={
              selectedAppointment !== null ||
              filterOpen === true ||
              loaderVisible === true ||
              addOpen === true ||
              publishOpen === true
            }
            className="block w-full px-2 py-2 mt-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={defaultWeekIndx}
            onChange={(e) => setDefaultWeekIndx(e.target.value)}
          >
            {weeksList?.map((week) => (
              <option key={week.id} value={week.id}>
                {week.id === 0 ? "This week" : week.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-row w-2/12 gap-4">
          <button
            disabled={
              selectedAppointment !== null ||
              filterOpen === true ||
              defaultWeekIndx === 0 ||
              loaderVisible === true ||
              addOpen === true ||
              publishOpen === true
            }
            onClick={() => {
              setDefaultWeekIndx(defaultWeekIndx - 1);
            }}
            className="relative flex items-center justify-center rounded-md group w-14 aspect-square bg-primarylightgrey disabled:opacity-40"
          >
            <img
              src={Forward_Icon}
              alt="Filter"
              className="object-contain w-5 h-5 rotate-180"
            />
            <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
              Previous Week
            </p>
          </button>
          <button
            disabled={
              selectedAppointment !== null ||
              filterOpen === true ||
              defaultWeekIndx === 51 ||
              loaderVisible === true ||
              addOpen === true ||
              publishOpen === true
            }
            onClick={() => {
              setDefaultWeekIndx(defaultWeekIndx + 1);
            }}
            className="relative flex items-center justify-center rounded-md group w-14 aspect-square bg-primarylightgrey disabled:opacity-40"
          >
            <img
              src={Forward_Icon}
              alt="Filter"
              className="object-contain w-5 h-5"
            />
            <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
              Next Week
            </p>
          </button>
        </div>
      </div>
      <div className="flex flex-row w-2/5 gap-8">
        <div className="flex flex-row items-center w-1/2 gap-4">
          <button
            disabled={
              selectedAppointment !== null ||
              filterOpen === true ||
              loaderVisible === true ||
              addOpen === true ||
              publishOpen === true ||
              !enableFilter
            }
            onClick={() => {
              setOpenMap(true);
            }}
            className="relative flex items-center justify-center rounded-md group w-14 aspect-square bg-primarylightgrey disabled:opacity-40"
          >
            {/* <img
              src={Sort_Icon}
              alt="Filter"
              className="object-contain w-5 h-5"
            /> */}
            <MapSvg color={"#000"} />
            {filtersOn && (
              <div className="absolute -top-[6px] -right-[6px] w-3 h-3 rounded-full bg-primaryred" />
            )}
            <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
              Map View
            </p>
          </button>
          <button
            disabled={
              selectedAppointment !== null ||
              filterOpen === true ||
              loaderVisible === true ||
              addOpen === true ||
              publishOpen === true ||
              !enableFilter
            }
            onClick={() => {
              setFilterOpen(true);
            }}
            className="relative flex items-center justify-center rounded-md group w-14 aspect-square bg-primarylightgrey disabled:opacity-40"
          >
            <img
              src={Sort_Icon}
              alt="Filter"
              className="object-contain w-5 h-5"
            />
            {filtersOn && (
              <div className="absolute -top-[6px] -right-[6px] w-3 h-3 rounded-full bg-primaryred" />
            )}
            <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
              Apply Filters
            </p>
          </button>
          <button
            disabled={
              selectedAppointment !== null ||
              filterOpen === true ||
              loaderVisible === true ||
              addOpen === true ||
              publishOpen === true
            }
            onClick={() => {
              setAddOpen(true);
            }}
            className="relative flex items-center justify-center rounded-md group w-14 aspect-square bg-primarylightgrey disabled:opacity-40"
          >
            <img
              src={Add_Icon}
              alt="Filter"
              className="object-contain w-5 h-5"
            />
            <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
              Add Appointment
            </p>
          </button>
          <button
            disabled={
              selectedAppointment !== null ||
              filterOpen === true ||
              loaderVisible === true ||
              addOpen === true ||
              publishOpen === true
            }
            onClick={() => {
              setEditable(!editable);
            }}
            className="relative flex items-center justify-center rounded-md group w-14 aspect-square bg-primarylightgrey disabled:opacity-40"
          >
            {!editable ? (
              <img
                src={Edit_Icon}
                alt="Filter"
                className="object-contain w-5 h-5"
              />
            ) : (
              <CheckSvg color={"#0d6efd"} />
            )}
            <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
              Edit Appointment
            </p>
          </button>
        </div>
        {!hidePublishToAthena && (
          <button
            disabled={
              selectedAppointment !== null ||
              filterOpen === true ||
              loaderVisible === true ||
              addOpen === true ||
              publishOpen === true
            }
            onClick={() => {
              setPublishOpen(true);
            }}
            className="flex items-center justify-center w-1/2 px-3 py-2 text-base font-semibold bg-white border-2 rounded-md disabled:opacity-40 border-primarygreen text-primarygreen"
          >
            Publish to Athena
          </button>
        )}
      </div>
    </div>
  );
};

export default AppointmentOptions;
