import React, { useState } from "react";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import classNames from "classnames";
import { Search_Icon } from "../../../assets/images";
import ArrowUpRight from "../../../assets/svgs/ArrowUpRight";
import moment from "moment";
import { useCreateOneAppointmentMutation } from "../../../features/appointment/appointmentApiSlice";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { useMapStore } from "../../../context/map.store";
import DownArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import { latlong } from "../../../data";

const AppointmentAddModal = ({
  selectedProvider,
  addOpen,
  setAddOpen,
  weeks,
  onAdd,
  filterOptions,
  setRecallSchedule,
  appointmentsCount,
}) => {
  const { setPatientSelected } = useMapStore((state) => state);
  const [visitDate, setVisitDate] = useState(null);
  const [visitDateOpen, setVisitDateOpen] = useState(false);

  const [visitType, setVisitType] = useState("NORMAL");

  const [locationType, setLocationType] = useState("ALL");
  const [location, setLocation] = useState(null);
  const [locationOpen, setLocationsOpen] = useState(false);

  const [patient, setPatient] = useState(null);
  const [patientsOpen, setPatientsOpen] = useState(false);

  const [appointmentType, setAppointmentType] = useState(null);
  const [appointmentTypesOpen, setAppointmentTypesOpen] = useState(false);

  const [patientSearch, setPatientSearch] = useState("");
  const [appointmentTypeSearch, setAppointmentTypeSearch] = useState("");

  const [patientType, setPatientType] = useState("All");
  const [patientAnchor, setPatientAnchor] = useState(null);

  const [createOneAppointment] = useCreateOneAppointmentMutation();

  const setDefaultValues = () => {
    setVisitDate("");
    setVisitType("NORMAL");
    setLocationType("ALL");
    setLocation(null);
    setPatient(null);
    setAppointmentType(null);
    setVisitDateOpen(false);
    setLocationsOpen(false);
    setPatientsOpen(false);
    setAppointmentTypesOpen(false);
    setPatientSelected(null);
  };

  const handleSubmit = async () => {
    try {
      if (!visitType) {
        console.log("Please select Appointment Type");
        return;
      }
      if (visitType !== "Normal" && !visitDate) {
        console.log("Please select Appointment Date");
        return;
      }
      const appDate =
        visitType !== "Normal"
          ? moment().format("YYYY-MM-DD")
          : moment(visitDate).format("YYYY-MM-DD");
      await createOneAppointment({
        visitDate: appDate,
        appointmentTypeId: appointmentType.id,
        patientId: patient.id,
        providerId: selectedProvider.id,
        lastVisitDate: null,
        visitType,
      }).unwrap();

      setAddOpen(false);
      setRecallSchedule(true);
      setDefaultValues();
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setDefaultValues();
    setAddOpen(false);
  };

  const patientData =
    patientType === "Recently discharged"
      ? filterOptions.dischargedPatients
      : filterOptions.patients;

  const patientsList = patientData
    .filter((i) => {
      if (location !== null) {
        return i.location.id === location.id;
      }

      if (locationType === "COMMUNITY") {
        return i.location.type !== "COMMUNITY_HOME";
      }

      if (["All", "Recently discharged"].includes(patientType)) {
        return true;
      }

      if (i.patientType === patientType) {
        return true;
      }

      return false;
    })
    .map((i) => {
      return {
        label:
          i.patientType === "New"
            ? `${i.name || ""}} |
              ${i?.location?.name || ""} |
              ${i?.location?.address} |
              Added on: ${moment(i.addedOn).format("MMMM DD, YYYY")}
              `
            : i.patientType === "Overdue"
            ? `${i?.location?.name || ""} |
                ${i?.location?.name || ""} |
                ${i?.location?.address} |
                Overdue by: ${i.slack} days |
                Last seen on: ${moment(i.lastVisitDate).format(
                  "MMMM DD, YYYY"
                )} |
                ${i?.visitType && `Last Appointment Type: ${i.visitType}`}
              `
            : `${i?.location?.name || ""} |
                ${i?.location?.name || ""} |
                ${i?.location?.address} |
                ${
                  i?.currentScheduled &&
                  `Currently Scheduled: ${moment(
                    i.currentScheduled.slice(0, 10)
                  ).format("MMMM DD, YYYY")} |`
                }
              ${i?.visitType && `Last Appointment Type: ${i.visitType}`}
            `,
        value: i.patient_id,
        ...i,
      };
    });

  const CustomOption = ({ label, isFocused, isSelected }) => (
    <div
      key={label}
      className={`custom-option ${isFocused ? "focused" : ""} ${
        isSelected ? "selected" : ""
      }`}
    >
      {label.split("|").map((value, i) => {
        return (
          <Typography
            className={`${i === 0 ? "bold-600" : "font-14"} flex items-start`}
          >
            {value.split(":").length < 2 ? (
              value
            ) : (
              <Grid className="flex flex-row gap-1 items-start">
                {value.split(":").map((data, i) => (
                  <Typography
                    key={i}
                    className={`${i === 0 ? "bold-medium" : ""}`}
                    style={{
                      fontSize: 14,
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {data}
                    {i === 0 && ":"}
                  </Typography>
                ))}
              </Grid>
            )}
          </Typography>
        );
      })}
    </div>
  );

  return (
    <div
      className={classNames(
        "absolute top-[0px] transition-all min-h-fit bg-white w-96 border-l border-t border-b border-secondarygrayborder right-0 opacity-100",
        addOpen ? "right-0" : "right-[-400px]"
      )}
    >
      <div className="flex flex-row items-center justify-between w-full p-4">
        <p className="m-0 text-2xl font-bold text-primaryblack">
          Add Appointment
        </p>
        <button
          onClick={onClose}
          className="flex items-center justify-center w-10 rounded-full aspect-square bg-primarylightgrey"
        >
          <CloseSvg color={"#3062D4"} />
        </button>
      </div>
      <div className="w-full border border-primarygrayborder" />
      <div className="flex flex-col w-full gap-8 p-4">
        <div className="relative flex flex-col gap-3">
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-xl font-semibold">Visit Date</p>
          </div>
          <input
            type="date"
            value={visitDate}
            placeholder="dd/mm/yyyy"
            min={moment().format("YYYY-MM-DD")}
            max={weeks && weeks[weeks.length - 1].date}
            onChange={(e) => {
              setVisitDate(e.target.value);
            }}
            className="w-full px-2 py-1 border rounded-md border-primarygrayborder"
          />
        </div>
        <div className="flex flex-row w-full gap-2">
          <button
            onClick={() => {
              setVisitType("NORMAL");
            }}
            className={classNames(
              "flex flex-row items-center justify-center flex-1 px-3 py-2 rounded-md shadow-sm",
              visitType === "NORMAL"
                ? "border-2 border-primaryblue"
                : "border border-primarygrayborder"
            )}
          >
            <p
              className={classNames(
                "m-0 text-sm",
                visitType === "NORMAL" && "font-bold text-primaryblue"
              )}
            >
              Normal
            </p>
          </button>
          <button
            onClick={() => {
              setVisitType("ADHOC");
            }}
            className={classNames(
              "flex flex-row items-center justify-center flex-1 px-3 py-2 rounded-md shadow-sm",
              visitType === "ADHOC"
                ? "border-2 border-primaryblue"
                : "border border-primarygrayborder"
            )}
          >
            <p
              className={classNames(
                "m-0 text-sm",
                visitType === "ADHOC" && "font-bold text-primaryblue"
              )}
            >
              Ad-Hoc
            </p>
          </button>
        </div>
        <div className="flex flex-row w-full gap-2">
          <button
            onClick={() => {
              setLocationType("COMMUNITY");
            }}
            className={classNames(
              "flex flex-row items-center justify-center flex-1 px-3 py-2 rounded-md shadow-sm",
              locationType === "COMMUNITY"
                ? "border-2 border-primaryblue"
                : "border border-primarygrayborder"
            )}
          >
            <p
              className={classNames(
                "m-0 text-sm",
                locationType === "COMMUNITY" && "font-bold text-primaryblue"
              )}
            >
              Community
            </p>
          </button>
          <button
            onClick={() => {
              setLocationType("ALL");
              setLocation(null);
            }}
            className={classNames(
              "flex flex-row items-center justify-center flex-1 px-3 py-2 rounded-md shadow-sm",
              locationType === "ALL"
                ? "border-2 border-primaryblue"
                : "border border-primarygrayborder"
            )}
          >
            <p
              className={classNames(
                "m-0 text-sm",
                locationType === "ALL" && "font-bold text-primaryblue"
              )}
            >
              All
            </p>
          </button>
        </div>
      </div>
      <div className="flex flex-col w-full gap-8 p-4">
        {locationType === "COMMUNITY" && (
          <div className="flex flex-col gap-3">
            <div className="flex flex-row items-center justify-between">
              <p className="m-0 text-xl font-semibold">Community</p>
            </div>
            <div className="relative flex flex-row items-center w-full px-2 py-1 border rounded-md border-primarygrayborder">
              <img src={Search_Icon} alt="Search Icon" className="w-5 h-5" />
              <input
                type="text"
                className="w-full px-2 outline-none"
                placeholder="Search..."
                value={location !== null ? location.name : ""}
              />
              <button
                onClick={() => {
                  setLocationsOpen(!locationOpen);
                }}
                className="rotate-[135deg] px-1 w-fit"
              >
                <ArrowUpRight color={"#000"} />
              </button>
              <div
                className={classNames(
                  "absolute z-50 left-0 w-full bg-white border rounded-md top-9 border-primarygrayborder",
                  locationOpen
                    ? "max-h-44 overflow-x-auto opacity-100"
                    : "hidden h-0 overflow-hidden opacity-30"
                )}
              >
                {filterOptions.locations.map((x, ind) => (
                  <button
                    key={ind}
                    onClick={() => {
                      setLocation(x);
                      setLocationsOpen(false);
                    }}
                    className="flex flex-col items-start w-full transition-all hover:bg-slate-300"
                  >
                    <p className="p-2 m-0 text-base font-semibold text-left cursor-pointer text-primaryblack">
                      {x.name}
                    </p>
                    {ind !== filterOptions.locations.length - 1 && (
                      <div className="w-full border border-primarygrayborder" />
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="relative flex flex-col gap-3">
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-xl font-semibold">Patient</p>
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  setPatientAnchor(event.currentTarget);
                }}
                className="flex-row d-flex align-items-center gap-1 justify-content-center"
              >
                <span className="color-1 font-bold text-xs">{patientType}</span>
                <DownArrow className="color-1" />
              </div>
              <Menu
                open={Boolean(patientAnchor)}
                anchorEl={patientAnchor}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                onClose={() => {
                  setPatientAnchor(null);
                }}
              >
                {["All", "Overdue", "New", "Recently discharged"].map(
                  (data, ind) => (
                    <MenuItem
                      key={ind}
                      style={{
                        width: "200px",
                        fontSize: 14,
                      }}
                      onClick={() => {
                        setPatientType(data);
                        setPatientAnchor(null);
                      }}
                    >
                      {data}
                    </MenuItem>
                  )
                )}
              </Menu>
            </div>
          </div>
          <div className="flex flex-row items-center w-full px-2 py-1 border rounded-md border-primarygrayborder">
            <img src={Search_Icon} alt="Search Icon" className="w-5 h-5" />
            <input
              type="text"
              className="w-full px-2 outline-none"
              placeholder="Search..."
              value={patient !== null ? patient.name : patientSearch}
              onChange={(e) => {
                setPatientSearch(e.target.value);

                if (e.target.value === "") {
                  setPatient(null);
                }
              }}
            />
            <button
              onClick={() => {
                setPatientsOpen(!patientsOpen);
              }}
              className="rotate-[135deg] px-1 w-fit"
            >
              <ArrowUpRight color={"#000"} />
            </button>
          </div>
          <div
            className={classNames(
              "absolute z-50 left-0 w-full bg-white border rounded-md top-20 border-primarygrayborder",
              patientsOpen || patientSearch !== ""
                ? "max-h-44 overflow-x-auto opacity-100"
                : "hidden h-0 overflow-hidden opacity-30"
            )}
          >
            {patientsList
              .filter((x) => {
                return x.name
                  .toLowerCase()
                  .includes(patientSearch.toLowerCase());
              })
              .map((x, ind) => (
                <button
                  key={ind}
                  onClick={() => {
                    setPatient(x);
                    setPatientsOpen(false);
                    setPatientSearch("");
                    setPatientSelected({
                      address: x.location.address,
                      coords: {
                        lat: x.location.latitude,
                        lng: x.location.longitude,
                      },
                      locationId: x.location.id,
                      name: x.location.name,
                      patients: [
                        {
                          id: x.id,
                          name: x.name,
                          visitType: x.visitType,
                        },
                      ],
                    });
                  }}
                  className="flex items-start w-full transition-all hover:bg-slate-300"
                >
                  {/* <p className="p-2 m-0 text-base font-semibold text-left cursor-pointer text-primaryblack">
                    {x.name}
                  </p>
                  {ind !== filterOptions.patients.length - 1 && (
                    <div className="w-full border border-primarygrayborder" />
                  )} */}
                  <CustomOption label={x.label} />
                </button>
              ))}
          </div>
        </div>
        <div className="relative flex flex-col gap-3">
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-xl font-semibold">Appointment Type</p>
          </div>
          <div className="flex flex-row items-center w-full px-2 py-1 border rounded-md border-primarygrayborder">
            <input
              type="text"
              className="w-full px-2 outline-none"
              placeholder="Search..."
              value={
                appointmentType !== null
                  ? appointmentType.name
                  : appointmentTypeSearch
              }
              onChange={(e) => {
                setAppointmentTypeSearch(e.target.value);

                if (e.target.value === "") {
                  setAppointmentType(null);
                }
              }}
            />
            <button
              onClick={() => {
                setAppointmentTypesOpen(!appointmentTypesOpen);
              }}
              className="rotate-[135deg] px-1 w-fit"
            >
              <ArrowUpRight color={"#000"} />
            </button>
          </div>
          <div
            className={classNames(
              "absolute z-50 left-0 w-full bg-white border rounded-md top-20 border-primarygrayborder",
              appointmentTypesOpen || appointmentTypeSearch !== ""
                ? "max-h-44 overflow-x-auto opacity-100"
                : "hidden h-0 overflow-hidden opacity-30"
            )}
          >
            {filterOptions.appointmentTypes
              .filter((x) => {
                return x.name
                  .toLowerCase()
                  .includes(appointmentTypeSearch.toLowerCase());
              })
              .map((x, ind) => (
                <button
                  key={ind}
                  onClick={() => {
                    setAppointmentType(x);
                    setAppointmentTypesOpen(false);
                    setAppointmentTypeSearch("");
                  }}
                  className="flex flex-col items-start w-full transition-all hover:bg-slate-300"
                >
                  <p className="p-2 m-0 text-base font-semibold text-left cursor-pointer text-primaryblack">
                    {x.name}
                  </p>
                  {ind !== filterOptions.appointmentTypes.length - 1 && (
                    <div className="w-full border border-primarygrayborder" />
                  )}
                </button>
              ))}
          </div>
        </div>
      </div>
      <div className="w-full border border-primarygrayborder" />
      <p className="p-4 m-0 text-sm">
        You are choosing to add this appointment for{" "}
        <span className="font-semibold">Janice Jones</span> to{" "}
        <span className="font-semibold">Robert Smith's</span> schedule for{" "}
        <span className="font-semibold">August 25, 2023</span>. This appointment
        will be placed last in the Provider's schedule for the day.
      </p>
      <div className="w-full p-4 mb-4">
        <button
          disabled={
            visitDate === "" || patient === null || appointmentType === null
          }
          onClick={handleSubmit}
          className="flex flex-row items-center justify-center w-full px-3 py-2 border rounded-md shadow-sm bg-primaryblue border-primaryblue disabled:opacity-40"
        >
          <p className="m-0 text-base text-white">Confirm</p>
        </button>
      </div>
    </div>
  );
};

export default AppointmentAddModal;
