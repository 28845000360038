import { apiSlice } from "../../app/api/apiSlice";

export const communityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCommunityById: builder.mutation({
      query: (credentials) => ({
        url: `/community/${credentials}`,
        method: "GET",
      }),
    }),
    fetchCommunityPatients: builder.mutation({
      query: (credentials) => ({
        url: `/community/patients/${credentials}`,
        method: "GET",
      }),
    }),
    fetchCommunityStaff: builder.mutation({
      query: (credentials) => ({
        url: `/community/staffs/${credentials}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useFetchCommunityByIdMutation,
  useFetchCommunityPatientsMutation,
  useFetchCommunityStaffMutation,
} = communityApiSlice;
