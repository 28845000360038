import React from "react";

const AppointmentCardSkeleton = () => {
  return (
    <div className="relative flex flex-col w-full gap-2 p-2 transition-all bg-white border rounded-md opacity-100 border-primarygrayborder">
      <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
      <div className="flex flex-row items-center justify-between w-full">
        <div className="h-5 border rounded-md w-28 bg-primarylightgrey" />
        <div className="w-4 h-4 border rounded-md bg-primarylightgrey" />
      </div>
      <div className="h-4 border rounded-md w-36 bg-primarylightgrey" />
      <div className="h-4 border rounded-md w-44 bg-primarylightgrey" />
      <div className="flex flex-row items-center justify-between w-full gap-1">
        <div className="px-2 border min-w-[120px] self-stretch rounded-md border-primarylightgrey bg-primarylightgrey h-[18px]" />
        <div className="border min-w-[88px] px-2 border-primarylightgrey bg-primarylightgrey rounded-md h-[18px]" />
      </div>
    </div>
  );
};

export default AppointmentCardSkeleton;
