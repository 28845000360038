import React, { useEffect, useState } from "react";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import {
  useGetPaymentMethodsMutation,
  useRemovePaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
} from "../../../features/billing/billingApiSlice";
import classNames from "classnames";
import moment from "moment/moment";

const PaymentMethods = () => {
  const [paymentFormOpen, setPaymentFormOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [openEdit, setOpenEdit] = useState(null);

  const [removePaymentMethod] = useRemovePaymentMethodMutation();

  const [setDefaultPaymentMethod] = useSetDefaultPaymentMethodMutation();

  const [getPaymentMethods] = useGetPaymentMethodsMutation();

  const fetchPaymentMethods = async () => {
    const response = await getPaymentMethods().unwrap();
    setPaymentMethods(response.data);
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  useEffect(() => {}, [paymentMethods]);

  return (
    <>
      <div className="flex flex-col gap-6 px-12 py-10">
        <div className="flex flex-row justify-between w-full">
          <h5 className="m-0 text-3xl font-semibold">Payment Methods</h5>
          <button
            onClick={() => setPaymentFormOpen(true)}
            className="flex flex-row items-center gap-2 p-2 text-white rounded-md bg-primaryblue"
          >
            <div className="rotate-45">
              <CloseSvg color={"#FFF"} />
            </div>
            <p className="m-0 text-base whitespace-nowrap">Add New</p>
          </button>
        </div>
        <table className="w-full">
          <thead>
            <tr className="bg-primarylightgrey">
              <th className="px-4 py-3 text-left">Payment Method</th>
              <th className="px-4 py-3 text-left">Expiry Date</th>
              <th className="px-4 py-3 text-left">Status</th>
              <th className="px-4 py-3 text-left"></th>
            </tr>
          </thead>
          <tbody>
            {paymentMethods.map((paymentMethod) => (
              <tr
                key={paymentMethod.id}
                className="border-b border-primarygrayborder"
              >
                <td className="px-4 py-3 text-left">
                  {paymentMethod.card.brand[0].toUpperCase() +
                    paymentMethod.card.brand.slice(1)}{" "}
                  ending in {paymentMethod.card.last4}
                </td>
                <td className="px-4 py-3 text-left">
                  {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
                </td>
                <td className="px-4 py-3 text-left">
                  {paymentMethod.default ? (
                    <div className="flex flex-row items-center gap-2 px-2 py-1 rounded-md bg-subtlegreen w-fit">
                      <div className="w-2 h-2 rounded-full bg-primarytextgreen" />
                      <p className="m-0 text-sm text-primarytextgreen">
                        Default
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td className="relative">
                  <button
                    onClick={() => {
                      if (openEdit === null) {
                        setOpenEdit(paymentMethod.id);
                      } else {
                        setOpenEdit(null);
                      }
                    }}
                    disabled={paymentMethod.default}
                    className="flex flex-row items-center justify-center gap-2 p-2 transition-all rounded-lg h-ful w-fit hover:bg-slate-200 disabled:opacity-40 disabled:cursor-not-allowed"
                  >
                    <div className="w-1 h-1 rounded-full bg-primarydarkgrey" />
                    <div className="w-1 h-1 rounded-full bg-primarydarkgrey" />
                    <div className="w-1 h-1 rounded-full bg-primarydarkgrey" />
                  </button>
                  <div
                    className={classNames(
                      "absolute right-6 z-50 -bottom-16 flex flex-col overflow-clip text-left divide-primarygrayborder divide-y-[1px] bg-white whitespace-nowrap rounded-md shadow-md border-[1px] border-primarygrayborder",
                      openEdit === paymentMethod.id ? "visible" : "hidden"
                    )}
                  >
                    <p
                      onClick={async () => {
                        await setDefaultPaymentMethod({
                          paymentMethodId: paymentMethod.id,
                        }).unwrap();

                        await fetchPaymentMethods();

                        setOpenEdit(null);
                      }}
                      className="p-2 m-0 transition-all cursor-pointer hover:bg-slate-100"
                    >
                      Set as default
                    </p>
                    <p
                      onClick={async () => {
                        await removePaymentMethod({
                          paymentMethodId: paymentMethod.id,
                        }).unwrap();

                        await fetchPaymentMethods();

                        setOpenEdit(false);
                      }}
                      className="p-2 m-0 transition-all cursor-pointer hover:bg-slate-100"
                    >
                      Delete payment method
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ReusableModal
        open={paymentFormOpen}
        onClose={() => setPaymentFormOpen(false)}
      >
        <Elements
          stripe={loadStripe(
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
          )}
        >
          <PaymentForm
            handleSuccess={async () => {
              await fetchPaymentMethods();
              setPaymentFormOpen(false);
            }}
          />
        </Elements>
      </ReusableModal>
    </>
  );
};

export default PaymentMethods;
