import React from 'react'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../../../Reports/reportStyles.css'

function PatientVisitReportFilter({ getPatientReports, dateRange, setDateRange, dateRangeValue, setDateRangeValue, handleClear, isSelectedValue, setClear, setIsTableView, isTableView }) {
    const today = dayjs();

    const handleSearch = (e) => {
        e.preventDefault()
        setClear(true)
        getPatientReports({ setHeaders: true, dateRange: dateRange });
    }
    const handleChange = async (e, newValue) => {
        await setDateRangeValue(newValue);
        setClear(true)
        if (newValue === 'THIS_MONTH') {
            setDateRange([today.startOf('month'), today])
        } else if (newValue === 'LAST_MONTH') {
            setDateRange([today.subtract(1, 'month').startOf('month'), today.subtract(1, 'month').endOf('month')])
        } else if (newValue === 'YTD') {
            setDateRange([today.startOf('year'), today])
        }
        getPatientReports({ dateRangeValue: newValue, setHeaders: true })
    };
    return (
        <div className="col-12 flex flex-row w-full items-end flex-wrap">
            <div className='col-md-6 col-12 flex flex-row flex-wrap mb-1 mt-1'>
                <div className='d-flex flex-row align-items-center mx-2 mb-1 mb-sm-0 w-3/5'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateRangePicker']}>
                            <DateRangePicker
                                localeText={{ start: 'From', end: 'To' }}
                                calendars={2}
                                value={dateRange}
                                onChange={(newValue) => {
                                    setDateRange(newValue);
                                    setDateRangeValue('')
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className='mx-1 mb-1'>
                    <button variant="contained"
                        disabled={dayjs(dateRange[0]).isValid() || dayjs(dateRange[1]).isValid() ? false : true} className='py-2 px-2 text-white font-bold bg-primaryblue rounded-lg disabled:opacity-60 hover:bg-blue-700 text-sm' onClick={handleSearch} style={{ textTransform: 'capitalize' }}>Search</button>
                </div>
                <div className=' mx-1 mb-1'>
                    <button variant="contained" disabled={!isSelectedValue && !dayjs(dateRange[0]).isValid() && !dayjs(dateRange[1]).isValid()} className='py-2 px-2 text-white font-bold bg-primaryblue rounded-lg disabled:opacity-60 hover:bg-blue-700 text-sm'
                        onClick={() => {
                            handleClear()
                            //  setDateVal([today.startOf('month'), today])
                        }} style={{ textTransform: 'capitalize' }}>Clear</button>
                </div>
            </div>
            <div className='d-flex flex-col sm:flex-row justify-content-start mt-1 mb-1 items-end col-md-6  col-12'>
                <div className='d-flex gap-2 mx-2 grow m-auto justify-end self-start mb-1'>
                    <button variant="contained"
                        disabled={dayjs(dateRange[0]).isValid() || dayjs(dateRange[1]).isValid() ? false : true} className={(isTableView ? 'bg-slate-700 hover:bg-slate-800' : 'bg-primaryblue hover:bg-blue-700') + ' py-2 px-2 text-white font-bold  rounded-lg disabled:opacity-60  text-sm'}
                        onClick={() => {
                            setIsTableView(true)

                        }}
                        style={{ textTransform: 'capitalize' }}>Table</button>
                    <button variant="contained"
                        disabled={dayjs(dateRange[0]).isValid() || dayjs(dateRange[1]).isValid() ? false : true} className={(!isTableView ? 'bg-slate-700 hover:bg-slate-800' : 'bg-primaryblue hover:bg-blue-700') + ' py-2 px-2 text-white font-bold  rounded-lg disabled:opacity-60 hover:bg-blue-700 text-sm'}
                        onClick={() => {
                            setIsTableView(false)
                        }}
                        style={{ textTransform: 'capitalize' }}>Graph</button>
                </div>
                <Tabs
                    value={dateRangeValue}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="white"
                    aria-label="secondary tabs example"
                    className='mb-1'
                >
                    <Tab label="This Month" value="THIS_MONTH" />
                    <Tab label="Last Month" value="LAST_MONTH" />
                    <Tab label="Year to Date" value="YTD" />
                </Tabs>
            </div>
        </div>
        // </div >
    )
}

export default PatientVisitReportFilter