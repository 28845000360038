import React, { useEffect, useState, createContext, useCallback } from "react";
import useSocketService from "../services/socket";
import { useSelector } from "react-redux";
import { selectUser } from "../features/auth/authSlice";
import {
  useCreateConversationMutation,
  useFetchChatsMutation,
  useFetchConversationAttachmentsMutation,
  useFetchConversationByIdMutation,
  useFetchConversationMessagesMutation,
  useFetchLatestMessagesMutation,
  useFetchStarredMessagesMutation,
  useFetchTaggedCaretagsMutation,
  useFetchTaggedPatientsMutation,
  useStarMessageMutation,
  useUnstarMessageMutation,
} from "../features/chat/chatApiSlice";
import moment from "moment";
import { useGetNotificationsMutation } from "../features/notification/notificationApiSlice";

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const user = useSelector(selectUser);
  const [messages, setMessages] = useState([]);
  const [latestMessages, setLatestMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const socket = useSocketService();

  const [fetchChats] = useFetchChatsMutation();
  const [createConversationApi] = useCreateConversationMutation();
  const [fetchConversationById] = useFetchConversationByIdMutation();
  const [fetchConversationMessages] = useFetchConversationMessagesMutation();
  const [starMessage] = useStarMessageMutation();
  const [unstarMessage] = useUnstarMessageMutation();
  const [fetchStarredMessages] = useFetchStarredMessagesMutation();
  const [fetchTaggedPatients] = useFetchTaggedPatientsMutation();
  const [fetchTaggedCaretags] = useFetchTaggedCaretagsMutation();
  const [fetchLatestMessages] = useFetchLatestMessagesMutation();
  const [getNotifications] = useGetNotificationsMutation();
  const [fetchMediaFiles] = useFetchConversationAttachmentsMutation();

  useEffect(() => {
    getNotifications().then((res) => {
      setNotifications(Array.isArray(res?.data?.data) ? res?.data?.data : []);
    });
  }, []);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected with token");
    });

    socket.on("connect_error", (err) => {
      console.log("Connection error: ", err.message);
    });

    socket.on("echo", (message) => {
      console.log(message, "echo");
    });

    const conversationCreated = () => {
      console.log("conversation created");
    };

    socket.on("conversation created", conversationCreated);

    const conversationUpdated = () => {};

    socket.on("conversation updated", conversationUpdated);

    const conversationDeleted = () => {};

    socket.on("conversation deleted", conversationDeleted);

    const conversationUserAdded = () => {};

    socket.on("conversation user added", conversationUserAdded);

    const conversationUserRemoved = () => {};

    socket.on("conversation user removed", conversationUserRemoved);

    const messageCreationAcknowledged = ({ databaseId, tempId }) => {
      console.log(databaseId, tempId, "message creation acknowledged");
      setMessages((prevMessages) => {
        const index = prevMessages.findIndex((msg) => msg.tempId === tempId);
        if (index !== -1) {
          prevMessages[index].id = databaseId;
        }
        return [...prevMessages];
      });
    };

    socket.on("message creation acknowledged", messageCreationAcknowledged);

    const messageSent = (message) => {
      console.log(message, "message sent");
      setMessages((prevMessages) => {
        return [...prevMessages, message];
      });
      setChats((prevChats) =>
        prevChats?.map((data_G) => {
          if (data_G.conversationId === message.conversationId) {
            return {
              ...data_G,
              lastMessage: message,
            };
          }
          return {
            ...data_G,
          };
        })
      );
    };

    socket.on("message sent", messageSent);

    const messageUpdateAcknowledged = (updatedMessage) => {
      console.log("message update acknowledged", updatedMessage);
      setMessages((prevMessages) =>
        prevMessages.map((message) => {
          if (message.id === updatedMessage.id) {
            return updatedMessage;
          }
          return message;
        })
      );
    };

    socket.on("message update acknowledged", messageUpdateAcknowledged);

    const messageUpdated = (message) => {
      console.log("message updated");
      setMessages((prevMessages) => {
        const index = prevMessages.findIndex((msg) => msg.id === message.id);
        if (index !== -1) {
          prevMessages[index] = message;
        }
        return [...prevMessages];
      });
    };

    socket.on("message updated", messageUpdated);

    const messageDeletionAcknowledged = ({ databaseId }) => {
      console.log("message deletion acknowledged");
      setMessages((prevMessages) => {
        return prevMessages?.filter((msg) => msg.id !== databaseId);
      });
    };

    socket.on("message deletion acknowledged", messageDeletionAcknowledged);

    const messageDeleted = (messageId) => {
      console.log("message deleted");
      setMessages((prevMessages) => {
        return prevMessages?.filter((msg) => msg.id !== messageId);
      });
    };

    socket.on("message deleted", messageDeleted);

    return () => {
      socket.off("connect");
      socket.off("connect_error");
      socket.off("conversation created");
      socket.off("conversation updated");
      socket.off("conversation deleted");
      socket.off("conversation user added");
      socket.off("conversation user removed");
      socket.off("message creation acknowledged");
      socket.off("message sent");
      socket.off("message update acknowledged");
      socket.off("message updated");
      socket.off("message deletion acknowledged");
      socket.off("message deleted");
    };
  }, []);

  const chatConvert = (data_res) => {
    const contact = data_res?.participants.length
      ? data_res?.participants.find((data) => data.user_id !== user?.id)
      : undefined;
    return {
      conversationId: data_res?.id,
      isCreator: data_res?.creator_id === user.id,
      isAdmin: data_res?.creator_id === user.id,
      createdAt: data_res?.createdAt,
      conversationName: data_res?.name,
      conversationImg: data_res?.image,
      conversationType: data_res?.type,
      communityId: data_res?.community_id,
      lastSeen: moment(data_res?.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
      lastMessage: data_res?.lastMessage,
      selected: false,
      contactId: contact?.user_id,
      contactFN: contact?.firstname,
      contactLN: contact?.lastname,
      contactImg: contact?.img,
      updatedAt: data_res?.updatedAt,
      participants: data_res?.participants,
    };
  };

  const getChats = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await fetchChats();
      const filteredData = data.data
        .filter(
          (data) =>
            data.last_message_id !== null ||
            data.last_message !== undefined ||
            data.type === "GROUP"
        )
        .map(chatConvert);
      console.log(filteredData, "filteredData");
      setChats(filteredData || []);
      setLoading(false);
      return filteredData;
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
      return [];
    }
  }, []);

  const getConversationDetails = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchConversationById({
        id: conversationId,
      });
      const filteredData = chatConvert(data.data);
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getMessages = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchConversationMessages({
        id: conversationId,
      });
      const filteredData = data.data;
      setMessages(filteredData);
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getLatestMessages = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await fetchLatestMessages();
      const filteredData = data.data;
      const finalMessages = filteredData.map((data) => ({
        ...data,
        conversationName: data?.conversation?.name,
        conversationId: data?.conversation_id,
        contactFN: data?.sender?.details?.firstname,
        contactLN: data?.sender?.details?.lastname,
        conversationImg: data?.conversation?.image,
        contactImg: data?.sender?.img,
      }));
      setLatestMessages(finalMessages);
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getStarredMessages = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchStarredMessages({
        id: conversationId,
      });
      const filteredData = data.data;
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getReferencedPatients = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchTaggedPatients({
        id: conversationId,
      });
      const filteredData = data.data;
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getTaggedCaretags = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchTaggedCaretags({
        id: conversationId,
      });
      const filteredData = data.data;
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getMediaFiles = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchMediaFiles({
        id: conversationId,
      });
      const filteredData = data.data;
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const emitEcho = () => {
    socket.emit("echo");
  };

  const createConversation = async ({
    userIds,
    community_id,
    name,
    description,
    image,
    type,
  }) => {
    try {
      const { data } = await createConversationApi({
        participants: userIds,
        community_id,
        name,
        description,
        image,
        type,
      });
      setChats((prevChats) => [...prevChats, chatConvert(data?.data || {})]);
      socket.emit("create conversation", { group: data?.data, userIds });
      return data.data;
    } catch (error) {
      return {};
    }
  };

  const onStarMessage = async (message) => {
    try {
      await starMessage({
        id: message.id,
      });
      setMessages((prevMsgs) =>
        prevMsgs?.map((data) => {
          if (data.id === message.id) {
            return {
              ...data,
              is_starred: true,
            };
          }
          return {
            ...data,
          };
        })
      );
    } catch (error) {
      console.log(error, "err-star-message");
    }
  };

  const onUnstarMessage = async (message) => {
    try {
      await unstarMessage({
        id: message.id,
      });
      setMessages((prevMsgs) =>
        prevMsgs?.map((data) => {
          if (data.id === message.id) {
            return {
              ...data,
              is_starred: false,
            };
          }
          return {
            ...data,
          };
        })
      );
    } catch (error) {
      console.log(error, "err-star-message");
    }
  };

  const markUrgent = async ({ message, userIds }) => {
    try {
      setMessages((prevMessages) => {
        return prevMessages?.map((msg) => {
          if (msg.id === message.id) {
            return message;
          }
          return msg;
        });
      });
      const senderId = user.id;
      const event = "UPDATE";
      updateMessage({ senderId, event, message, userIds });
    } catch (error) {
      console.log(error, "mark-message");
    }
  };

  const updateConversation = (group, userIds) => {
    socket.emit("update conversation", { group, userIds });
  };

  const deleteConversation = (group, userIds) => {
    socket.emit("delete conversation", { group, userIds });
  };

  const addConversationUser = (group, userIds) => {
    socket.emit("add conversation user", { group, userIds });
  };

  const removeConversationUser = (group, userIds) => {
    socket.emit("remove conversation user", { group, userIds });
  };

  const sendMessage = ({ message, userIds, meta }) => {
    setMessages((prevMessages) => [
      {
        ...message,
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        sender_id: user.id,
      },
      ...prevMessages,
    ]);
    // event -> 'CREATE' | 'UPDATE' | 'DELETE'
    // userIds -> participants
    const senderId = user.id;
    const event = "CREATE";
    socket.emit("send message", { senderId, event, message, userIds, meta });
    setChats((prevChats) =>
      prevChats?.map((data_G) => {
        if (data_G.conversationId === message.conversationId) {
          return {
            ...data_G,
            lastMessage: {
              ...message,
              createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
              sender_id: user.id,
            },
          };
        }
        return {
          ...data_G,
        };
      })
    );
  };

  const updateMessage = ({ message, userIds, meta }) => {
    setMessages((prevMessages) =>
      prevMessages?.map((msg) => {
        if (msg.id === message.id) {
          return message;
        }
        return msg;
      })
    );
    const senderId = user.id;
    const event = "UPDATE";
    socket.emit("update message", { senderId, event, message, userIds, meta });
  };

  const deleteMessage = ({ message, userIds }) => {
    setMessages((prevMessages) =>
      prevMessages?.filter((msg) => msg.id !== message.id)
    );
    const senderId = user.id;
    const event = "DELETE";
    socket.emit("delete message", { senderId, event, message, userIds });
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        emitEcho,
        createConversation,
        updateConversation,
        deleteConversation,
        addConversationUser,
        removeConversationUser,
        sendMessage,
        updateMessage,
        deleteMessage,
        messages,
        setMessages,
        chats,
        setChats,
        currentChat,
        setCurrentChat,
        editMessage,
        setEditMessage,
        getChats,
        getConversationDetails,
        getMessages,
        onStarMessage,
        onUnstarMessage,
        markUrgent,
        getStarredMessages,
        getReferencedPatients,
        getTaggedCaretags,
        isLoading,
        getLatestMessages,
        latestMessages,
        setLatestMessages,
        notifications,
        getNotifications,
        getMediaFiles,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
