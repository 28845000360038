import * as d3 from 'd3';

import React, { useEffect, useRef, useState } from 'react';

import CircleIcon from '@mui/icons-material/Circle';


const ChartComponent = ({ option, properties, showAlert, isChartView, isTableView, isAnimated, setIsAnimated }) => {
    const chartRef = useRef();
    const [tooltip, setTooltip] = useState({ visible: false, name: '', borderColor: '#ccc', content: '', left: 0, top: 0 });
    const x0Ref = useRef(null);
    const yRef = useRef(null);
    const legendRef = useRef(null);
    const parentRef = useRef(null);
    const [marginBottom, setMarginBottom] = useState(30);
    const [legendVisibility, setLegendVisibility] = useState(
        option.legend.data.reduce((acc, item) => ({ ...acc, [item]: true }), {})
    );
    const escapeClassName = (str) => {
        return str?.replace(/[^a-zA-Z0-9-_]/g, '-');
    };
    const wrapLegend = (legend, containerWidth) => {
        // Wrap legend items to fit the container width
        let xOffset = 0;
        let yOffset = 0;
        const legendWidth = 150; // Width of the legend item including padding
        const legendHeight = 20; // Height of the legend item
        const legendPadding = 10; // Padding between legend items

        legend.each(function () {
            const g = d3.select(this);
            const textElement = g.select('text');
            const textWidth = textElement.node().getComputedTextLength();
            const totalWidth = textWidth + 18 + legendPadding; // Adding rect width

            // Move to a new row if the item doesn't fit
            if (xOffset + totalWidth > containerWidth) {
                xOffset = 0;
                yOffset += legendHeight + legendPadding;
            }
            // Position the legend item
            g.attr('transform', `translate(${xOffset}, ${yOffset})`);
            xOffset += totalWidth; // Move xOffset to the right for the next item
        });
    };
    const handleMouseOver = (event, d) => {
        const [x, y] = d3.pointer(event);
        let borderColor = '#ccc'
        if (properties?.chartType === 'bar' || properties?.chartType === 'barWithLine' || properties?.chartType === 'double') {
            const xValue = option.xAxis.data[d.key]; // Get the x-axis label
            const yValue = d.value;// Get the y-axis value
            const borderColor = option?.series.find((item) => item.name === d?.name).itemStyle.color;
            let topPosition = y - 28;

            // If y is 0 or less, set top position to 0
            if (y <= 36) {
                topPosition = 44;
            }
            setTooltip({
                visible: true,
                name: `${d?.name}`,
                content: ` ${xValue} : ${yValue}`, // Display both x and y values
                left: x + 10,
                // top: y - 28,
                top: topPosition,
                borderColor
            });

        } else if (properties?.chartType === 'pie') {
            const { data } = d; // data contains the pie chart slice data
            const content = `${data.name}: ${data.value}`;
            //console.log(option?.series[0]?.data, ">>>dkjfuj")
            const borderColor = option?.series[0]?.data.find((item) => item.name === data?.name).itemStyle.color;
            setTooltip({
                visible: true,
                name: "",
                content: content,
                // left: x + 180,
                // top: y + 200,
                left: x + 200,
                top: y + 150,
                borderColor: borderColor
            });
        }
        // For stacked charts, d is an array with [key, value, data]
        else if (properties?.chartType === 'stacked') {
            const xValue = option.xAxis.data[d.dataIndex]; // Get the x-axis label
            // Format the content based on the data object in d
            const dataContent = Object.entries(d.data).map(([name, value]) => ` ${name}: ${value}`).join('\n');
            setTooltip({
                visible: true,
                content: ` ${dataContent}`, // Display the x value and series values
                left: x + 10,
                top: y - 28
            });
        }
    };
    const handleMouseOut = () => {
        setTooltip({ ...tooltip, visible: false });
    };

    const handleLineMouseOver = (event, d, x0Scale, name) => {
        const [xPos, yPos] = d3.pointer(event);

        // Determine if 'd' is a single value or an array
        if (Array.isArray(d)) {
            // 'd' is an array (for lines), use it for index calculations
            const x0Domain = x0Scale.domain();
            const x0Range = x0Scale.range();
            const domainLength = x0Domain.length;
            const pixelWidth = x0Range[1] - x0Range[0];
            const xIndex = Math.round((xPos - x0Range[0]) / pixelWidth * (domainLength - 1));
            const clampedIndex = Math.max(0, Math.min(domainLength - 1, xIndex));

            const xValue = x0Domain[clampedIndex];
            const yValue = d[clampedIndex];

            setTooltip({
                visible: true,
                // content: X: ${xValue}, Y: ${yValue},
                name: `${name}`,
                content: ` ${xValue} : ${yValue}`,
                left: xPos + 10,
                top: yPos - 28,
                borderColor: d3.select(event.target).attr('stroke') ? d3.select(event.target).attr('stroke') : d3.select(event.target).attr('fill') // Use stroke color for lines
            });
        } else {
            // 'd' is a single value (for circles), use it directly
            const x0Domain = x0Scale.domain();
            const x0Range = x0Scale.range();
            const domainLength = x0Domain.length;
            const pixelWidth = x0Range[1] - x0Range[0];
            const xIndex = Math.round((xPos - x0Range[0]) / pixelWidth * (domainLength - 1));
            const clampedIndex = Math.max(0, Math.min(domainLength - 1, xIndex));

            const xValue = x0Domain[clampedIndex]; // Use the provided index for x-axis data
            const yValue = d; // Directly use 'd' for yValue

            setTooltip({
                visible: true,
                // content: X: ${xValue}, Y: ${yValue},
                name: `${name}`,
                content: ` ${xValue} : ${yValue}`,
                left: xPos + 10,
                top: yPos - 28,
                borderColor: d3.select(event.target).attr('fill') // Use fill color for circles
            });
        }
    };

    const toggleLegendVisibility = (legendItem) => {
        setLegendVisibility((prev) => ({ ...prev, [legendItem]: !prev[legendItem] }));
        setIsAnimated(false)
    };
    const adjustContainerHeight = () => {
        // Select the SVG element
        const svgElement = d3.select(legendRef.current).select('svg');

        // Select the legend container within the SVG
        const legendContainer = svgElement.select('.legend-container');


        // Calculate the total height of all legend items
        let totalHeight = 0;
        legendContainer.selectAll('.legend').each(function () {
            totalHeight += this.getBBox().height + 5;
        });

        totalHeight += 60;

        // Set the height of the SVG element
        svgElement.attr('height', totalHeight);

    };
    const margin = { top: 30, right: 30, bottom: marginBottom, left: 30 };
    const generateChart = () => {

        const svg = d3.select(chartRef.current);
        svg.selectAll('*').remove();
        let width;
        properties?.zAxis?.length > 0 ? properties?.zAxis?.forEach((item, i) => {
            width = chartRef.current.clientWidth - margin.left - margin.right - (70 * (i + 1));
        }) : width = chartRef.current.clientWidth - margin.left - margin.right;

        const height = chartRef.current.clientHeight - margin.top - margin.bottom < 0 ? 50 : chartRef.current.clientHeight - margin.top - margin.bottom;
        const g = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .attr('width', `100%`)

        const x0Scale = d3.scaleBand()
            .domain(option.xAxis.data)
            .range([0, width])
            .padding(0.1);

        const x1 = d3.scaleBand()
            .domain(option.series.filter(s => s.type === 'bar').map(s => escapeClassName(s.name)))
            .range([0, x0Scale.bandwidth()])
            .padding(0.05);

        let maxStackHeight = 0;
        option.xAxis.data.forEach((_, i) => {
            let stackSum = 0;
            option.series.filter(s => s.type === 'bar' && s.category === properties?.yAxis).forEach(series => {
                stackSum += series.data[i];
            });
            maxStackHeight = Math.max(maxStackHeight, stackSum);
        });
        const yScale = d3.scaleLinear()
            .domain([
                option.yAxis.min || 0,
                properties?.chartType === 'stacked' ?
                    maxStackHeight
                    : d3.max(option.series?.filter((item) => item?.category === properties?.yAxis), s => {
                        return d3.max(s.data)
                    })
            ])
            .nice()
            .range([height, 0]);

        const yAxis = d3.axisLeft(yScale)
            .ticks((height / 40))
            .tickValues(d3.range(
                Math.ceil((option.yAxis.min || 0) / option.yAxis.interval) * option.yAxis.interval,
                properties?.chartType === 'stacked' ?
                    maxStackHeight + (option.yAxis.interval % 2 === 0 && maxStackHeight % 2 !== 0 ? 1 : 1)
                    : d3.max(option.series?.filter((item) => item?.category === properties?.yAxis), s => d3.max(s.data)) + (option.yAxis.interval % 2 === 0 && d3.max(option.series?.filter((item) => item?.category === properties?.yAxis), s => d3.max(s.data)) % 2 !== 0 ? 1 : 1),
                option.yAxis.interval
            ));
        const lineScale = d3.scaleLinear()
            .domain([
                option.yAxis.min || 0,
                d3.max(option.series?.filter((item) => item?.category === properties?.line), s => {
                    return d3.max(s.data)
                })
            ])
            .nice()
            .range([height, 0])
        const line = d3.axisRight(lineScale)
            .ticks((height / 40))
            .tickValues(d3.range(
                Math.ceil((option.yAxis.min || 0) / option.yAxis.interval) * option.yAxis.interval,
                d3.max(option.series?.filter((item) => item?.category === properties?.line), s => d3.max(s.data)) + (option.yAxis.interval % 2 === 0 && d3.max(option.series?.filter((item) => item?.category === properties?.line), s => d3.max(s.data)) % 2 !== 0 ? 1 : 1),
                option.yAxis.interval
            ));
        g.append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(x0Scale))
            .selectAll('.tick text')
            .attr('fill', option.xAxis.axisLabel.color || '#4b5563')
            .style('font-size', `${option.xAxis.axisLabel.fontSize || 12}px`)
            .style('font-family', option.xAxis.axisLabel.fontFamily || 'Verdana')
            .attr('transform', `rotate(${option.xAxis.axisLabel.rotate || -45})`)
            .style('text-anchor', 'end')
            .attr('x', -8)
            .text(function (d) {
                const label = d.toString();
                const rotation = d3.select(this).attr("transform");
                let rotationAngle = 0;

                if (rotation && rotation.includes("rotate")) {
                    const match = rotation.match(/rotate\((-?\d+)\)/);
                    if (match) {
                        rotationAngle = parseInt(match[1], 10);
                    }
                }
                rotationAngle = rotationAngle % 360;
                if (rotationAngle === -90) {
                    setMarginBottom(80);
                } else if (rotationAngle === 0) {
                    setMarginBottom(30);
                }
                else if (rotationAngle >= -10) {
                    setMarginBottom(40);
                }
                else if (rotationAngle >= -20 && rotationAngle <= -11) {
                    setMarginBottom(50);
                }
                else if (rotationAngle >= -110 && rotationAngle <= -100) {
                    setMarginBottom(65);
                } else if (rotationAngle >= -120 && rotationAngle <= -111) {
                    setMarginBottom(58);
                } else if (rotationAngle >= -130 && rotationAngle <= -121) {
                    setMarginBottom(52);
                } else if (rotationAngle >= -140 && rotationAngle <= -131) {
                    setMarginBottom(48);
                } else if (rotationAngle >= -150 && rotationAngle <= -141) {
                    setMarginBottom(38);
                } else if (rotationAngle >= -360 && rotationAngle <= -151) {
                    setMarginBottom(20);
                } else if (rotationAngle > 0) {
                    setMarginBottom(30);
                } else {
                    setMarginBottom(80);
                }
                if (rotationAngle === 0 || rotationAngle === 360 || rotationAngle === -360) {
                    return label;
                }
                if (rotationAngle === -90) {
                    return label.length > 9 ? label.slice(0, 7) + '...' : label;
                }
                if (rotationAngle === -45) {
                    return label.length > 9 ? label.slice(0, 8) + '...' : label;
                }

                const absRotation = Math.abs(rotationAngle);
                const maxChars = Math.max(10 - Math.floor(absRotation / 30), 2);
                return label.length > maxChars ? label.slice(0, maxChars) + '...' : label;
            })
        if (properties?.chartType !== 'double' && properties?.chartType !== 'pie') {
            g.append('g')
                .attr('class', 'y-axis')
                .call(yAxis)
                .selectAll('.tick text')
                .attr('fill', option.yAxis.axisLabel.color || '#4b5563')
                .style('font-size', `${option.yAxis.axisLabel.fontSize || 13}px`)
                .style('font-family', option.yAxis.axisLabel.fontFamily || 'Verdana')

            g.append('text')
                .attr('class', 'y-axis-label')
                .attr('text-anchor', 'start')
                .attr('transform', `translate(${-margin.top / 2}, ${-margin.top / 2})`)
                .style('font-size', `${option.yAxis.axisLabel.fontSize - 1 || 13}px`)
                .style('font-family', option.yAxis.axisLabel.fontFamily || 'Verdana')
                .style('fill', option.yAxis.axisLabel.color || '#4b5563')
                .text(function () {
                    const label = properties?.yAxis?.toString() || '';
                    return label.length > 10 ? label.slice(0, 8) + '...' : label;
                });
        }
        properties?.zAxis?.forEach((item, index) => {
            const newName = item;
            let maxStackHeight = 0;
            option.xAxis.data.forEach((_, i) => {
                let stackSum = 0;
                option.series.filter(s => s.type === 'bar' && s.category === newName).forEach(series => {
                    stackSum += series.data[i];
                });
                maxStackHeight = Math.max(maxStackHeight, stackSum);
            });
            const zAxisScale = d3.scaleLinear()
                .domain([
                    option.yAxis.min || 0,
                    properties?.chartType === 'stacked' ?
                        maxStackHeight
                        : d3.max(option.series?.filter((d) => d?.category === newName), s => d3.max(s.data))
                ])
                .nice()
                .range([height, 0]);
            // Create the axis for each z-axis value
            const zAxisItem = properties?.chartType === 'double' && index === 0 ?
                d3.axisLeft(zAxisScale)
                    .ticks(height)
                    .tickValues(d3.range(
                        Math.ceil((option.yAxis.min || 0) / option.yAxis.interval) * option.yAxis.interval,
                        properties?.chartType === 'stacked' ?
                            maxStackHeight + (option.yAxis.interval % 2 === 0 && maxStackHeight % 2 !== 0 ? 1 : 1)
                            : d3.max(option.series?.filter((d) => d?.category === newName), s => d3.max(s.data)) + (option.yAxis.interval % 2 === 0 && d3.max(option.series?.filter((d) => d?.category === newName), s => d3.max(s.data)) % 2 !== 0 ? 1 : 1),
                        option.yAxis.interval
                    ))
                    .tickFormat(d3.format('d'))
                : d3.axisRight(zAxisScale)
                    .ticks(height)
                    .tickValues(d3.range(
                        Math.ceil((option.yAxis.min || 0) / option.yAxis.interval) * option.yAxis.interval,
                        properties?.chartType === 'stacked' ?
                            maxStackHeight + (option.yAxis.interval % 2 === 0 && maxStackHeight % 2 !== 0 ? 1 : 1)
                            : d3.max(option.series?.filter((d) => d?.category === newName), s => d3.max(s.data)) + (option.yAxis.interval % 2 === 0 && d3.max(option.series?.filter((d) => d?.category === newName), s => d3.max(s.data)) % 2 !== 0 ? 1 : 1),
                        option.yAxis.interval
                    ))
                    .tickFormat(d3.format('d'))

            const zAxisPosition = (properties?.chartType === 'double' ? (index - 1) * 70 : index * 70); // Adjust position as needed

            g.append('g')
                .attr('class', 'z-axis')
                .attr('transform', `translate(${properties?.chartType === 'double' && index === 0 ? 0 : width + zAxisPosition},0)`)  // Position at the desired location
                .call(zAxisItem)
                .selectAll('.tick text')
                .attr('fill', option.yAxis.axisLabel.color || '#4b5563')
                .style('font-size', `${option.yAxis.axisLabel.fontSize || 13}px`)
                .style('font-family', option.yAxis.axisLabel.fontFamily || 'Verdana');

            g.append('text')
                .attr('class', 'y-axis-label')
                .attr('text-anchor', 'start')
                .attr('transform', `translate(${(properties?.chartType === 'double' && index === 0 ? 0 : width + zAxisPosition)}, ${-margin.top / 2})`)  // Adjust positioning
                .style('font-size', `${option.yAxis.axisLabel.fontSize - 1 || 13}px`)
                .style('font-family', option.yAxis.axisLabel.fontFamily || 'Verdana')
                .style('fill', option.yAxis.axisLabel.color || '#4b5563')
                .text(function () {
                    const label = newName?.toString() || ''; // Get the y-axis label as a string
                    return label.length > 10 ? label.slice(0, 8) + '...' : label; // Truncate if longer than 10 characters
                });
        });
        if (properties?.chartType === 'barWithLine') {
            g.append('g')
                .attr('class', 'z-axis')
                .attr('transform', `translate(${width},0)`)  // Position at the desired location
                .call(line)
                .selectAll('.tick text')
                .attr('fill', option.yAxis.axisLabel.color || '#4b5563')
                .style('font-size', `${option.yAxis.axisLabel.fontSize || 13}px`)
                .style('font-family', option.yAxis.axisLabel.fontFamily || 'Verdana');

            g.append('text')
                .attr('class', 'y-axis-label')
                .attr('text-anchor', 'middle')
                .attr('transform', `translate(${(width)}, ${-margin.top / 2})`)  // Adjust positioning
                .style('font-size', `${option.yAxis.axisLabel.fontSize - 2 || 13}px`)
                .style('font-family', option.yAxis.axisLabel.fontFamily || 'Verdana')
                .style('fill', option.yAxis.axisLabel.color || '#4b5563')
                // .text(`${properties?.line}`)
                .text(function () {
                    const label = properties?.line?.toString() || ''; // Get the y-axis label as a string
                    return label.length > 10 ? label.slice(0, 8) + '...' : label; // Truncate if longer than 10 characters
                });
        }
        const result = properties?.zAxis?.map((item, index) => {
            const zAxisScale = d3.scaleLinear()
                .domain([
                    option.yAxis.min || 0,
                    d3.max(option.series?.filter((d) => d?.category === item), s => d3.max(s.data))
                ])
                .nice()
                .range([height, 0]);
            return { [item]: zAxisScale }
        });
        if (properties?.chartType === 'bar' || properties?.chartType === 'barWithLine' || properties?.chartType === 'double') {
            option.series.filter(s => s.type === 'bar' && s?.category === properties?.yAxis).forEach(series => {
                g.selectAll(`.bar-${escapeClassName(series.name)}`)
                    .data(option.xAxis.data.map((_, i) => ({
                        key: i,
                        value: series.data[i],
                        name: series.name
                    })))
                    .enter().append('rect')
                    .attr('class', `bar-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('x', d => x0Scale(option.xAxis.data[d.key]) + x1(escapeClassName(series.name)))
                    .attr('y', height)
                    .attr('width', x1.bandwidth())
                    .attr('height', 0)
                    .attr('fill', series.itemStyle.color || '#000')
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', handleMouseOver)
                    .on('mouseout', handleMouseOut)
                    .transition()  // Add transition for animation
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation: ;
                    .attr('y', d => yScale(d.value))  // Move the bar to its final position
                    .attr('height', d => height - yScale(d.value));
            });
            option.series.filter(s => s.type === 'bar' && properties?.zAxis?.includes(s?.category)).forEach(series => {
                const CatName = result.find((item) => item[series?.category])[series?.category]
                g.selectAll(`.bar-${escapeClassName(series.name)}`)
                    .data(option.xAxis.data.map((_, i) => ({
                        key: i,
                        value: series.data[i],
                        name: series.name
                    })))
                    .enter().append('rect')
                    .attr('class', `bar-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('x', d => x0Scale(option.xAxis.data[d.key]) + x1(escapeClassName(series.name)))
                    .attr('y', height)
                    .attr('width', x1.bandwidth())
                    .attr('height', 0)
                    .attr('fill', series.itemStyle.color || '#000')
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', handleMouseOver)
                    .on('mouseout', handleMouseOut)
                    .transition()  // Add transition for animation
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation
                    .attr('y', d => CatName(d.value))  // Move the bar to its final position
                    .attr('height', d => height - CatName(d.value));  // 
            });
            option.series.filter(s => s.type === 'line').forEach((series, index) => {
                const line = d3.line()
                    .x((d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)
                    .y(d => lineScale(d))
                    .curve(d3.curveMonotoneX);
                // Append the line path
                const path = g.append('path')
                    .data([series.data])
                    .attr('class', `line-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('fill', 'none')
                    .attr('stroke', series.itemStyle.color || '#000')
                    .attr('stroke-width', 2)
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .attr('d', line);

                // Animate the line path
                const totalLength = path.node().getTotalLength();  // Get the total length of the line path

                path.attr('stroke-dasharray', `${totalLength} ${totalLength}`)  // Start with the whole line invisible
                    .attr('stroke-dashoffset', totalLength)
                    .transition()
                    .duration(isAnimated ? 1000 : 0)
                    .ease(d3.easeLinear)
                    .attr('stroke-dashoffset', 0);

                g.selectAll(`.dot-${escapeClassName(series.name)}`)
                    .data(series.data)
                    .enter().append('circle')
                    .attr('class', `dot-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('cx', (d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)
                    .attr('cy', d => lineScale(d))
                    .attr('r', 0)
                    .attr('fill', series.itemStyle.color || '#000')
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .transition()
                    .duration(isAnimated ? 1000 : 0)
                    .attr('r', 3);
            });

        } else if (properties?.chartType === 'pie') {
            const radius = Math.min(width, height) / 2;
            const color = d3.scaleOrdinal(d3.schemeCategory10);
            const pie = d3.pie().value(d => d.value);
            const arc = d3.arc().outerRadius(radius - 10).innerRadius(0);

            const pieGroup = g.append('g')
                .attr('transform', `translate(${width / 2},${height / 2})`);

            const initialArc = d3.arc()
                .startAngle(0)
                .endAngle(0);

            pieGroup.selectAll('path')
                .data(pie(option.series[0].data))
                .enter().append('path')
                .attr('class', `cursor-pointer`)
                // .attr('d', arc)
                .attr('d', initialArc)
                .attr('fill', d => d.data.itemStyle?.color || color(d.index))
                .attr('stroke', '#fff')
                .attr('stroke-width', '1px')
                .attr('visibility', d => legendVisibility[d.data.name] ? 'visible' : 'hidden')
                .on('mouseover', handleMouseOver)
                .on('mouseout', handleMouseOut)
                .transition()  // Add transition for animation
                .duration(isAnimated ? 1000 : 0) // Duration of the animation
                .attrTween('d', function (d) {
                    const interpolate = d3.interpolate(
                        { startAngle: 0, endAngle: 0 },  // Start with both angles at 0
                        { startAngle: d.startAngle, endAngle: d.endAngle }  // Transition to final angles
                    );
                    return function (t) {
                        return arc(interpolate(t));  // Interpolate the arc path
                    };
                });

            if (properties?.xAxis) {
                svg.append('text')
                    .attr('class', 'x-axis-label')
                    .attr('x', width / 2)  // Center horizontally
                    .attr('y', height / 2 + radius + 50)
                    .style('text-align', 'center')
                    .text(properties.xAxis)
                    .style('font-size', '13px')
            }
            svg.selectAll('.x-axis').remove();
            svg.selectAll('.y-axis').remove();
        } else if (properties?.chartType === 'area') {
            option.series.filter(s => s.type === 'bar' && s?.category === properties?.yAxis).forEach(series => {
                const area = d3.area()
                    .x((d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)
                    .y0(height)
                    .y1(d => yScale(d))
                    .curve(d3.curveMonotoneX);

                g.append('path')
                    .data([series.data])
                    .attr('class', `area-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('d', area)
                    .attr('fill', d3.color(series.itemStyle.color).copy({ opacity: 0.7 }))
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut)
                    .attr('opacity', 0)  // Start with opacity 0 for animation
                    .transition()
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation
                    .attr('opacity', 0.7);

                const line = d3.line()
                    .x((d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)
                    .y(d => yScale(d))
                    .curve(d3.curveMonotoneX);

                g.append('path')
                    .data([series.data])
                    .attr('class', `line-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('d', line)
                    .attr('fill', 'none')
                    .attr('stroke', series.itemStyle.color || '#000')
                    .attr('stroke-width', 2)
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut)
                    .attr('stroke-dasharray', function () {
                        const length = this.getTotalLength();
                        return `${length} ${length}`;
                    })
                    .attr('stroke-dashoffset', function () {
                        return this.getTotalLength();
                    })
                    .transition()
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation
                    .attr('stroke-dashoffset', 0);

                g.selectAll(`.dot-${escapeClassName(series.name)}`)
                    .data(series.data)
                    .enter().append('circle')
                    .attr('class', `dot-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('cx', (d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)
                    .attr('cy', d => yScale(d))
                    .attr('r', 0)
                    .attr('fill', series.itemStyle.color || '#000')
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut)
                    .transition()
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation: ;
                    .attr('r', 3);
            });
            option.series.filter(s => s.type === 'bar' && properties?.zAxis?.includes(s?.category)).forEach(series => {
                const CatName = result.find((item) => item[series?.category])[series?.category]
                const area = d3.area()
                    .x((d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)
                    .y0(height)
                    .y1(d => CatName(d))
                    .curve(d3.curveMonotoneX);

                g.append('path')
                    .data([series.data])
                    .attr('class', `area-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('d', area)
                    .attr('fill', d3.color(series.itemStyle.color).copy({ opacity: 0.7 }))
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut)
                    .attr('opacity', 0)  // Start with opacity 0 for animation
                    .transition()
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation
                    .attr('opacity', 0.7);

                const line = d3.line()
                    .x((d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)
                    .y(d => CatName(d))
                    .curve(d3.curveMonotoneX);

                g.append('path')
                    .data([series.data])
                    .attr('class', `line-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('d', line)
                    .attr('fill', 'none')
                    .attr('stroke', series.itemStyle.color || '#000')
                    .attr('stroke-width', 2)
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut)
                    .attr('stroke-dasharray', function () {
                        const length = this.getTotalLength();
                        return `${length} ${length}`;
                    })
                    .attr('stroke-dashoffset', function () {
                        return this.getTotalLength();
                    })
                    .transition()
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation
                    .attr('stroke-dashoffset', 0);

                g.selectAll(`.dot-${escapeClassName(series.name)}`)
                    .data(series.data)
                    .enter().append('circle')
                    .attr('class', `dot-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('cx', (d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)
                    .attr('cy', d => CatName(d))
                    // .attr('r', 3)
                    .attr('r', 0)
                    .attr('fill', series.itemStyle.color || '#000')
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut)
                    .transition()
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation
                    .attr('r', 3);
            });
        } else if (properties?.chartType === 'scatter') {
            option.series.filter(s => s.type === 'bar' && s?.category === properties?.yAxis).forEach(series => {
                // Append circles for each data point in the scatter plot
                const scatterPoints = g.selectAll(`.scatter-${escapeClassName(series.name)}`)
                    .data(series.data)
                    .enter().append('circle')
                    .attr('class', `scatter-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('cx', (d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)  // X axis positions
                    .attr('cy', height)  // Start from the bottom (or another position for animation)
                    .attr('r', 0)  // Start with radius 0 for animation
                    .attr('fill', series.itemStyle.color || '#000')  // Fill color of the points
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut);

                // Animate the circles (scatter points)
                scatterPoints.transition()
                    .duration(isAnimated ? 1000 : 0)  // Animation duration
                    .ease(d3.easeBounceOut)  // Choose the easing function
                    .attr('cy', d => yScale(d))  // Final Y axis positions
                    .attr('r', 4);  // Final radius
            });

            option.series.filter(s => s.type === 'bar' && properties?.zAxis?.includes(s?.category)).forEach(series => {
                const CatName = result.find((item) => item[series?.category])[series?.category];

                // Append circles for the zAxis category
                const scatterPointsZAxis = g.selectAll(`.scatter-${escapeClassName(series.name)}`)
                    .data(series.data)
                    .enter().append('circle')
                    .attr('class', `scatter-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('cx', (d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)  // X axis positions
                    .attr('cy', height)  // Start from the bottom for animation
                    .attr('r', 0)  // Start with radius 0
                    .attr('fill', series.itemStyle.color || '#000')  // Fill color of the points
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut);

                // Animate the circles
                scatterPointsZAxis.transition()
                    .duration(isAnimated ? 1000 : 0)  // Animation duration
                    .ease(d3.easeBounceOut)  // Easing function
                    .attr('cy', d => CatName(d))  // Final Y axis positions for zAxis category
                    .attr('r', 4);  // Final radius
            });
        }
        else if (properties?.chartType === 'line') {
            option.series.filter(s => s.type === 'bar' && s?.category === properties?.yAxis).forEach(series => {
                // Create the line generator
                const line = d3.line()
                    .x((d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)  // X axis positions
                    .y(d => yScale(d))
                    .curve(d3.curveMonotoneX);

                g.append('path')
                    .data([series.data])
                    .attr('class', `line-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('d', line)
                    .attr('fill', 'none')
                    .attr('stroke', series.itemStyle.color || '#000')
                    .attr('stroke-width', 2)
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut)
                    .attr('stroke-dasharray', function () {
                        const length = this.getTotalLength();
                        return `${length} ${length}`;
                    })
                    .attr('stroke-dashoffset', function () {
                        return this.getTotalLength();
                    })
                    .transition()
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation: ;
                    .attr('stroke-dashoffset', 0)

                // Add circles at each data point on the line
                g.selectAll(`.dot-${escapeClassName(series.name)}`)
                    .data(series.data)
                    .enter().append('circle')
                    .attr('class', `dot-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('cx', (d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)
                    .attr('cy', d => yScale(d))
                    .attr('r', 0)  // Radius of the circle
                    .attr('fill', series.itemStyle.color || '#000')
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut)
                    .transition()
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation
                    .attr('r', 3);
            });
            option.series.filter(s => s.type === 'bar' && properties?.zAxis?.includes(s?.category)).forEach(series => {
                const CatName = result.find((item) => item[series?.category])[series?.category]
                const line = d3.line()
                    .x((d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)  // X axis positions
                    .y(d => CatName(d))
                    .curve(d3.curveMonotoneX);
                // Y axis positions based on data

                // Append the line path for each bar series
                g.append('path')
                    .data([series.data])
                    .attr('class', `line-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('d', line)
                    .attr('fill', 'none')
                    .attr('stroke', series.itemStyle.color || '#000')
                    .attr('stroke-width', 2)
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut)
                    .attr('stroke-dasharray', function () {
                        const length = this.getTotalLength();
                        return `${length} ${length}`;
                    })
                    .attr('stroke-dashoffset', function () {
                        return this.getTotalLength();
                    })
                    .transition()
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation: ;
                    .attr('stroke-dashoffset', 0)

                // Add circles at each data point on the line
                g.selectAll(`.dot-${escapeClassName(series.name)}`)
                    .data(series.data)
                    .enter().append('circle')
                    .attr('class', `dot-${escapeClassName(series.name)} cursor-pointer`)
                    .attr('cx', (d, i) => x0Scale(option.xAxis.data[i]) + x0Scale.bandwidth() / 2)
                    .attr('cy', d => CatName(d))
                    .attr('r', 3)  // Radius of the circle
                    .attr('fill', series.itemStyle.color || '#000')
                    .attr('visibility', legendVisibility[series.name] ? 'visible' : 'hidden')
                    .on('mouseover', (event, d) => handleLineMouseOver(event, d, x0Scale, series?.name))
                    .on('mouseout', handleMouseOut);
            });
        } else if (properties?.chartType === 'stacked') {
            const categories = {};
            option.series.forEach(series => {
                if (!categories[series.category]) {
                    categories[series.category] = [];
                }
                categories[series.category].push(series);
            });

            // Total number of categories
            const totalCategories = Object.keys(categories).length;

            // Iterate over each category and stack the data
            Object.keys(categories).forEach((category, categoryIndex) => {
                const categorySeries = categories[category];

                // Create the stack for the current category
                const stack = d3.stack()
                    .keys(categorySeries.map(s => escapeClassName(s.name)))
                    .order(d3.stackOrderNone)
                    .offset(d3.stackOffsetNone);

                // Prepare the data for stacking
                const stackedData = option.xAxis.data.map((_, i) => {
                    const entry = {};
                    categorySeries.forEach(series => {
                        entry[escapeClassName(series.name)] = series.data[i];
                    });
                    return entry;
                });

                const stackedCategoryData = stack(stackedData);

                // Draw the stacked bars for the current category
                const totalSeries = categorySeries.length; // Number of series in the current category

                g.selectAll(`.series-${categoryIndex}`)
                    .data(stackedCategoryData)
                    .enter().append('g')
                    .attr('class', `series-${categoryIndex} cursor-pointer`)
                    .selectAll('rect')
                    .data(d => d)
                    .enter().append('rect')
                    .attr('x', (d, i) => {
                        // Calculate the x-position dynamically
                        const categoryWidth = x0Scale.bandwidth() / totalCategories; // Divide space by number of categories
                        const barXPosition = x0Scale(option.xAxis.data[i]) + categoryIndex * categoryWidth;
                        return barXPosition;
                    })
                    // .attr('y', d => yScale(d[1]))
                    .attr('y', height)
                    .attr('height', 0)
                    .attr('width', x0Scale.bandwidth() / totalCategories)  // Each category occupies equal space
                    // .attr('height', d => yScale(d[0]) - yScale(d[1]))
                    .attr('fill', (d, i) => {
                        const seriesIndex = stackedCategoryData.findIndex(series => series.includes(d));
                        return categorySeries[seriesIndex]?.itemStyle?.color || '#000';
                    })
                    .attr('visibility', (d, i) => {
                        const seriesName = categorySeries[stackedCategoryData.findIndex(series => series.includes(d))].name;
                        return legendVisibility[seriesName] ? 'visible' : 'hidden';
                    })
                    .on('mouseover', handleMouseOver)
                    .on('mouseout', handleMouseOut)
                    .transition()  // Add transition for new bars
                    .duration(isAnimated ? 1000 : 0)  // Duration of the animation
                    .attr('y', d => yScale(d[1]))  // Move to final position
                    .attr('height', d => yScale(d[0]) - yScale(d[1]));
            });
        }
        // Remove any existing legends
        d3.select(legendRef.current).selectAll('*').remove();
        const legendSvg = d3.select(legendRef.current)
            .append('svg')
            //.attr('position', 'absolute')
            // .attr('height', 500)
            .append('g')
            .attr('class', 'legend-container')
            .attr('transform', `translate(0,0)`);
        // legendSvg.append('rect')
        //     .attr('class', 'legend-background')
        //     .attr('width', 250)
        //     .attr('height', 500)
        //     .style('fill', 'none')
        //     .style('stroke', '#ccc')
        //     .style('stroke-width', '1px');
        const legendGroups = d3.group(option.series, d => d.category);
        let currentYPosition = 10;

        const legend = legendSvg.selectAll('.legend')
            .data(option.legend.data)
            .enter().append('g')
            .attr('class', 'legend')
            .attr('transform', (d, i) => `translate(0, ${i * 24})`)
            .on('click', (event, d) => {
                toggleLegendVisibility(d);
                generateChart();
            });
        if (properties?.chartType !== 'pie') {
            legendGroups.forEach((seriesInCategory, categoryName) => {
                legendSvg.append('text')
                    .attr('x', 0)
                    .attr('y', currentYPosition)
                    .attr('dy', '.35em')
                    .style('text-anchor', 'start')
                    .style('font-size', '13px')
                    .style('font-weight', 'bold')
                    .style('fill', '#6b7280')
                    .text(categoryName);

                currentYPosition += 20;
                seriesInCategory.forEach((series, i) => {
                    const legendGroup = legendSvg.append('g')
                        .attr('class', 'legend')
                        .attr('transform', `translate(0, ${currentYPosition})`)
                        .on('click', (event, d) => {
                            toggleLegendVisibility(series.name);
                            generateChart();
                        });

                    if (series.type === 'line' || properties?.chartType === 'line') {
                        legendGroup.append('path')
                            .attr('d', 'M 0 9 L 18 9')
                            .style('cursor', 'pointer')
                            .style('stroke', series.itemStyle.color || '#000')
                            .attr('opacity', d => legendVisibility[series.name] ? 1 : 0.3)
                            .style('stroke-width', 4);
                    } else {
                        legendGroup.append('rect')
                            .attr('x', 0)
                            .style('cursor', 'pointer')
                            .attr('width', 18)
                            .attr('height', 18)
                            .attr('opacity', d => legendVisibility[series.name] ? 1 : 0.4)
                            .style('fill', series.itemStyle.color || '#000');
                    }
                    legendGroup.append('text')
                        .attr('x', 25)
                        .attr('y', 9)
                        .style('cursor', 'pointer')
                        .attr('dy', '.35em')
                        .style('text-anchor', 'start')
                        .style('font-size', '12px')
                        .attr('opacity', d => legendVisibility[series.name] ? 1 : 0.4)
                        .text(series.name);
                    currentYPosition += 24;
                });
                currentYPosition += 10;
            });
        } else {
            legend.each(function (d) {
                const g = d3.select(this);
                const series = properties?.chartType === 'pie'
                    ? option?.series[0]?.data.find(s => s.name === d)
                    : option.series.find(s => s.name === d);

                if (series) {
                    if (series.type === 'line' || properties?.chartType === 'line') {
                        g.append('path')
                            .attr('d', 'M 0 9 L 18 9')
                            .style('stroke', series.itemStyle.color || '#000')
                            .attr('opacity', d => legendVisibility[d] ? 1 : 0.3)
                            .style('stroke-width', 4);
                    } else if (properties.chartType === 'pie') {
                        g.append('rect')
                            .attr('x', 0)
                            .attr('width', 18)
                            .attr('height', 18)
                            .style('cursor', 'pointer')
                            .attr('opacity', d => legendVisibility[d] ? 1 : 0.4)
                            .style('fill', series.itemStyle?.color || '#000');
                    } else {
                        g.append('rect')
                            .attr('x', 0)
                            .attr('width', 18)
                            .attr('height', 18)
                            .attr('opacity', d => legendVisibility[d] ? 1 : 0.4)
                            .style('fill', series.itemStyle.color || '#000');
                    }
                    g.append('text')
                        .attr('x', 25)
                        .attr('y', 9)
                        .style('cursor', 'pointer')
                        .attr('dy', '.35em')
                        .style('text-anchor', 'start')
                        .style('font-size', '12px')
                        .attr('opacity', d => legendVisibility[d] ? 1 : 0.4)
                        .text(d);
                }
            });
        }
        const legendHeight = d3.select(legendRef.current).select('svg').select('g.legend-container').node().getBBox().height;
        d3.select(legendRef.current).select('svg').attr('height', legendHeight + 20); // Adding some padding if needed
        adjustContainerHeight();

        // Optional: Re-adjust the height if the window is resized
        // window.addEventListener('resize', adjustContainerHeight);

        // return () => {
        //     window.removeEventListener('resize', adjustContainerHeight);
        // };
    }
    useEffect(() => {
        try {
            generateChart();
        } catch (err) {
            console.log(err)
            showAlert("Cannot generate chart.Please try again.", "error");
        }
    }, [option, properties, isChartView, isTableView, marginBottom, legendVisibility]);

    useEffect(() => {
        setLegendVisibility(option.legend.data.reduce((acc, item) => ({ ...acc, [item]: true }), {}));
    }, [option]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            border: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
            backgroundColor: `${properties?.option?.backgroundColor}`
        }}>
            <span
                className='pb-1'
                style={{
                    width: '200px', // Width for the legend container
                    backgroundColor: `${properties?.option?.backgroundColor}`,
                    //borderRight: '1px solid #ccc',
                }}>
                <p className='m-0 font-semibold'
                    style={{
                        textAlign: 'center',
                        color: "#0a3a67",
                        fontSize: '14px'
                        // color: ${properties?.option?.xAxis?.axisLabel?.color}
                    }}>
                    {properties?.name}
                </p>
                <div id="legend-container" style={{ overflowY: 'auto' }} />
            </span>
            <div
                className='overflow-auto custom-scrollbar'
                style={{ flex: 1, position: 'relative', width: '100%', display: 'flex' }}>
                <svg ref={chartRef} style={{ width: '100%', height: '100%' }} />
                <div
                    ref={legendRef}
                    className='no-scrollbar'
                    style={{
                        //  position: 'absolute',
                        width: '200px',
                        maxHeight: '500px', // Adjust based on your needs
                        overflowY: 'auto',
                        borderLeft: '1px solid #ccc',
                        paddingLeft: '10px',
                        height: chartRef?.current?.clientHeight ? chartRef?.current?.clientHeight : '100%',
                        right: 0,
                        //display:'none'
                        overflowX: 'auto',
                    }}
                ></div>
                {tooltip.visible && (
                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            left: tooltip.left,
                            top: tooltip.top,
                            backgroundColor: '#fff',
                            // border: '1px solid #ccc',
                            border: `1px solid ${tooltip.borderColor}`,
                            padding: '8px',
                            pointerEvents: 'none',
                            fontSize: "12px",
                            borderRadius: '5px',
                            fontWeight: 600,
                            color: "#374151",
                        }}
                    >
                        <span className='pb-1'>
                            <CircleIcon sx={{ color: tooltip?.borderColor, fontSize: '12px' }} />{tooltip.name !== "" ? tooltip?.name : ""}
                        </span>
                        <span className='ml-5'>{tooltip.content}</span>
                    </div>
                )}
            </div>
        </div >
    );
};
export default ChartComponent;
