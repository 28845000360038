import React from "react";
import LeftArrowSvg from "../../../assets/svgs/LeftArrowSvg";
import { PlayArrow } from "@mui/icons-material";
import classNames from "classnames";
import Bolt from "../../../assets/svgs/Bolt";

const SchedulingSettingsFooter = ({
  option,
  providers,
  handleClick,
  title,
  hidePrevious,
}) => {
  const disabledByProviders = () => {
    if (providers.length === 0) return true;

    let flag = false;

    for (let i = 0; i < providers.length; i++) {
      if (
        providers[i].workingDays === null ||
        providers[i].workingDays.length === 0 ||
        providers[i].visitsPerDay === null ||
        providers[i].locationId === null
      ) {
        flag = true;
        break;
      }
    }

    return flag;
  };

  const configPending = disabledByProviders();

  return (
    <div className="absolute bottom-0 flex z-50 bg-white border-t-[1px] h-fit pt-6 border-t-secondarygrayborder border-none w-full items-center justify-center">
      {/* <div className="absolute inset-0 z-10 h-full bg-black bg-opacity-50" /> */}
      <div className="flex flex-col items-center justify-center w-full gap-2 pb-2 md:flex-row md:gap-0 md:pb-0 xs:px-2 md:px-6">
        {!hidePrevious && (
          <button
            onClick={() => {
              handleClick(option - 1);
            }}
            disabled={option === 0}
            className={classNames(
              "items-center gap-2 px-3 py-2 border rounded-lg shadow-md disabled:opacity-40 flex flex-row"
            )}
          >
            <LeftArrowSvg />
            <p className="m-0 text-sm">Previous</p>
          </button>
        )}
        {/* <div>
          <p className="m-0 text-xs text-gray-600">
            You've selected {providers.length} providers
          </p>
          {providers.length > 0 && configPending && (
            <p className="m-0 text-xs text-primaryred">
              Please complete providers configuration to generate the schedules.
            </p>
          )}
        </div> */}
        {title === "GENERATE SCHEDULES" ? (
          <div className="relative flex flex-col items-center justify-center gap-2">
            <button
              onClick={() => {
                handleClick(option + 1);
              }}
              disabled={option === 2 || disabledByProviders()}
              className="relative flex flex-row items-center justify-center gap-2 border rounded-lg shadow-md w-80 h-14 disabled:opacity-40 bg-primarygreen"
            >
              <div className="absolute left-6">
                <Bolt color={"#FFF"} />
              </div>
              <p className="m-0 text-lg font-bold text-white">Run Scheduler</p>
            </button>
            {providers.length > 0 && configPending && (
              <p className="absolute m-0 text-xs -bottom-6 text-primaryred whitespace-nowrap">
                Please complete providers configuration to generate the
                schedules.
              </p>
            )}
          </div>
        ) : (
          <button
            onClick={() => {
              handleClick(option + 1);
            }}
            disabled={option === 2 || disabledByProviders()}
            className="flex flex-row items-center gap-2 px-3 py-2 border rounded-lg shadow-md disabled:opacity-40 bg-primarypurple"
          >
            <div className="relative flex flex-row">
              <PlayArrow
                style={{
                  color: "#fff",
                  width: "18px",
                  height: "18px",
                  objectFit: "contain",
                }}
              />
              <PlayArrow
                style={{
                  color: "#fff",
                  width: "18px",
                  height: "18px",
                  objectFit: "contain",
                  position: "absolute",
                  left: -7,
                }}
              />
            </div>
            <p className="m-0 text-sm font-bold text-white">{title}</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default SchedulingSettingsFooter;
