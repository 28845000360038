import React, { useEffect } from "react";
import Logo from "../../../assets/images/CareSMS_Logo.png";
import { PlayArrow } from "@mui/icons-material";
import { useVerifyOtpMutation } from "../../../features/auth/authApiSlice";

const VerifyEmail = ({ step, email, handleStepChange }) => {
  const [errMsg, setErrMsg] = React.useState(null);
  const [otp, setOtp] = React.useState(null);

  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();

  const handleOtp = async () => {
    try {
      await verifyOtp({
        email,
        otp,
      }).unwrap();

      handleStepChange(step + 1);
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    setErrMsg(null);
  }, [otp]);

  return (
    <div className="relative flex flex-col w-full gap-8 mt-8 sm:w-11/12 sm:mt-16 base:mt-36">
      <div className="flex flex-col w-full gap-12 mr-auto">
        <div className="flex flex-col w-full gap-8">
          <img src={Logo} alt="logo" className="-ml-2 logo" />
          <div className="flex flex-col w-full gap-2 m-0 text-left">
            <h1 className="m-0 text-2xl font-bold">
              Verify your email address
            </h1>
            <h2 className="m-0 text-base font-normal">
              You have been sent a verification email
            </h2>
          </div>
        </div>
        <div className="flex flex-col w-full gap-8 sm:gap-12">
          <div className="flex flex-col gap-2">
            <label htmlFor="email" className="text-sm font-semibold">
              OTP
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="text"
              name="text"
              className="p-2 text-sm border rounded-lg border-primarylightgrey"
              placeholder="Enter OTP here"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            {errMsg && <p className="m-0 text-xs text-danger">{errMsg}</p>}
          </div>
          <button
            onClick={handleOtp}
            disabled={isLoading}
            className="flex items-center justify-center w-full gap-2 p-2 font-semibold text-white rounded-lg bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
          >
            Verify
            <div className="relative flex flex-row">
              <PlayArrow
                style={{
                  color: "#fff",
                  width: "18px",
                  height: "18px",
                  objectFit: "contain",
                }}
              />
              <PlayArrow
                style={{
                  color: "#fff",
                  width: "18px",
                  height: "18px",
                  objectFit: "contain",
                  position: "absolute",
                  left: -5,
                }}
              />
            </div>
          </button>
          <p className="m-0 text-center">OR</p>
          <button
            onClick={() => handleStepChange(step - 1)}
            className="flex items-center justify-center w-full gap-2 p-2 font-semibold text-black bg-white rounded-lg shadow-sm"
          >
            Go back to previous step
          </button>
          <p className="w-full mt-12 text-xs text-center">
            2024 CareSMS Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
