import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Steps from "../components/Steps";
import Content from "../components/Content";
import StepButtons from "../components/StepButtons";
import ProviderParameterRight from "./ProviderParameterRight";

const ProviderParameter = ({
  handleStepChange,
  step,
  providers,
  setProviders,
}) => {
  const [opaque, setOpaque] = useState(false);
  return (
    <div className="relative flex flex-col h-screen px-6 pt-8">
      <Header setOpaque={setOpaque} />
      <Steps step={2} total={6} />
      <Content
        header={"Set your Provider Availability"}
        content={`Below are the active Providers that we read from your Patient Care Plan
        data upload. To accurately create optimized daily visit schedules for
        each Provider, please configure their working days and visit targets
        below.`}
        note={`You will have the ability
        to update these settings and re-run the CareSMS Intelligent Scheduler at
        any time.`}
      />
      <div className="flex m-1 grow">
        <ProviderParameterRight
          providers={providers}
          setProviders={setProviders}
        />
      </div>
      <StepButtons
        primaryText={"Step 3: Scheduling Rules"}
        secondaryText={"Go back to previous step"}
        primaryClick={() => handleStepChange(step + 1)}
        secondaryClick={() => handleStepChange(step - 1)}
      />
      <Footer />
    </div>
  );
};

export default ProviderParameter;
