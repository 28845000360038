import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Steps from "../components/Steps";
import Content from "../components/Content";
import StepButtons from "../components/StepButtons";
import classNames from "classnames";
import VisitRules from "../../Schedules/Configure/VisitRules";

const SchedulingRules = ({ handleStepChange, step, isFileUpload }) => {
  const [opaque, setOpaque] = useState(false);
  return (
    <div className="flex flex-col h-screen px-6 pt-8 relative">
      
      <Header setOpaque={setOpaque} />
      <Steps step={3} total={6} />
      <Content
        header={"Patient and Community Scheduling Rules"}
        content={`Here are the Patients and Communities that were identified in your Patient Care Plan data upload. If any of your Communities or Patients in private residences have a scheduling rule, then enter them below. The CareSMS Intelligent Scheduler will account for each rule when creating your daily Provider visit schedules.`}
        note={`You will have the ability to update these rules and re-run the CareSMS Intelligent Scheduler at any time.`}
      />
      <div className="flex grow">
        <VisitRules isWelcomePage={true} isFileUpload={isFileUpload} />
      </div>
      <StepButtons
        primaryText={"Step 4: Configure Appointment Types"}
        secondaryText={"Go back to previous step"}
        primaryClick={() => handleStepChange(step + 1)}
        secondaryClick={() => handleStepChange(step - 1)}
      />
      <Footer />
    </div>
  );
};

export default SchedulingRules;
