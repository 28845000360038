import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Steps from "../components/Steps";
import Content from "../components/Content";
import StepButtons from "../components/StepButtons";
import AppointmentTypes from "../../Schedules/Configure/AppointmentTypes";

const AppointmentType = ({
  handleStepChange,
  step,
  appointmentTypes,
  setAppointmentTypes,
}) => {
  const [opaque, setOpaque] = useState(false);
  return (
    <div className="relative flex flex-col h-screen px-6 pt-8">
      
      <Header setOpaque={setOpaque} />
      <Steps step={4} total={6} />
      <Content
        header={"Configure your Appointment Types"}
        content={`Here are the appointment types that were identified in your Patient Care Plan data upload. Your CareSMS Intelligent Scheduler Assistant will account for each appointment type’s rotation schedule (ie. 28 days) and days in advance (?) setting when building your daily visit schedules.`}
        note={`You will have the ability to update these appointment settings and re-run the CareSMS Intelligent Scheduler at any time.`}
      />
      <div className="flex m-4 grow">
        <AppointmentTypes
          isWelcomePage={true}
          appointmentTypes={appointmentTypes}
          setAppointmentTypes={setAppointmentTypes}
        />
      </div>
      <StepButtons
        primaryText={"Step 5: Confirm Settings and Run the Scheduler"}
        secondaryText={"Go back to previous step"}
        primaryClick={() => handleStepChange(step + 1)}
        secondaryClick={() => handleStepChange(step - 1)}
      />
      <Footer />
    </div>
  );
};

export default AppointmentType;
