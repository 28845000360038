import * as React from "react";

const NotTable = (props) => (
  <svg
    width={props?.width || '24'}
    height={props?.height || '24'}
    viewBox="0 0 24 24"
    fill={props?.fill || '#000'}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 2v3H5V5zm-5 14h-5v-9h5zM5 10h3v9H5zm12 9v-9h3v9z" fill="currentColor" />
    <path
      d="m2 2 21 20"
      stroke={props?.fill || '#000'}
      strokeWidth="2"
    />
  </svg>
);

export default NotTable;
