import React, { useState } from "react";
import AppointmentCard from "./AppointmentCard";
import { DropArea } from "./DropArea";
import classNames from "classnames";
import { useLSStore } from "../../../context/ls-store";
import AppointmentCardSkeleton from "./AppointmentCardSkeleton";

const DateColumn = ({
  id,
  info,
  cards,
  onDrop,
  addOpen,
  loaderVisible,
  isToday,
  publishOpen,
}) => {
  const filterOpen = useLSStore((state) => state.filterOpen);
  const selectedAppointment = useLSStore((state) => state.selectedAppointment);

  return (
    <div
      className={classNames(
        "flex flex-col min-h-[100vh] flex-1 border-r border-y min-w-64 border-primarygrayborder",
        (selectedAppointment !== null ||
          filterOpen === true ||
          addOpen === true ||
          publishOpen === true) &&
          "border-opacity-40",
        !info.isAvailable && "bg-red-100"
      )}
    >
      <div
        className={classNames(
          "flex flex-col items-center group justify-center py-6 relative",
          (selectedAppointment !== null ||
            filterOpen === true ||
            addOpen === true ||
            publishOpen === true) &&
            "opacity-40"
        )}
      >
        <p
          className={classNames(
            "m-0 text-lg",
            isToday ? "text-primaryblue" : "text-primaryblack",
            !info.isAvailable && "text-primaryred"
          )}
        >
          {info.day}
        </p>
        <p
          className={classNames(
            "m-0 text-2xl font-semibold",
            isToday ? "text-primaryblue" : "text-primaryblack",
            !info.isAvailable && "text-primaryred"
          )}
        >
          {info.date}
        </p>
        {/* {!info.isAvailable && (
          <p className="absolute m-0 text-xs font-light text-center translate-y-10 text-primaryred">
            Provider might not be available{" "}
            <span className="font-extrabold">*</span>
          </p>
        )} */}
      </div>
      <div
        className={classNames(
          "w-full h-[1px] bg-primarygrayborder",
          (selectedAppointment !== null ||
            filterOpen === true ||
            addOpen === true ||
            publishOpen === true) &&
            "opacity-40",
          !info.isAvailable && "h-[1px] bg-primaryred"
        )}
      />
      {loaderVisible ? (
        <div className="flex flex-col px-2 pb-3">
          <DropArea onDrop={() => onDrop(id, 0, info.isAvailable)} />
          {Array(7)
            .fill(0)
            .map((_, indx) => (
              <React.Fragment key={indx}>
                <AppointmentCardSkeleton indx={indx} />
                <DropArea onDrop={() => onDrop(id, indx + 1, info.isAvailable)} />
              </React.Fragment>
            ))}
        </div>
      ) : (
        <div className="flex flex-col px-2 pb-3">
          <DropArea onDrop={() => onDrop(id, 0, info.isAvailable)} />
          {cards &&
            cards.map((card, indx) => (
              <React.Fragment key={indx}>
                <AppointmentCard
                  {...card}
                  indx={indx}
                  addOpen={addOpen}
                  publishOpen={publishOpen}
                  appointment={card}
                />
                <DropArea onDrop={() => onDrop(id, indx + 1, info.isAvailable)} />
              </React.Fragment>
            ))}
        </div>
      )}
    </div>
  );
};

export default DateColumn;
