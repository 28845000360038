import React from "react";
import classNames from "classnames";
import { useFetchCommunitiesMutation } from "../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectToken, selectUser } from "../../../features/auth/authSlice";
import { Search_Icon } from "../../../assets/images";
import { ArrowDownwardTwoTone, ArrowUpwardTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../../assets";
import { Collapse } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { useLSStore } from "../../../context/ls-store";
import axios from "axios";

const fileTypes = ["csv", "xls", "xlsx"];

const CommunityList = () => {
  const [communities, setCommunities] = React.useState([]);

  const [searchFilter, setSearchFilter] = React.useState("");

  const [sortNOPOrder, setSortNOPOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");

  const user = useSelector(selectUser);
  const [fetchCommunities] = useFetchCommunitiesMutation();

  const navigate = useNavigate();

  const handleCommunityClick = (community) => {
    navigate(`/care-management/communities/${community.id}`);
  };

  const getProviders = async () => {
    try {
      const { data } = await fetchCommunities(user.organisation.id).unwrap();

      setCommunities(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  const handleSortNOP = (a, b) => {
    if (sortNOPOrder === "none") {
      return 0;
    } else if (sortNOPOrder === "asc") {
      return a.noOfPatients - b.noOfPatients;
    } else {
      return b.noOfPatients - a.noOfPatients;
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  React.useEffect(() => {
    getProviders();
  }, []);

  const Avatar = ({ name, image }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className="object-cover w-10 h-10 rounded-full"
            />
          </div>
        ) : (
          <p className="flex items-center justify-center w-10 h-10 m-0 text-white rounded-full bg-primaryblue">
            {name
              .split(" ")
              .map((n) => n[0])
              .slice(0, 2)
              .map((n) => n.toUpperCase())
              .join("")}
          </p>
        )}
      </>
    );
  };

  const setAlert = useLSStore((state) => state.setAlert);

  const token = useSelector(selectToken);

  async function uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/community/verify-communities-doc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": process.env.REACT_APP_SERVER_URL,
          },
          withCredentials: true,
        }
      );

      await getProviders();

      setOpenDropdown(false);

      setAlert({
        message: "Data Uploaded Successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (file) => {
    try {
      uploadFile(file);
    } catch (error) {
      console.log("err");
    }
  };

  const [openDropdown, setOpenDropdown] = React.useState(false);

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <div
        className={classNames(
          "w-full px-16 transition-all",
          !openDropdown && "pb-8"
        )}
      >
        <button
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
          className="flex flex-row items-center justify-between w-full px-4 py-2 bg-gray-100 rounded-md"
        >
          <div className="flex flex-row items-center">
            Upload Community Data
            <img
              src={Icons.Upload_Icon}
              alt="upload icon"
              className="mx-2 icon"
            />
          </div>
          {openDropdown ? <ArrowDownwardTwoTone /> : <ArrowUpwardTwoTone />}
        </button>
      </div>
      <Collapse in={openDropdown} className="w-full">
        <div className="flex flex-col w-full gap-1 px-16 py-8">
          <div className="flex flex-row items-end justify-end w-full mb-2 md:mb-0">
            <p
              className="m-0 text-sm underline text-primaryblue"
              onClick={() => {}}
              role="button"
            >
              Community Data Template
            </p>
          </div>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            classes="w-100"
          >
            <div className="flex hover:bg-subtleblue transition-all cursor-pointer flex-col w-full justify-center items-center gap-2 border-[1px] border-dashed border-secondarygrayborder rounded-lg p-4">
              <img
                src={Icons.Upload_Icon}
                alt="upload icon"
                className="mx-2 icon"
              />
              <p className="m-0 mt-2 text-sm">
                Drag & Drop or{" "}
                <span className="text-primaryblue">Choose file</span> to upload
              </p>
            </div>
          </FileUploader>
        </div>
      </Collapse>
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Communities{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {communities.length}
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <div className="relative w-1/3 flex flex-row items-center gap-2 rounded-md border-[2px] border-primarygrayborder overflow-clip">
              <div className="pl-2">
                <img
                  src={Search_Icon}
                  alt="search"
                  className="w-5 h-5 cursor-pointer search-icon opacity-80"
                />
              </div>
              <input
                type="text"
                placeholder="Search..."
                className="w-full h-full p-2 text-base outline-none"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <ArrowDownwardTwoTone />
                      ) : sortNameOrder === "desc" ? (
                        <ArrowUpwardTwoTone />
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">Address</th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">No of Patients</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNOPOrder === "none") {
                          setSortNOPOrder("asc");
                        }
                        if (sortNOPOrder === "asc") {
                          setSortNOPOrder("desc");
                        }
                        if (sortNOPOrder === "desc") {
                          setSortNOPOrder("none");
                        }
                      }}
                    >
                      {sortNOPOrder === "asc" ? (
                        <ArrowDownwardTwoTone />
                      ) : sortNOPOrder === "desc" ? (
                        <ArrowUpwardTwoTone />
                      ) : (
                        <div className="flex items-center h-4">
                          <div className="w-6 h-[2px] bg-white" />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {communities
                .filter((community) =>
                  community.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
                )
                .sort((a, b) => handleSortNOP(a, b))
                .sort((a, b) => handleSortName(a, b))
                .map((community, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== communities.length - 1
                        ? "border-b"
                        : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <button
                        onClick={() => handleCommunityClick(community)}
                        className="flex flex-row items-center gap-4"
                      >
                        <Avatar name={community.name} image={null} />
                        <p className="m-0 font-semibold underline">
                          {community.name}
                        </p>
                      </button>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {community.address}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {community.noOfPatients}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CommunityList;
